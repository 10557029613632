import { FETCH_PAYMENT_TOKEN_SUCCESS,PAYMENT_TYPE_SUCCESS, FETCH_PAYMENT_TOKEN, FETCH_PAYMENT_TOKEN_FAILS } from './paymentActionTypes';

export const paymentReducer = (
  state = {
    payment: {
      fetching: false,
      error: { status: false, message: '' }
    }
  },
  action
) => {
  switch (action.type) {
    case FETCH_PAYMENT_TOKEN:
      return {
        ...state,
        payment: {
          ...state.payment,
          isFetching: true
        }
      };

    case FETCH_PAYMENT_TOKEN_SUCCESS:
      return {
        ...state,
        payment: {
          ...state.payment,
          isFetching: false,
          token: action.payload,
          error: { status: false, message: '' }
        }
      };

    case FETCH_PAYMENT_TOKEN_FAILS:
      return {
        ...state,
        payment: {
          ...state.payment,
          isFetching: false,
          error: { status: false, message: action.payload }
        }
      };

    case PAYMENT_TYPE_SUCCESS:
      return {
        ...state,
        payment: {
          ...state.payment,
          paymentType : action.payload          
        }
      };

    default:
      return state;
  }
};
