import React from 'react';
import PropTypes from 'prop-types';
import downloadIcon from '../../../assets/images/common/downloadGreen.svg';

const HeaderButton = ({ handleDownload, status }) => {

  const downloadCSV = () => {
    handleDownload(handleDownload);
  }

  return (
    <div>
      {status === 'PENDING' ? <div className="loading-wrap">Generating...</div> : 
      <a className="btn-csv-download-wrap" onClick={downloadCSV}>
          <img class="download-icon" src={downloadIcon} />   
          <p className="btn-csv-download">Generate CSV</p>
      </a>}
    </div>
  );
};

HeaderButton.propTypes = {
  handleDownload: PropTypes.object,
  status: PropTypes.string,
};

export default HeaderButton;
