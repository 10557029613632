import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSpacesStatus, getFilteredSpaces } from './HostDashboardActions';
import decode from "jwt-decode";
import { Grid, Cell } from 'react-md';
import {
    Link, Switch,
    Route, useRouteMatch, Redirect
} from "react-router-dom";

import Spaces from './Spaces';
import { ADMIN, USER } from '../Common/constants';
import SpaceDetails from './SpaceDetails';


const SpaceContainer = () => {
    const isGuest = (authDetails) => authDetails ? decode(authDetails)?.authorities?.[0] === USER : true;
    const authDetails = useSelector((state) => state?.auth?.loginDetails?.data?.token || null);
    const authToken = useSelector(state => state?.auth?.loginDetails?.data?.token);

    const { path, url } = useRouteMatch();
    const dispatch = useDispatch();
    const status = ['ALL', 'ACTIVE', 'PENDING', 'INACTIVE', 'DECLINED'];

    const [visibleComingSoon, setVisibleComingSoon] = useState(false);
    const [userType, setUserType] = useState(null);
    const [statusAllSelected, setStatusAllSelected] = useState(true);
    const [bookingStatus, setBookingStatus] = useState('');
    const [clickedStatus, setClickedStatus] = useState('ALL');
    const [pageCount, setPageCount] = useState(1);
    const [divFocus, setDivFocus] = useState(false);
    
    const [scrolledPosition, setScrolledPosition] = useState(0);
    const [isBackClicked, handleBackClicked] = useState(false)
    const pageSize = 10;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': authToken
    }

    useEffect(() => {
        window.scrollTo(0,0)
        dispatch(getFilteredSpaces({ page: 1, size: pageSize }, headers))
        dispatch(fetchSpacesStatus());
    }, []);

    const handleStatusSelection = (status) => {
        if (status) {
            setPageCount(value => 1);
            dispatch(getFilteredSpaces({ page: 1, size: pageSize, status: status }, headers));
        }
        else
            dispatch(getFilteredSpaces({ page: 1, size: pageSize }, headers));
    };

    return (
        <div>
            <div className={divFocus && 'space-main-wrap'} onClick={() => setDivFocus(false)}></div>
            <div className={`my-space host-dashboard ${isGuest(authDetails) ? "guest-dashboard" : ""}`}>
                <div className="header">
                    <div className="container custom-container flex-wrap">
                        <h2 className="title">{userType === ADMIN ? "" : "My "}Spaces</h2>
                        <button className="btn-teal-outline btn-list" onClick={() => setVisibleComingSoon(true)}>List a space</button>
                    </div>
                </div>
                <div className="content">
                    <div className="container custom-container">
                        <Grid className="space-grid">
                            <Cell size={4} tabletSize={2} phoneHidden className="spacy-left">
                                <div className="list">
                                    {statusAllSelected ?
                                        (<>
                                            {status.map((status, index) => (
                                                <Link to={`${url}/${(status).toLowerCase()}`}>
                                                    <span
                                                        id={status}
                                                        key={index}
                                                        className={`list-items ${clickedStatus === status ? 'active' : ''}`}
                                                        hidden={status === 'REQUESTED'}
                                                        onClick={(e) => {
                                                            setClickedStatus(status)
                                                            setBookingStatus(status !== 'ALL' ? status : '');
                                                            handleStatusSelection(status !== 'ALL' ? status : '');
                                                        }}
                                                    >
                                                        {status}
                                                    </span>
                                                </Link>
                                            ))}
                                        </>) :
                                        (<span
                                            className="list-items"
                                            onClick={() => handleStatusSelection()}>
                                            All
                                        </span>)}
                                </div>
                            </Cell>
                            <Cell size={8} tabletSize={6} phoneSize={12} className="spacy-right">
                                <Switch>
                                    <Route path={`${path}/:status/:spaceId`}>
                                        <SpaceDetails
                                            handleBackClicked={handleBackClicked}
                                        />
                                    </Route>
                                    <Route path={`${path}/:status`}>
                                        <Spaces
                                            divFocus={divFocus}
                                            setDivFocus={setDivFocus}
                                            bookingStatus={bookingStatus}
                                            setClickedStatus={setClickedStatus}
                                            scrolledPosition={scrolledPosition}
                                            setScrolledPosition={setScrolledPosition}
                                            handleBackClicked={handleBackClicked}
                                            isBackClicked={isBackClicked}
                                        />
                                    </Route>
                                    <Route path={`*`}>
                                        <Redirect to={`${url}/all`} />
                                    </Route>
                                </Switch>
                            </Cell>
                        </Grid>
                    </div>
                </div>

            </div>
        </div>)
};

export default SpaceContainer;