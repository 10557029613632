import * as actions from './BookingActionTypes';
import { spaceProfile, favorite, reviews } from '../../api/resources';
import { push } from 'connected-react-router';
import { notification } from 'antd';

export const getBookingDetails = (spaceId, eventType) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.FETCH_BOOKING_DETAILS
    });

    axiosInstance.get(`booking/space/${spaceId}?eventType=${eventType}`)
      .then(res => {
        dispatch({
          type: actions.FETCH_BOOKING_DETAILS_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: actions.FETCH_BOOKING_DETAILS_FAILS,
          payload: error
        });
      });
  };
};
export const getSavings = (spaceId,eventType,chargings,duration,guestCount, paymentType) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.FETCH_SAVINGS
    });

    axiosInstance.get(`booking/saving/calculation?space=${spaceId}&eventType=${eventType}&price=${chargings}&duration=${duration}&guest=${guestCount}&chargingType=${paymentType}`)
      .then(res => {
        dispatch({
          type: actions.FETCH_SAVINGS_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: actions.FETCH_SAVINGS_FAILS,
          payload: error
        });
      });
  };
};
export const getTax = (spaceId,eventType,price,duration,guestCount,paymentType) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.FETCH_TAX
    });

    axiosInstance.get(`booking/tax/calculation?space=${spaceId}&eventType=${eventType}&price=${price}&duration=${duration}&guest=${guestCount}&chargingType=${paymentType}`)
      .then(res => {
        dispatch({
          type: actions.FETCH_TAX_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: actions.FETCH_TAX_FAILS,
          payload: error
        });
      });
  };
};
export const postBooking = (data, searchParameters, headers) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.POST_BOOKING_DATA
    });

    axiosInstance.post(`booking/`, data)
      .then(res => {
        dispatch({
          type: actions.POST_BOOKING_DATA_SUCCESS,
          payload: res.data
        });
        dispatch(push(`summary${searchParameters}`));
      })
      .catch(error => {
        dispatch({
          type: actions.POST_BOOKING_DATA_FAIL,
          payload: error?.response?.data
        });
      });
  };
};
export const postBookingRequestBooking = (data, searchParameters, headers) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.POST_BOOKING_REQUEST_BOOKING_DATA
    });

    axiosInstance.post(`booking/booking-request/`, data)
      .then(res => {
        dispatch({
          type: actions.POST_BOOKING_DATA_REQUEST_BOOKING_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: actions.POST_BOOKING_DATA_REQUEST_BOOKING_FAIL,
          payload: error
        });
        notification.open({
          message: error?.response?.data || "Failed to submit",
        });
      });
  };
};
export const clearBookingData = () => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.POST_BOOKING_DATA_REQUEST_BOOKING_CLEAR
    });
  };
};

export const getSpaceAvailability = (params, chargingType) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.FETCH_SPACE_AVAILABILITY
    });
    let url = `booking/availability?space=${params.spaceId}&eventTypeId=${params.eventTypeId}&startDate=${params.startDate}&endDate=${params.endDate}&chargingType=${chargingType}`;
    axiosInstance.get(url)
      .then(res => {
        dispatch({
          type: actions.FETCH_SPACE_AVAILABILITY_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: actions.FETCH_SPACE_AVAILABILITY_FAILS,
          payload: error
        });
      });
  };
};

export const cloneTaxAndSavings = data => {
  return {
    type: actions.TAX_SAVINGS,
    payload: data
  };
};

export const cloneBookingData = data => {
  return {
    type: actions.BOOKING_PAYLOAD,
    payload: data
  };
};

export const setGuestDetails = (count) => {
  return {
    type: actions.SET_GUEST_COUNT,
    payload: count
  };
};

export const submitPackage = (packageDetail) => {
  return {
    type: actions.PACKAGE_SUBMIT,
    payload: packageDetail
  };
};

export const updatePackage = (packageDetail) => {
  return {
    type: actions.PACKAGE_SUBMIT,
    payload: packageDetail
  };
};

export const deletePackage = (packageId) => {
  return {
    type: actions.PACKAGE_DELETE,
    payload: packageId
  };
};

export const deleteAllPackages = () => {
  return {
    type: actions.PACKAGES_DELETE,
  };
};

export const setBookingFlowStatus = (isBooking) => {
  return {
    type: actions.BOOKING_FLOW,
    payload: isBooking
  };
};

export const createTentativeBooking = (bookingId,generateInvoice) => {
  return (dispatch, getState, {axiosInstance}) => {
    axiosInstance.put(`booking/tentative-booking/${bookingId}?generateInvoice=${generateInvoice}`)
      .then(res =>{
        dispatch({
          type: actions.TENTATIVE_BOOKING_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: actions.TENTATIVE_BOOKING_FAILED,
          payload: error
        });
      });
  };
}

export const setTentativeBookingStatus = (status) => {
  return {
    type: actions.TENTATIVE_BOOKING_CONFIRM_RESET,
    payload: status
  }
}

export const tentativeBookingConfirm = (bookingId,openConrimNotification) => {
  return (dispatch, getState, {axiosInstance}) => {
    axiosInstance.put(`booking/tentative-booking/${bookingId}/confirm`)
      .then(res =>{
        dispatch({
          type: actions.TENTATIVE_BOOKING_CONFIRM_SUCCESS,
          payload: res.data
        });
        openConrimNotification(true);
      })
      .catch(error => {
        dispatch({
          type: actions.TENTATIVE_BOOKING_CONFIRM_FAILED,
          payload: error
        });
        openConrimNotification(false);
      });
  };
}

export const getPromoCode = (params, promoCodeModal) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.FETCH_PROMO_CODE
    });
    let url = `booking/promo-code/validate?user=${params.user}&promoCode=${params.code}&space=${params.spaceId}&event=${params.event}`;
    axiosInstance.get(url)
      .then(res => {
        dispatch({
          type: actions.FETCH_PROMO_CODE_SUCCESS,
          payload: res.data
        });
        promoCodeModal(false);
      })
      .catch(error => {
        dispatch({
          type: actions.FETCH_PROMO_CODE_FAILS,
          payload: error?.response?.data || null
        });
        promoCodeModal(true);
      });
  };
}

export const clearPromoCode = () => {
  return {
    type: actions.CLEAR_PROMO_CODE,
  }
}

