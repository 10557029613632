export const FETCH_SPACES_STATUS = "FETCH_SPACES_STATUS";
export const FETCH_SPACES_STATUS_SUCCESS = "FETCH_SPACES_STATUS_SUCCESS";
export const FETCH_SPACES_STATUS_FAILS = "FETCH_SPACES_STATUS_FAILS";

export const FETCH_ALL_SPACES = "FETCH_ALL_SPACES";
export const FETCH_ALL_SPACES_SUCCESS = "FETCH_ALL_SPACES_SUCCESS";
export const FETCH_ALL_SPACES_FAILS = "FETCH_ALL_SPACES_FAILS";

export const FETCH_FILTERED_SPACE_DETAILS = "FETCH_FILTERED_SPACE_DETAILS";
export const FETCH_FILTERED_SPACE_DETAILS_SUCCESS = "FETCH_FILTERED_SPACE_DETAILS_SUCCESS";
export const FETCH_MORE_SPACE_DETAILS_SUCCESS = "FETCH_MORE_SPACE_DETAILS_SUCCESS";
export const FETCH_FILTERED_SPACE_DETAILS_FAILS = "FETCH_FILTERED_SPACE_DETAILS_FAILS";

export const FETCH_SPACES_DETAILS = "FETCH_SPACES_DETAILS";
export const FETCH_SPACES_DETAILS_SUCCESS = "FETCH_SPACES_DETAILS_SUCCESS";
export const FETCH_SPACES_DETAILS_FAILS = "FETCH_SPACES_DETAILS_FAILS";

export const FETCH_CALENDER_VISITS_DATA = 'FETCH__CALENDER_VISITS_FILTER_DATA';
export const FETCH_CALENDER_VISITS_DATA_SUCCESS = 'FETCH_CALENDER_VISITS_DATA_SUCCESS';
export const FETCH_CALENDER_VISITS_DATA_FAILS = 'FETCH_CALENDER_VISITS_DATA_FAILS';

export const FETCH_CALENDER_BOOKING_DATA = 'FETCH__CALENDER_BOOKING_FILTER_DATA';
export const FETCH_CALENDER_BOOKING_DATA_SUCCESS = 'FETCH_CALENDER_BOOKING_DATA_SUCCESS';
export const FETCH_CALENDER_BOOKING_DATA_FAILS = 'FETCH_CALENDER_BOOKING_DATA_FAILS';

export const FETCH_BOOKING_SELECTED_DATE = 'FETCH_BOOKING_SELECTED_DATE';
export const FETCH_BOOKING_SELECTED_DATE_SUCCESS = 'FETCH_BOOKING_SELECTED_DATE_SUCCESS';
export const FETCH_BOOKING_SELECTED_DATE_FAILS = 'FETCH_BOOKING_SELECTED_DATE_FAILS';

export const CREATE_RESERVATION = 'CREATE_RESERVATION';
export const CREATE_RESERVATION_SUCCESS = 'CREATE_RESERVATION_SUCCESS';
export const CREATE_RESERVATION_FAILED = 'CREATE_RESERVATION_FAILED';

export const UPDATE_RESERVATION = 'UPDATE_RESERVATION';
export const UPDATE_RESERVATION_SUCCESS = 'UPDATE_RESERVATION_SUCCESS';
export const UPDATE_RESERVATION_FAILED = 'UPDATE_RESERVATION_FAILED';

export const FETCH_SPACE_AVAILABILITY = "FETCH_SPACE_AVAILABILITY";
export const FETCH_SPACE_AVAILABILITY_SUCCESS = "FETCH_SPACE_AVAILABILITY_SUCCESS";
export const FETCH_SPACE_AVAILABILITY_FAILS = "FETCH_SPACE_AVAILABILITY_FAILS";
export const CLEAR_SPACE_AVAILABILITY = "CLEAR_SPACE_AVAILABILITY"

export const CANCEL_SELECTED_BOOKING = 'CANCEL_SELECTED_BOOKING';
export const CANCEL_SELECTED_BOOKING_SUCCESS = 'CANCEL_SELECTED_BOOKING_SUCCESS';
export const CANCEL_SELECTED_BOOKING_FAIL = 'CANCEL_SELECTED_BOOKING_FAIL';

export const FETCH_SELECTED_SPACE = 'FETCH_SELECTED_SPACE';
export const FETCH_SELECTED_SPACE_SUCCESS = 'FETCH_SELECTED_SPACE_SUCCESS';
export const FETCH_SELECTED_SPACE_FAIL = 'FETCH_SELECTED_SPACE_FAIL';

export const UPDATE_VISIT_STATUS = 'UPDATE_VISIT_STATUS';
export const UPDATE_VISIT_STATUS_SUCCESS = 'UPDATE_VISIT_STATUS_SUCCESS';
export const UPDATE_VISIT_STATUS_FAIL = 'UPDATE_VISIT_STATUS_FAIL';

export const SPACE_STATUS = {
    ACTIVE : 'ACTIVE',
    PENDING : 'PENDING',
    INACTIVE : 'INACTIVE',
    DECLINED : 'DECLINED'
}