import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import Progress from 'antd/es/progress';
import LoginDetails from './SubComponents/LoginDetails';
import SpaceDetails from './SubComponents/SpaceDetails';
import {
  ADDONS,
  AGREEMENT,
  AMENITIES,
  COMMISSION,
  CONGRATULATIONS,
  DEFAULT_DATA_OBJ,
  EXTERNAL_SYSTEM,
  LOGIN_DETAILS,
  ORGANIZATION_DETAILS,
  PAYOUT_DETAILS,
  PRICING_OPTIONS,
  RULES,
  SAVINGS,
  CALENDARSYNC,
  SEATING_AND_COMMON,
  SEATING_ARRANGEMENT,
  SINGAPORE_SHORT_CODE,
  AUSTRALIA_SHORT_CODE,
  SPACE_AND_NOTICE,
  SPACE_DETAILS,
  SPACE_LOCATION,
  SRI_LANKA_SHORT_CODE,
  TAX_INFORMATION,
  UPLOADING_IMAGES,
  WELCOME,
} from './SpaceListingConstants';
import ExternalSystem from './SubComponents/ExternalSystems';
import UploadImages from './SubComponents/UploadImages';
import { Col, Row } from 'antd';
import SpaceAndNotice from './SubComponents/SpaceAndNotice';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAddOns,
  getAmenities,
  getCancellationPolicyDetails,
  getCategoriesAndActivityTypes,
  getCountryWiseEventType,
  getMrtStations,
  getPackageTypes,
  getPricingOptions,
  getRules,
  getSavings,
  getSeatingArrangements,
  getTaxDetails,
} from './ListingAction';
import TaxInformation from './SubComponents/TaxAndDetails';
import SeatingAndCommon from './SubComponents/SeatingAndCommon';
import Amenities from './SubComponents/Amenities';
import AddOns from './SubComponents/AddOns';
import Rules from './SubComponents/Rules';
import CalendarSync from './SubComponents/CalendarSync';
import PayoutDetails from './SubComponents/PayoutDetails';
import Commission from './SubComponents/Commission';
import Congratulations from './SubComponents/Congratulations';
import { googleMapAPIKey } from '../../settings';
import spaceListingBanner from '../../assets/images/space-listing/space-listing.jpg';
import welcomeBanner from '../../assets/images/space-listing/welcome-banner-space-listing.png';
import ListingMap from './Common/ListingMap';
import PricingOptions from './SubComponents/PricingOptions/PricingOptions';
import { Redirect, Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import SpaceLocation from './SubComponents/SpaceLocation';
import { getProfileData, setProfileData } from './Common/DataController';
import { spaceListingData } from './Common/ProfileListingDataModal';
import { push } from 'connected-react-router';
import WelcomePage from './SubComponents/Welcome';
import OrganizationDetails from './SubComponents/OrganizationDetails';
import Agreement from './SubComponents/HostAgreement';
import SeatingArrangement from './SubComponents/SeatingArrangement';

const SpaceListing = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(1);
  const [nearLocationLatitude, setNearlocationLatitude] = useState(0);
  const [nearLocationLongitude, setNearlocationLongitude] = useState(0);
  const seating_arrangement = useSelector((state) => state?.spaceListing?.seatingArrangement || []);
  const savings = useSelector((state) => state?.spaceListing?.savings || []);
  const amenities = useSelector((state) => state?.spaceListing?.amenities || []);
  const rules = useSelector((state) => state?.spaceListing?.rules || []);
  const addons = useSelector((state) => state?.spaceListing?.addons || []);
  const authDetails = useSelector((state) => state?.auth?.loginDetails?.data?.token || null);
  const searchURLstring = useSelector((state) => state?.router?.location?.search || '');
  const countryShortCode = useSelector((state) => state?.locationMeta?.data?.country_shortCode);
  const { url } = useRouteMatch();
  const { country } = useParams();
  const baseRouteURL = `${url}/`;
  // const [mainDataObj,setMainDataObj] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData():spaceListingData);
  // Resetting the data on main component loading!
  const [mainDataObj, setMainDataObj] = useState(spaceListingData(country));
  const [isWelcomeNext, setWelcomeNextClick] = useState(false);

  const handleMapSet = (value) => {
    setMainDataObj(value);
  };

  useEffect(() => {
    setProgress(((getProfileData()?.routeList?.indexOf(window.location.pathname) + 1) / getProfileData()?.routeList?.length) * 100);
  }, [location]);

  useEffect(() => {
    if (!window.location.pathname.includes('list-a-space/welcome')) {
      dispatch(push(`${baseRouteURL}/welcome$`));
    }
    setProfileData(spaceListingData(country));
    dispatch(getCategoriesAndActivityTypes());
    dispatch(getPricingOptions(countryShortCode === 'AUS' ? AUSTRALIA_SHORT_CODE : SINGAPORE_SHORT_CODE));
    dispatch(getCancellationPolicyDetails());
    dispatch(getCountryWiseEventType(countryShortCode === 'AUS' ? AUSTRALIA_SHORT_CODE : countryShortCode === 'SG' ? SINGAPORE_SHORT_CODE : SRI_LANKA_SHORT_CODE));
    if (mainDataObj?.tax_information?.[0]?.LKR.length === 0) {
      dispatch(getTaxDetails(SRI_LANKA_SHORT_CODE));
    }
    if (mainDataObj?.tax_information?.[0]?.SGD.length === 0) {
      dispatch(getTaxDetails(countryShortCode === 'AUS' ? AUSTRALIA_SHORT_CODE : SINGAPORE_SHORT_CODE));
    }
    if (mainDataObj?.savings?.[0]?.data.length === 0) {
      dispatch(getSavings());
    }
    if (mainDataObj?.seating_arrangement?.[0]?.data.length === 0) {
      dispatch(getSeatingArrangements());
    }
    if (mainDataObj?.amenities?.[0]?.data.length === 0) {
      dispatch(getAmenities(countryShortCode === 'AUS' ? AUSTRALIA_SHORT_CODE : SINGAPORE_SHORT_CODE));
    }
    if (mainDataObj?.addons?.[0]?.data.length === 0) {
      dispatch(getAddOns());
    }
    if (mainDataObj?.rules?.[0]?.data.length === 0) {
      dispatch(getRules(countryShortCode === 'AUS' ? AUSTRALIA_SHORT_CODE : SINGAPORE_SHORT_CODE));
    }
    if (countryShortCode === 'SG') dispatch(getMrtStations());
    dispatch(getPackageTypes());
  }, []);

  useEffect(() => {
    if (authDetails && window.location.pathname.includes('list-a-space/welcome') && sessionStorage.getItem('nextClick') === 'true') {
      const nextBlock = getProfileData()?.routeList?.indexOf(window.location.pathname) + 1;
      dispatch(push(getProfileData()?.routeList?.[nextBlock]));
      sessionStorage.setItem('nextClick', false);
    }
  }, [authDetails]);

  useEffect(() => {
    if (savings && savings.length > 0) {
      handleSpaceLocation(
        SAVINGS,
        'data',
        savings.map((value) => {
          return { ...value, selectedStatus: false, capacity: 0 };
        })
      );
    }
  }, [savings]);

  useEffect(() => {
    if (seating_arrangement && seating_arrangement.length > 0) {
      handleSpaceLocation(
        SEATING_ARRANGEMENT,
        'data',
        seating_arrangement.map((value) => {
          return { ...value, selectedStatus: false, capacity: 0 };
        })
      );
    }
  }, [seating_arrangement]);

  useEffect(() => {
    if (amenities && amenities.length > 0) {
      handleSpaceLocation(
        AMENITIES,
        'data',
        amenities.map((value) => {
          return { ...value, selectedStatus: false };
        })
      );
    }
  }, [amenities]);

  useEffect(() => {
    if (addons && addons.length > 0) {
      handleSpaceLocation(
        ADDONS,
        'data',
        addons.map((value) => {
          return { ...value, price: 0, description: '', selectedStatus: false };
        })
      );
    }
  }, [addons]);

  useEffect(() => {
    if (rules && rules.length > 0) {
      handleSpaceLocation(
        RULES,
        'data',
        rules.map((value) => {
          return { ...value, selectedStatus: false };
        })
      );
    }
  }, [rules]);

  const handleSpaceLocation = useCallback((type, name, newValue) => {
    const tempObj = Object.assign({}, getProfileData(), {
      ...getProfileData(),
      processStarted: true,
      [type]: getProfileData()?.[type]?.map((value, index) => {
        value[name] = newValue;
        return value;
      }),
    });
    setMainDataObj(tempObj);
    setProfileData(tempObj);
  }, []);

  const handleNearestlocation =  (latitude,longitude) => {
  
     setNearlocationLatitude(latitude);
   setNearlocationLongitude(longitude);
   
  };


  // useEffect(()=>{
  //   if( window.location.pathname.includes("Singapore")){
  //     dispatch(push("/all/dashboard/list-a-space/welcome"))
  //   }
  // },[window.location.pathname])

  return (
    <>
      <div className="space-listing-page">
        <Progress percent={progress} status="active" showInfo={false} strokeColor={'#2A8485'} />
        <div className="full-height-wrapper">
          <Row className="section-wrapper">
            <Col className="left-side" xl={12} lg={12} md={24} sm={24}>
              <Switch>
                <Route
                  path={baseRouteURL + WELCOME}
                  component={() => <WelcomePage currentBlock={WELCOME} authDetails={authDetails} setWelcomeNextClick={setWelcomeNextClick} />}
                />
                <Route path={baseRouteURL + LOGIN_DETAILS} component={() => <LoginDetails currentBlock={LOGIN_DETAILS} />} />
                <Route path={baseRouteURL + ORGANIZATION_DETAILS} component={() => <OrganizationDetails currentBlock={ORGANIZATION_DETAILS} />} />
                <Route path={baseRouteURL + SPACE_DETAILS} component={() => <SpaceDetails currentBlock={SPACE_DETAILS} />} />
                <Route path={baseRouteURL + SPACE_LOCATION}>
                  <SpaceLocation currentBlock={SPACE_LOCATION} handleMapSet={handleMapSet} handleNearestlocation={handleNearestlocation} />
                </Route>
                <Route path={baseRouteURL + EXTERNAL_SYSTEM} component={() => <ExternalSystem currentBlock={EXTERNAL_SYSTEM} />} />
                <Route path={baseRouteURL + UPLOADING_IMAGES} component={() => <UploadImages currentBlock={UPLOADING_IMAGES} />} />
                <Route path={baseRouteURL + SPACE_AND_NOTICE} component={() => <SpaceAndNotice currentBlock={SPACE_AND_NOTICE} />} />
                <Route path={baseRouteURL + TAX_INFORMATION} component={() => <TaxInformation currentBlock={TAX_INFORMATION} />} />
                <Route path={baseRouteURL + SEATING_AND_COMMON}>
                  <SeatingAndCommon currentBlock={SEATING_AND_COMMON} />
                </Route>
                <Route path={baseRouteURL + SEATING_ARRANGEMENT} component={() => <SeatingArrangement currentBlock={SEATING_ARRANGEMENT} />} />
                <Route path={baseRouteURL + PRICING_OPTIONS} component={() => <PricingOptions />} />
                <Route path={baseRouteURL + AMENITIES} component={() => <Amenities currentBlock={AMENITIES} />} />
                <Route path={baseRouteURL + ADDONS} component={() => <AddOns currentBlock={ADDONS} />} />
                <Route path={baseRouteURL + RULES} component={() => <Rules currentBlock={RULES}/>} />
             
                <Route path={baseRouteURL + PAYOUT_DETAILS} component={() => <PayoutDetails currentBlock={PAYOUT_DETAILS} />} />
                <Route path={baseRouteURL + COMMISSION} component={() => <Commission currentBlock={COMMISSION} />} />
                <Route path={baseRouteURL + CALENDARSYNC} component={() => <CalendarSync />} />
                <Route path={baseRouteURL + AGREEMENT} component={() => <Agreement currentBlock={AGREEMENT} />} />
                <Route path={baseRouteURL + CONGRATULATIONS} component={() => <Congratulations />} />
                <Route path={'*'} component={() => <Redirect to={baseRouteURL + WELCOME} />} />
              </Switch>
            </Col>
            {location.pathname !== baseRouteURL + SPACE_LOCATION && (
              <Col className="right-side" xl={12} lg={12} md={0} sm={0}>
                <div className="background-img-container" style={{ backgroundImage: `url(${welcomeBanner})` }} />
              </Col>
            )}
            {location.pathname === baseRouteURL + SPACE_LOCATION && (
              <Col className="right-side map-section" xl={12} lg={12} md={24} sm={24}>
                <div className="map-content-wrapper">
                  <div className="map-title-wrapper">
                    <div className="map-title">Drag & drop the pin to your space location in the map</div>
                  </div>
                  <ListingMap
                    isMarkerShown={true}
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapAPIKey}`}
                    loadingElement={<div style={{ height: '400px' }} />}
                    containerElement={<div className="container-element" />}
                    mapElement={<div className="map-element" />}
                    locationDetails={getProfileData()?.[SPACE_LOCATION]?.[0]}
                    nearLatitude={nearLocationLatitude}
                    nearLongitude={nearLocationLongitude}
                  />
                </div>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </>
  );
};

export default SpaceListing;
