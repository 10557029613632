import { DATE_FORMAT, HOT_DESK_C, PERCENTAGE, TIME_FORMAT, VALUE, WORK_C } from './../components/Common/constants';
import { DAILY, WORK, WEEKLY, MONTHLY, HOURLY, BLOCK } from '../components/Common/constants';
import moment from 'moment';

export const getDayName = (paymentType, duration, timePickers) => {
  if (paymentType == DAILY) {
    return duration === 1 ? `${duration} day` : `${duration} days`;
  }
  if (paymentType === HOURLY) {
    const beginTime = moment(timePickers[0].beginTime, 'h:mm A');
    const endTime = moment(timePickers[timePickers.length - 1].endTime, 'h:mm A');
    const duration = moment.duration(endTime.diff(beginTime));
    const hours = duration.asMinutes() / 60;
    return hours > 1 ? `${hours} hours` : `${hours} hour`;
  }
  if (paymentType == WEEKLY) {
    return Number(duration) === 1 ? `${duration} week` : `${duration} weeks`;
  }
  if (paymentType == MONTHLY) {
    return Number(duration) === 1 ? `${duration} month` : `${duration} months`;
  }
  if (paymentType == BLOCK) {
    return Number(duration) === 1 ? `${duration} block` : `${duration} blocks`;
  }
};

export const getCardIcon = (cardBrand) => {
  switch (cardBrand) {
    case 'visa':
      return 'image/upload/v1586172485/ms-singapore/assets/Icons/acount-settings/Payments/visa.svg';
    case 'mastercard':
      return 'image/upload/v1586172485/ms-singapore/assets/Icons/acount-settings/Payments/master.svg';
    case 'amex':
      return 'image/upload/v1586172485/ms-singapore/assets/Icons/acount-settings/Payments/amex.svg';
    default:
      return 'image/upload/v1586172485/ms-singapore/assets/Icons/acount-settings/Payments/payment.svg';
  }
};

export const getCardName = (cardBrand) => {
  switch (cardBrand) {
    case 'visa':
      return 'Visa';
    case 'mastercard':
      return 'Mastercard';
    case 'amex':
      return 'American Express';
    default:
      return cardBrand;
  }
};

export const checkCurrentDateSlots = (reduxAvailableDates) => {
  //Clone redux object
  let availableDates = JSON.parse(JSON.stringify(reduxAvailableDates));

  // check time slots for the current date
  const dateNow = moment();
  const currentDateData = availableDates.find((availableDate) => {
    const [selecteAvailableDate] = Object.keys(availableDate);
    if (
      availableDate[`${moment(dateNow).format(DATE_FORMAT)}T00:00:00.000+0000`] &&
      `${moment(dateNow).format(DATE_FORMAT)}T00:00:00.000+0000` === selecteAvailableDate
    )
      return reduxAvailableDates[selecteAvailableDate];
  });
  if (currentDateData && currentDateData[`${moment(dateNow).format(DATE_FORMAT)}T00:00:00.000+0000`]?.slots?.length) {
    currentDateData[`${moment(dateNow).format(DATE_FORMAT)}T00:00:00.000+0000`].slots.forEach((slot) => {
      if (moment(dateNow).isAfter(moment(slot.endTime, TIME_FORMAT))) {
        currentDateData[`${moment(dateNow).format(DATE_FORMAT)}T00:00:00.000+0000`].slots = currentDateData[
          `${moment(dateNow).format(DATE_FORMAT)}T00:00:00.000+0000`
        ].slots.filter((timeSlot) => timeSlot !== slot);
      } else if (moment(dateNow).isAfter(moment(slot.startTime, TIME_FORMAT))) {
        const newStartTime = `${moment(dateNow, TIME_FORMAT).add('hour', 1).format('h:')}00 ${moment(dateNow, TIME_FORMAT)
          .add('hour', 1)
          .format('a')}`;
        slot.startTime = newStartTime;
      }
      if (moment.duration(moment(slot.startTime, TIME_FORMAT).diff(moment(slot.endTime, TIME_FORMAT))).asHours() < 1) {
        currentDateData[`${moment(dateNow).format(DATE_FORMAT)}T00:00:00.000+0000`].slots = currentDateData[
          `${moment(dateNow).format(DATE_FORMAT)}T00:00:00.000+0000`
        ].slots.filter((timeSlot) => timeSlot !== slot);
      }
    });
    if (currentDateData[`${moment(dateNow).format(DATE_FORMAT)}T00:00:00.000+0000`].slots.length < 1) {
      availableDates = availableDates.filter((availableDate) => {
        const [selecteAvailableDate] = Object.keys(availableDate);
        const [currentAvailableDate] = Object.keys(currentDateData);
        return selecteAvailableDate !== currentAvailableDate;
      });
    }
  }
  return availableDates;
};

export const checkPreviousDropMenue = (dropDownMenueList) => {
  let previousDropDownSelected = true;
  dropDownMenueList.forEach((dropPackage) => {
    if (!dropPackage.code) previousDropDownSelected = false;
  });
  return previousDropDownSelected;
};

export const setValueForPackageDropdown = (selectedQty, maxQty, spaceCategory) => {
  if (spaceCategory === WORK_C) {
    return selectedQty || maxQty || 1;
  } else {
    return 0;
  }
};

export const getMaximumQtyForPackage = (spaceCategory, maxQty, guestCount) => {
  if (spaceCategory === HOT_DESK_C) {
    return parseFloat(maxQty) * parseFloat(guestCount);
  } else {
    return maxQty;
  }
};

export const getDurationTypeByChargingType = (chargingType, duration) => {
  switch (chargingType) {
    case DAILY:
      return parseInt(duration) === 1 ? 'day' : 'days';
    case WEEKLY:
      return parseInt(duration) === 1 ? 'week' : 'weeks';
    case MONTHLY:
      return parseInt(duration) === 1 ? 'month' : 'months';
    case HOURLY:
      return parseInt(duration) === 1 ? 'hour' : 'hours';
    case BLOCK:
      return parseInt(duration) === 1 ? 'block' : 'blocks';

    default:
      return duration == 1 ? 'day' : 'days';
  }
};

export const getTotalAmountForPackage = (selectedPackages = [], duration, chargingType) => {
  let packageTotalAmount = 0.0;
  selectedPackages.forEach((pkg) => {
    if (pkg.id && pkg?.selectedQty) {
      if (chargingType === HOURLY || chargingType === BLOCK) {
        packageTotalAmount = parseFloat(packageTotalAmount) + parseFloat(pkg?.price?.amount) * parseFloat(pkg?.selectedQty);
      } else {
        packageTotalAmount = parseFloat(packageTotalAmount) + parseFloat(pkg?.price?.amount) * parseFloat(duration) * parseFloat(pkg?.selectedQty);
      }
    }
  });
  return packageTotalAmount;
};

export const getTotalProceAmountOfPackages = (selectedPackages = []) => {
  let total = 0.0;
  selectedPackages.forEach((pkg) => {
    if (pkg.id) {
      total += pkg?.price?.amount;
    }
  });
  return total;
};

export const handleSubmitDisable = (isAddingPackages, dropDownMenueList) => {
  if (!isAddingPackages) return false;
  if (!checkPreviousDropMenue(dropDownMenueList)) return true;
  let checkQuantity = false;
  dropDownMenueList.forEach((dropPackage) => {
    if (!dropPackage.selectedQty) checkQuantity = true;
  });
  return checkQuantity;
};

//---------------------- Savings calculations ---------------------
export const calculateTotalSavings = (finalDuration, savings, bookingTotal, packagePrice) => {
  let finalSavings = 0;
  const savingsArr = getTotalSavings(finalDuration, savings, bookingTotal, packagePrice);
  savingsArr.forEach((saving) => {
    finalSavings += parseFloat(Number(saving.value).toFixed(2));
  });

  return finalSavings;
};

export const getTotalSavings = (finalDuration, savings, bookingTotal, packagePrice) => {
  let savingsObjectArray = [];

  if (savings?.length > 0) {
    savings.forEach((obj) => {
      let packageDevidedAmount = 0;
      if (packagePrice && parseFloat(finalDuration) - (obj.terms - 1)) {
        packageDevidedAmount = packagePrice / (parseFloat(finalDuration) - (obj.terms - 1));
      }
      let value = 0;
      const savingIncludedTerm = obj.terms - 1;
      const discountedTerm = finalDuration - savingIncludedTerm;
      const pricePerUnit = bookingTotal / finalDuration;
      if (finalDuration >= savingIncludedTerm) {
        switch (obj?.saving?.amountType) {
          case PERCENTAGE:
            value = discountedTerm * (((pricePerUnit + packageDevidedAmount) * obj.amount) / 100);
            savingsObjectArray.push({ name: obj.saving.name, percentage: obj.amount, value: value });
            break;
          case VALUE:
            value = obj.amount;
            savingsObjectArray.push({ name: obj.saving.name, percentage: obj.amount, value: value });
            break;
          default:
            break;
        }
      }
    });
  }

  return savingsObjectArray;
};
//---------------------- Savings calculations ---------------------

//---------------------- Tax Calculation --------------------------
export const getTotalTaxAndArray = (taxes, taxAmountArray, priceAfterDiscount, setTaxAmountArray) => {
  taxAmountArray.length && taxAmountArray.forEach((txAmt) => taxAmountArray.pop());
  return getTaxAmount([...taxes], taxes, priceAfterDiscount, taxAmountArray, setTaxAmountArray);
};

export const getTaxAmountFromTaxArr = (taxId, taxAmountArray) => {
  let taxAmount = 0;
  taxAmountArray.forEach((txAmt) => {
    if (txAmt.id == taxId) taxAmount = txAmt.amount;
  });
  return taxAmount;
};

export const addTaxToTaxAmountArray = (taxId, taxAmount, taxAmountArray) => {
  let taxFound = false;
  taxAmountArray.length &&
    taxAmountArray.forEach((tx) => {
      if (taxId === tx.id) {
        tx.amount = taxAmount;
        taxFound = true;
      }
    });
  return taxFound;
};

export const getTaxAmount = (taxArr, taxes, priceAfterDiscount, taxAmountArray, setTaxAmountArray) => {
  const calculatedTax = [];
  let totalTax = 0;
  if (taxArr.length === 0) return totalTax;

  taxArr.forEach((taxVal) => {
    if (taxes.some((item) => item === taxVal)) {
      let taxAmount = 0;
      const taxOnTax = getTaxAmount([...taxVal.taxOnTax], taxes, priceAfterDiscount, calculatedTax, setTaxAmountArray);
      taxAmount = parseFloat((priceAfterDiscount + taxOnTax + totalTax) * (taxVal.taxAmount / 100));
      if (!addTaxToTaxAmountArray(taxVal.id, taxAmount, calculatedTax)) calculatedTax.push({ id: taxVal.id, amount: taxAmount });
      totalTax += Math.round(taxAmount * 100) / 100;
    }
  });
  setTaxAmountArray(calculatedTax);
  return parseFloat(Number(totalTax).toFixed(2));
};
//---------------------- Tax Calculation --------------------------

export const setDefaultChargingType = (unit) => {
  switch (unit) {
    case 'day':
      return DAILY;
    case 'month':
      return MONTHLY;
    case 'week':
      return WEEKLY;
    case 'block':
      return BLOCK;
    case 'hour':
      return HOURLY;
    default:
      return DAILY;
  }
};

export const getChargingTypeForURL = (unit) => {
  switch (unit) {
    case DAILY:
      return 'day';
    case MONTHLY:
      return 'month';
    case WEEKLY:
      return 'week';
    case BLOCK:
      return 'block';
    case HOURLY:
      return 'hour';
    default:
      return 'day';
  }
};

export const getDatefromTimezone = (dateTimezone) => {
  return dateTimezone?.split('T')[0]
}
