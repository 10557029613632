import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import Select from 'antd/es/select';
import { useSelector } from 'react-redux';
import FlowHandlingButtons from './HandleNavigationAndSubmit';
import { contentIsNotNull, noSpecialCharIncluded } from './Validator';
import { DialogContainer, TextField } from 'react-md';
import {
  DEFAULT_DATA_OBJ,
  ERROR_CHAR_LIMIT,
  ERROR_ONLY_NUMBERS,
  ERROR_SPECIAL_CHAR,
  ERROR_WHITE_SPACE,
  MEETING_SPACE_CODE,
  WORK_SPACE_CODE,
} from '../SpaceListingConstants';
import { spaceListingData } from './ProfileListingDataModal';
import editIcon from '../../../assets/images/host/edit-teal.svg';
import { getProfileData, setProfileData } from './DataController';
import deleteIcon from '../../../assets/images/common/recyclebin.svg';
import { showErrorMessage } from './Common';
import { BASE_URL, IMAGES_BASE_URL } from '../../../settings';
import { useParams } from 'react-router-dom';

const { Option, OptGroup } = Select;
const PackageComponent = ({ pricingOption }) => {
  const { country } = useParams();
  const [packageDetails, setPackageDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));
  const [packagePopUpVisibility, setPackagePopUpVisibility] = useState(false);
  const packageTypes = useSelector((state) => state?.spaceListing?.packageTypes || []);
  const countryName = useSelector((state) => state?.locationMeta?.data?.country_longCode?.replace(' ', '-') || null);
  const [singlePackageData, setSinglePackageData] = useState({
    number: undefined,
    packageTypeId: undefined,
    packageType: undefined,
    packageName: undefined,
    description: undefined,
    maxGuestQty: undefined,
    unitPrice: undefined,
  });

  const [isModelCreate, setIsModelCreate] = useState(true);
  const [deletePopUp, setDeletePopUp] = useState(false);

  const [isAddBtnActive, setIsAddBtnActive] = useState(false);
  const [isNextBtnActive, setIsNextBtnActive] = useState(false);

  const [maxGuestToolTipVisibility, setMaxGuestToolTipVisibility] = useState(false);
  const [unitPriceTipVisibility, setUnitPriceTipVisibility] = useState(false);

  const handleToolTipVisibility = (toolTipRef) => {
    switch (toolTipRef) {
      case 1:
        setMaxGuestToolTipVisibility(true);
        setInterval(() => setMaxGuestToolTipVisibility(false), 5000);
        break;
      case 2:
        setUnitPriceTipVisibility(true);
        setInterval(() => setUnitPriceTipVisibility(false), 5000);
        break;
    }
  };

  useEffect(() => {
    setIsAddBtnActive(
      singlePackageData?.packageTypeId &&
        contentIsNotNull(singlePackageData?.packageType, 0) &&
        contentIsNotNull(singlePackageData?.packageName, 0, 30) &&
        contentIsNotNull(singlePackageData?.description, 0, 100) &&
        singlePackageData?.maxGuestQty > 0 &&
        singlePackageData?.unitPrice > 0
    );
  }, [singlePackageData]);

  useEffect(() => {
    setIsNextBtnActive(!getBlockValue()?.['packageStatus'] || contentIsNotNull(getBlockValue()?.['packages'], 0));
  }, [packageDetails]);

  useEffect(() => {
    handleInputData('number', getBlockValue()?.packages.length);
  }, [packageDetails]);

  const getBlockValue = () => {
    return getProfileData()?.['pricing_options']?.[0]?.[pricingOption]?.['packageDetails'];
  };

  const handlePackageStatus = (name, packageStatus) => {
    const tempObj = Object.assign({}, packageDetails, {
      ...packageDetails,
      pricing_options: packageDetails?.['pricing_options'].map((value) => {
        value[pricingOption]['packageDetails']['packageStatus'] = packageStatus;
        return value;
      }),
    });
    setProfileData(tempObj);
    setPackageDetails(tempObj);
    if(countryName === 'Australia'){
      initialPackages(packageStatus)
    }

  };

  const handleInputData = (
    name,
    value,
    max = 5000,
    noSpecialChar = false,
    noWhiteSpaces = false,
    onlyNumbers = false,
    regex = '',
    fieldName = ''
  ) => {
    if (onlyNumbers) {
      value = value.replace(/^0+/, '');
    }

    if (value?.length > max) {
      showErrorMessage(ERROR_CHAR_LIMIT);
    } else if (noWhiteSpaces && /\s/g.test(value)) {
      showErrorMessage(ERROR_WHITE_SPACE);
    } else if (onlyNumbers && !/^[0-9.]{0,60}$/i.test(value) && value !== '') {
      showErrorMessage(ERROR_ONLY_NUMBERS);
    } else {
      setSinglePackageData((b) => {
        return { ...b, [name]: value };
      });
    }
  };

  const cancelAddedPackage = (value) => {
    setPackagePopUpVisibility(false);
    setSinglePackageData({
      number: getBlockValue()?.packages.length,
      packageTypeId: undefined,
      packageType: undefined,
      packageName: undefined,
      description: undefined,
      maxGuestQty: undefined,
      unitPrice: undefined,
    });
  };

  const openCreatePopUp = () => {
    setPackagePopUpVisibility(true);
    setIsModelCreate(true);
  };

  const initialPackages=(packageStatus)=>{
    const tempObj = Object.assign({}, packageDetails, {
      ...packageDetails,
      pricing_options: packageDetails?.['pricing_options'].map((value) => {
        value[pricingOption]['packageDetails'] = {
          packageStatus: packageStatus,
          packages: [
        
              {
                number: 1,
                packageTypeId: 2,
                packageType: 'Facilities',
                packageName:' Office Chair',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                maxGuestQty: '1',
                unitPrice: '5',
               },
               {
                number: 2,
                packageTypeId: 3,
                packageType: 'IT',
                packageName:' Video whiteboard',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                maxGuestQty: '1',
                unitPrice: '10'
               },
               {
                number:3,
                packageTypeId: 4,
                packageType: 'Food & Beverage',
                packageName:' Water cooler',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                maxGuestQty: '1',
                unitPrice: '8'
               },
               {
                number: 4,
                packageTypeId: 5,
                packageType: 'Facilities',
                packageName:'USB Charger',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                maxGuestQty: '1',
                unitPrice: '10'
               },
               {
                number: 5,
                packageTypeId: 6,
                packageType: 'Facilities',
                packageName:'Pot plant',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                maxGuestQty: '1',
                unitPrice: '5'
               }
              ]
            
        };
        return value;
      }),
    });
    setProfileData(tempObj);
    setPackageDetails(tempObj);
  }

  const addPackage = () => {
    setPackagePopUpVisibility(false);

    const tempObj = Object.assign({}, packageDetails, {
      ...packageDetails,
      pricing_options: packageDetails?.['pricing_options'].map((value) => {
        value[pricingOption]['packageDetails'] = {
          packageStatus: true,
          packages: value[pricingOption]['packageDetails'].packages
            ? [...value[pricingOption]['packageDetails']?.packages, singlePackageData]
            : [singlePackageData],
        };
        return value;
      }),
    });

    setProfileData(tempObj);
    setPackageDetails(tempObj);
    setSinglePackageData({
      number: undefined,
      packageTypeId: undefined,
      packageType: undefined,
      packageName: undefined,
      description: undefined,
      maxGuestQty: undefined,
      unitPrice: undefined,
    });
  };

  const openEditPopUp = (singlePackageData) => {
    setPackagePopUpVisibility(true);
    setIsModelCreate(false);
    setSinglePackageData(singlePackageData);
  };

  const editPackage = () => {
    setPackagePopUpVisibility(false);

    const tempObj = Object.assign({}, packageDetails, {
      ...packageDetails,
      pricing_options: packageDetails?.['pricing_options'].map((value) => {
        value[pricingOption]['packageDetails'] = {
          packageStatus: true,
          packages: getBlockValue()?.packages.map((value) => {
            if (singlePackageData?.number === value?.number) {
              value = singlePackageData;
            }
            return value;
          }),
        };
        return value;
      }),
    });

    setProfileData(tempObj);
    setPackageDetails(tempObj);
    setSinglePackageData({
      number: undefined,
      packageTypeId: undefined,
      packageType: undefined,
      packageName: undefined,
      description: undefined,
      maxGuestQty: undefined,
      unitPrice: undefined,
    });
  };

  const openRemovePackagePopUp = (singlePackageData) => {
    setDeletePopUp(true);
    setSinglePackageData(singlePackageData);
  };

  const closeRemovePackagePopUp = () => {
    setDeletePopUp(false);
    setSinglePackageData({
      number: undefined,
      packageTypeId: undefined,
      packageType: undefined,
      packageName: undefined,
      description: undefined,
      maxGuestQty: undefined,
      unitPrice: undefined,
    });
  };

  const removePackage = () => {
    setDeletePopUp(false);

    const tempObj1 = Object.assign({}, packageDetails, {
      ...packageDetails,
      pricing_options: packageDetails?.['pricing_options'].map((value) => {
        value[pricingOption]['packageDetails'] = {
          packageStatus: true,
          packages: getBlockValue()?.packages.filter((value) => value?.number !== singlePackageData?.number),
        };
        return value;
      }),
    });

    setProfileData(tempObj1);
    setPackageDetails(tempObj1);

    const tempObj2 = Object.assign({}, packageDetails, {
      ...packageDetails,
      pricing_options: packageDetails?.['pricing_options'].map((value) => {
        value[pricingOption]['packageDetails'] = {
          packageStatus: true,
          packages: handlePackageRemove(),
        };
        return value;
      }),
    });

    setProfileData(tempObj2);
    setPackageDetails(tempObj2);
    setSinglePackageData({
      number: undefined,
      packageTypeId: undefined,
      packageType: undefined,
      packageName: undefined,
      description: undefined,
      maxGuestQty: undefined,
      unitPrice: undefined,
    });
  };

  const handlePackageRemove = () => {
    const filteredArray = getBlockValue()?.packages.filter((value) => value?.number !== singlePackageData?.number);
    return filteredArray.map((value, index) => {
      value.number = index;
      return value;
    });
  };

  

  const getPackageList = () => {
    return getBlockValue()?.packages.map((singlePackageDetails) => (
      <>
        <div className="package-card-wrapper">
          <div className="card-header">
            <div className="titles-wrapper">
              <div className="header-title">Paid add-on {singlePackageDetails?.number + 1}</div>
              <div className="sub-title">{singlePackageDetails?.packageType}</div>
            </div>
            <div className="edit-delete-wrapper">
              <div onClick={() => openEditPopUp(singlePackageDetails)} className="edit">
                <img className="img" src={editIcon} alt={'Edit'} />
              </div>
              <div onClick={() => openRemovePackagePopUp(singlePackageDetails)} className="delete">
                <img src={deleteIcon} alt={'Delete'} />
              </div>
            </div>
          </div>
          <div className="content-area">
            <div className="content-block description-wrapper">
              <div className="description-title">{singlePackageDetails?.packageName}</div>
              <div className="description">{singlePackageDetails?.description}</div>
            </div>
            <div className="content-block">
              <div className="price">
                {singlePackageDetails?.unitPrice} {getProfileData()?.tax_information?.[0]?.currency}
              </div>
              <div className="price-tag">
                {getProfileData()?.space_and_notice?.[0]?.activity?.[0]?.code === MEETING_SPACE_CODE ? `Max qty` : `Max qty`} •{' '}
                {singlePackageDetails?.maxGuestQty}
              </div>
            </div>
          </div>
        </div>
      </>
    ));
  };

  return (
    <>
      <div className="left-side-inner-wrapper availability-detais-wrapper block-pkg-details">
        <div className="pricing-option-label">
          <img
            className="icon"
            src={
              IMAGES_BASE_URL +
              getProfileData()?.seating_and_common?.[0]?.pricingOptionRaw?.filter((value) => value?.name?.toLowerCase() === pricingOption)?.[0]?.icon
            }
            alt={'Option Icon'}
          />
          <div className="title">
            <span style={{ textTransform: 'capitalize' }}>{pricingOption}</span> pricing
          </div>
        </div>
        <h1 className="listing-page-title">
          <span style={{ textTransform: 'capitalize' }}>{pricingOption}</span>: Paid add-ons
        </h1>
        <p>Do you want to add paid add-ons to your space?</p>
        <div className="btn-wrapper">
          <button
            className={`btn border-btn ${getBlockValue()?.['packageStatus'] ? 'active' : ''}`}
            onClick={() => handlePackageStatus('packageStatus', true)}
          >
            Yes
          </button>
          <button
            className={`btn border-btn ${!getBlockValue()?.['packageStatus'] ? 'active' : ''}`}
            onClick={() => handlePackageStatus('packageStatus', false)}
          >
            No
          </button>
        </div>
        {getBlockValue()?.['packageStatus'] && (
          <div className="package-section">
            <p className="gray-text">Please add the paid add-on and details:</p>
            <button className="border-btn green-border" onClick={() => openCreatePopUp()}>
              {' '}
              + ADD
            </button>
            {getPackageList()}
          </div>
        )}
      </div>
      <FlowHandlingButtons isActive={isNextBtnActive} />

      <DialogContainer className="common-popup delete-popup" visible={deletePopUp} id={'packagePopup'} onHide={cancelAddedPackage}>
        <>
          <h2 className="main-title">Delete paid add-on</h2>
          <p>Are you sure you want to delete the paid add-on?</p>
          <div className="btn-wrapper">
            <button className="border-btn" onClick={closeRemovePackagePopUp}>
              Cancel
            </button>
            <button className="red-btn" onClick={removePackage}>
              Delete
            </button>
          </div>
        </>
      </DialogContainer>

      <DialogContainer
        className="common-popup package-details-popup sm-input-height"
        visible={packagePopUpVisibility}
        id={'packagePopup'}
        onHide={cancelAddedPackage}
      >
        <>
          <h2 className="main-title">{isModelCreate ? 'Add' : 'Edit'} paid add-on</h2>
          <p className="gray-text sm-text">Add-on {Number(singlePackageData?.number) + 1}</p>

          <Select
            className={`input-margin-wrapper ${singlePackageData?.packageType?.length > 0 ? `placeHolderTop` : ``}`}
            placeholder="Add-on type"
            value={singlePackageData?.packageType}
            dropdownClassName="space-listing-drop-down package-drop-down"
            onSelect={(value) => {
              handleInputData('packageTypeId', value);
              handleInputData('packageType', packageTypes.filter((e) => value === e.id)?.[0]?.name || 'No Name Found');
            }}
            defaultActiveFirstOption={true}
          >
            {packageTypes.map((value) => (
              <Option key={value?.id || 'Error Occurred'}>
                <div className="wrapper">
                  <div className="title">{value?.name || 'Error Occurred'}</div>
                  {value?.name === 'Combo' && <div className="introduction">You can write your own combination in descriptions</div>}
                </div>
              </Option>
            ))}
          </Select>

          <TextField
            className="input-margin-wrapper"
            id="packageName"
            label="Add-on name"
            lineDirection="center"
            errorText="Package Name is required."
            value={singlePackageData?.packageName}
            onChange={(value) => handleInputData('packageName', value, 25, true, false)}
          />

          <TextField
            className="text-area"
            rows={2}
            id="description"
            label="Add-on description"
            lineDirection="center"
            errorText="Description is required."
            value={singlePackageData?.description}
            onChange={(value) => handleInputData('description', value, 100)}
          />

          <Tooltip
            placement="top"
            overlayClassName="popup-tooltip"
            title={
              <div className="tooltip-content">
                Maximum quantity from this <br /> package is available per guest{' '}
              </div>
            }
            visible={maxGuestToolTipVisibility}
          >
            <TextField
              id="maxGuestQty"
              label={getProfileData()?.space_and_notice?.[0]?.activity?.[0]?.code === WORK_SPACE_CODE ? `Max qty` : `Max qty`}
              lineDirection="center"
              errorText="Max qty per guest is required."
              value={singlePackageData?.maxGuestQty}
              onClick={() => handleToolTipVisibility(1)}
              onChange={(value) => handleInputData('maxGuestQty', value, 5000, true, true, true)}
            />
          </Tooltip>

          <Tooltip
            placement="top"
            overlayClassName="popup-tooltip"
            title={
              <div className="tooltip-content">
                Price per one unit of the package <br />
                excluding taxes
              </div>
            }
            visible={unitPriceTipVisibility}
          >
            <TextField
              id="unitPrice"
              label={`Unit Price (${getProfileData()?.tax_information?.[0]?.currency})`}
              lineDirection="center"
              errorText="Unit Price is required."
              value={singlePackageData?.unitPrice}
              onClick={() => handleToolTipVisibility(2)}
              onChange={(value) => handleInputData('unitPrice', value, 10000, true, true, true)}
            />
          </Tooltip>
          <div className="btn-seperater" />
          <div className="btn-wrapper">
            <button className="border-btn" onClick={cancelAddedPackage}>
              Cancel
            </button>
            {isModelCreate ? (
              <button className="border-btn gray-btn  active" onClick={addPackage} disabled={!isAddBtnActive}>
                Add
              </button>
            ) : (
              <button className="gray-btn" onClick={editPackage} disabled={!isAddBtnActive}>
                Edit
              </button>
            )}
          </div>
        </>
      </DialogContainer>
    </>
  );
};

export default PackageComponent;
