import {
  CLEAR_CONVERT_TO_HOST,
  CLEAR_DATA_SUBMIT,
  FETCH_ADDONS,
  FETCH_ADDONS_FAILS,
  FETCH_ADDONS_SUCCESS,
  FETCH_AMENITIES,
  FETCH_AMENITIES_FAILS,
  FETCH_AMENITIES_SUCCESS,
  FETCH_CANCELLATION_POLICY,
  FETCH_CANCELLATION_POLICY_FAILS,
  FETCH_CANCELLATION_POLICY_SUCCESS,
  FETCH_CONVERT_TO_HOST,
  FETCH_CONVERT_TO_HOST_FAILS,
  FETCH_CONVERT_TO_HOST_SUCCESS,
  FETCH_COUNTRY_WISE_EVENT_TYPE,
  FETCH_COUNTRY_WISE_EVENT_TYPE_FAILS,
  FETCH_COUNTRY_WISE_EVENT_TYPE_SUCCESS,
  FETCH_DATA_OBJECT,
  FETCH_DATA_OBJECT_FAILS,
  FETCH_DATA_OBJECT_SUCCESS,
  FETCH_DATA_SUBMIT,
  FETCH_DATA_SUBMIT_FAILS,
  FETCH_DATA_SUBMIT_SUCCESS,
  FETCH_LOCATION_STREET_NAME,
  FETCH_LOCATION_STREET_NAME_FAILS,
  FETCH_LOCATION_STREET_NAME_SUCCESS,
  FETCH_LOCATION_ADDRESS_LINE3,
  FETCH_LOCATION_ADDRESS_LINE3_SUCCESS,
  FETCH_LOCATION_ADDRESS_LINE3_FAILS,
  FETCH_MRT_STATIONS,
  FETCH_MRT_STATIONS_FAILS,
  FETCH_MRT_STATIONS_SUCCESS,
  FETCH_PACKAGE,
  FETCH_PACKAGE_FAILS,
  FETCH_PACKAGE_SUCCESS,
  FETCH_PRICING_OPTIONS,
  FETCH_PRICING_OPTIONS_DAYS_ARRAY,
  FETCH_PRICING_OPTIONS_DAYS_ARRAY_FAILS,
  FETCH_PRICING_OPTIONS_DAYS_ARRAY_SUCCESS,
  FETCH_PRICING_OPTIONS_FAILS,
  FETCH_PRICING_OPTIONS_SUCCESS,
  FETCH_RULES,
  FETCH_RULES_FAILS,
  FETCH_RULES_SUCCESS,
  FETCH_SAVINGS,
  FETCH_SAVINGS_FAILS,
  FETCH_SAVINGS_SUCCESS,
  FETCH_SEATING_ARRANGEMENT,
  FETCH_SEATING_ARRANGEMENT_FAILS,
  FETCH_SEATING_ARRANGEMENT_SUCCESS,
  FETCH_SG_TAX_DETAILS,
  FETCH_SG_TAX_DETAILS_FAILS,
  FETCH_SG_TAX_DETAILS_SUCCESS,
  FETCH_SL_TAX_DETAILS,
  FETCH_SL_TAX_DETAILS_FAILS,
  FETCH_SL_TAX_DETAILS_SUCCESS,
  FETCH_TYPES,
  FETCH_TYPES_FAILS,
  FETCH_TYPES_SUCCESS,
  MAP_PIN_UPDATED,
  MAP_ZOOM_UPDATED,
  REMOVE_TOKEN_UPDATE_STATUS,
  SET_TOKEN_UPDATE_STATUS,
  GET_LAT_LONG_BY_ADDRESS_SUCCESS,
  GET_LAT_LONG_BY_ADDRESS_FAILS,
  GET_NEAREST_TRAIN_STATION_BY_LAT_LNG,
  GET_NEAREST_TRAIN_STATION_BY_LAT_LNG_SUCCESS,
  GET_NEAREST_TRAIN_STATION_BY_LAT_LNG_FAILS,
  SYNC_WITH_CALENDAR,
  SYNC_WITH_CALENDAR_SUCCESS,
  SYNC_WITH_CALENDAR_FAILS,
  GET_GOOGLE_TOKEN_DETAILS_SUCCESS,
  GET_GOOGLE_TOKEN_DETAILS_FAILS,
  GET_EMAIL_SUCCESS,
  GET_EMAIL_FAILS,
  CALENDAR_AUTH_DATA,
  OUTLOOK_STATUS
} from './ListingActionTypes';

export const listingReducer = (
  state = {
    fetching: false,
    error: { status: false, message: '' },
    data: [],
    similarSpaces: [],
  },
  action
) => {
  switch (action.type) {
    case FETCH_TYPES:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_TYPES_SUCCESS:
      return {
        ...state,
        fetching: false,
        spaceTypes: action.payload,
      };
    case FETCH_TYPES_FAILS:
      return {
        ...state,
        fetching: false,
        spaceTypes: {},
      };

    case FETCH_COUNTRY_WISE_EVENT_TYPE:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_COUNTRY_WISE_EVENT_TYPE_SUCCESS:
      return {
        ...state,
        fetching: false,
        spaceTypesCountryWise: action.payload,
      };
    case FETCH_COUNTRY_WISE_EVENT_TYPE_FAILS:
      return {
        ...state,
        fetching: false,
        spaceTypesCountryWise: {},
      };

    case FETCH_SL_TAX_DETAILS:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_SL_TAX_DETAILS_SUCCESS:
      return {
        ...state,
        fetching: false,
        slTax: action.payload,
      };
    case FETCH_SL_TAX_DETAILS_FAILS:
      return {
        ...state,
        fetching: false,
        slTax: {},
      };

    case FETCH_SG_TAX_DETAILS:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_SG_TAX_DETAILS_SUCCESS:
      return {
        ...state,
        fetching: false,
        sgTax: action.payload,
      };
    case FETCH_SG_TAX_DETAILS_FAILS:
      return {
        ...state,
        fetching: false,
        sgTax: {},
      };

    case FETCH_PRICING_OPTIONS:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_PRICING_OPTIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        pricingOption: action.payload,
      };
    case FETCH_PRICING_OPTIONS_FAILS:
      return {
        ...state,
        fetching: false,
        pricingOption: {},
      };

    case FETCH_PRICING_OPTIONS_DAYS_ARRAY:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_PRICING_OPTIONS_DAYS_ARRAY_SUCCESS:
      return {
        ...state,
        fetching: false,
        pricingOptionDaysArray: action.payload,
      };
    case FETCH_PRICING_OPTIONS_DAYS_ARRAY_FAILS:
      return {
        ...state,
        fetching: false,
        pricingOptionDaysArray: {},
      };

    case FETCH_CANCELLATION_POLICY:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_CANCELLATION_POLICY_SUCCESS:
      return {
        ...state,
        fetching: false,
        cancellationPolicy: action.payload,
      };
    case FETCH_CANCELLATION_POLICY_FAILS:
      return {
        ...state,
        fetching: false,
        cancellationPolicy: {},
      };

    case FETCH_AMENITIES:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_AMENITIES_SUCCESS:
      return {
        ...state,
        fetching: false,
        amenities: action.payload,
      };
    case FETCH_AMENITIES_FAILS:
      return {
        ...state,
        fetching: false,
        amenities: {},
      };

    case FETCH_ADDONS:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_ADDONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        addons: action.payload,
      };
    case FETCH_ADDONS_FAILS:
      return {
        ...state,
        fetching: false,
        addons: {},
      };

    case FETCH_RULES:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_RULES_SUCCESS:
      return {
        ...state,
        fetching: false,
        rules: action.payload,
      };
    case FETCH_RULES_FAILS:
      return {
        ...state,
        fetching: false,
        rules: {},
      };

    case FETCH_MRT_STATIONS:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_MRT_STATIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        mrtStations: action.payload,
      };
    case FETCH_MRT_STATIONS_FAILS:
      return {
        ...state,
        fetching: false,
        mrtStations: {},
      };

    case FETCH_PACKAGE:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_PACKAGE_SUCCESS:
      return {
        ...state,
        fetching: false,
        packageTypes: action.payload,
      };
    case FETCH_PACKAGE_FAILS:
      return {
        ...state,
        fetching: false,
        packageTypes: {},
      };

    case FETCH_DATA_OBJECT:
      return {
        ...state,
        fetching: true,
        dataObject: {},
      };

    case FETCH_DATA_OBJECT_SUCCESS:
      return {
        ...state,
        fetching: false,
        listingData: action.payload,
      };
    case FETCH_DATA_OBJECT_FAILS:
      return {
        ...state,
        fetching: false,
        listingData: {},
      };

    case FETCH_SEATING_ARRANGEMENT:
      return {
        ...state,
        fetching: true,
        dataObject: {},
      };

    case FETCH_SEATING_ARRANGEMENT_SUCCESS:
      return {
        ...state,
        fetching: false,
        seatingArrangement: action.payload,
      };
    case FETCH_SEATING_ARRANGEMENT_FAILS:
      return {
        ...state,
        fetching: false,
        seatingArrangement: {},
      };

    case FETCH_SAVINGS:
      return {
        ...state,
        fetching: true,
        dataObject: {},
      };

    case FETCH_SAVINGS_SUCCESS:
      return {
        ...state,
        fetching: false,
        savings: action.payload,
      };
    case FETCH_SAVINGS_FAILS:
      return {
        ...state,
        fetching: false,
        savings: {},
      };

    case FETCH_DATA_SUBMIT:
      return {
        ...state,
        fetching: true,
        dataObject: {},
      };

    case FETCH_DATA_SUBMIT_SUCCESS:
      return {
        ...state,
        fetching: false,
        submitResponse: action.payload,
      };
    case FETCH_DATA_SUBMIT_FAILS:
      return {
        ...state,
        fetching: false,
        submitResponse: action.payload,
      };
    case CLEAR_DATA_SUBMIT:
      return {
        ...state,
        fetching: false,
        submitResponse: {},
      };

    case FETCH_CONVERT_TO_HOST:
      return {
        ...state,
        fetching: true,
        dataObject: {},
      };

    case FETCH_CONVERT_TO_HOST_SUCCESS:
      return {
        ...state,
        fetching: false,
        userToHost: action.payload,
      };
    case FETCH_CONVERT_TO_HOST_FAILS:
      return {
        ...state,
        fetching: false,
        userToHost: {},
      };

    case FETCH_LOCATION_STREET_NAME:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_LOCATION_STREET_NAME_SUCCESS:
      return {
        ...state,
        fetching: false,
        locationSearch: action.payload,
      };
    case FETCH_LOCATION_STREET_NAME_FAILS:
      return {
        ...state,
        fetching: false,
        locationSearch: {},
      };

    case FETCH_LOCATION_ADDRESS_LINE3:
      return {
        ...state,
        fetching: true,
      };

    case FETCH_LOCATION_ADDRESS_LINE3_SUCCESS:
      return {
        ...state,
        fetching: false,
        locationSearchAddress3: action.payload,
      };
    case FETCH_LOCATION_ADDRESS_LINE3_FAILS:
      return {
        ...state,
        fetching: false,
        locationSearchAddress3: [],
      };

    case CLEAR_CONVERT_TO_HOST:
      return {
        ...state,
        fetching: false,
        userToHost: {},
      };
    case SET_TOKEN_UPDATE_STATUS:
      return {
        ...state,
        fetching: false,
        tokenUpdatedStatus: { status: true },
      };
    case REMOVE_TOKEN_UPDATE_STATUS:
      return {
        ...state,
        fetching: false,
        tokenUpdatedStatus: { status: false },
      };
    case MAP_PIN_UPDATED:
      return {
        ...state,
        fetching: false,
        mapPinUpdatedTime: {
          time: new Date(),
          latitude: action.payload.latitude,
          longitude: action.payload.longitude,
        },
      };
    case MAP_ZOOM_UPDATED:
      return {
        ...state,
        fetching: false,
        mapZoomUpdated: {
          mapZoom: action.payload.mapZoom,
        },
      };

    case GET_LAT_LONG_BY_ADDRESS_SUCCESS:
      return {
        ...state,
        fetching: true,
        coordinates: action.payload,
      };
    case GET_LAT_LONG_BY_ADDRESS_FAILS:
      return {
        ...state,
        fetching: false,
        coordinates: [],
      };
    case GET_NEAREST_TRAIN_STATION_BY_LAT_LNG:
      return {
        ...state,
        fetching: true,
        dataObject: {},
      };

    case GET_NEAREST_TRAIN_STATION_BY_LAT_LNG_SUCCESS:
      return {
        ...state,
        fetching: false,
        nearestLocations: action.payload,
      };
    case GET_NEAREST_TRAIN_STATION_BY_LAT_LNG_FAILS:
      return {
        ...state,
        fetching: false,
        nearestLocations: {},
      };
    case SYNC_WITH_CALENDAR:
      return {
        ...state,
        fetching: true,
      };

    case SYNC_WITH_CALENDAR_SUCCESS:
      return {
        ...state,
        fetching: false,
        syncing: action.payload,
      };
    case SYNC_WITH_CALENDAR_FAILS:
      return {
        ...state,
        fetching: false,
        syncing: {},
      };
    case CALENDAR_AUTH_DATA:
      return { ...state, data: action.result };
    case OUTLOOK_STATUS:
      return { ...state, outlookStatus: action.result };

    case GET_GOOGLE_TOKEN_DETAILS_SUCCESS:
      return {
        ...state,
        fetching: false,
        tokenObj: action.payload,
      };
    case GET_GOOGLE_TOKEN_DETAILS_FAILS:
      return {
        ...state,
        fetching: false,
        tokenObj: {},
      };

    case GET_EMAIL_SUCCESS:
      return {
        ...state,
        fetching: false,
        authEmail: action.payload,
      };
    case GET_EMAIL_FAILS:
      return {
        ...state,
        fetching: false,
        authEmail: {},
      };

    default:
      return state;
  }
};
