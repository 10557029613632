import React, { useCallback, useEffect, useState } from "react";
import { TextField } from "react-md";
import { getFieldCurrentValue, handleDataObject } from '../Common/Common';
import { getProfileData } from "../Common/DataController";
import { DEFAULT_DATA_OBJ } from "../HostOnboardingConstants";
import FlowHandlingButtons from "../Common/HandleNavigationAndSubmit";
import { hostOnBoardingData } from "../Common/HostOnBoardingDataModal";
import informationIcon from "../../../assets/images/host-onboarding/information-transparent-icon.svg";
import { useParams } from 'react-router-dom';

const Commission = ({ currentBlock }) => {
  const { country } = useParams();
  const [commissionDetails, setCommissionDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : hostOnBoardingData(country));
  const [isNextBtnActive, setIsNextBtnActive] = useState(false);

  useEffect(() => {
    setIsNextBtnActive(getFieldCurrentValue(currentBlock,"percentage") > 0 && getFieldCurrentValue(currentBlock, "percentage") < 100);
  }, [commissionDetails]);

  return (
    <>
      <div className="left-side-inner-wrapper commission-details">
        <h1 className="listing-page-title">Commission percentage details</h1>
        <div>
          <div className="common-max-width-sm">
            <TextField
              className="input-margin-wrapper"
              id="percentage"
              label="Commission Percentage (%)"
              lineDirection="center"
              disabled={true}
              errorText="This field is required."
              onChange={(value) => handleDataObject("percentage", value, currentBlock, commissionDetails, setCommissionDetails, 5000, true, true, true)}
              value={getFieldCurrentValue(currentBlock,"percentage")}
            />
          </div>
        </div>
        <div className="icon-sentense-wrapper" style={{ backgroundImage: `url(${informationIcon})` }}>
          <div className="icon-sentense-inner-wrapper">
            <p className="green-text">
              MillionSpaces takes a {getFieldCurrentValue(currentBlock,"percentage")}% commission on the tax excluded amounts. This commission ensures you pay absolutely nothing to us unless we generate bookings. We use this revenue to cover all bank and credit card
              charges as well as all marketing expenses used to promote your property.
            </p>
          </div>
        </div>
      </div>
      <FlowHandlingButtons isActive={isNextBtnActive} />
    </>
  );
};

export default Commission;
