import {
    FETCH_COUNTRY_SHORT_CODE,
    FETCH_COUNTRY_SHORT_CODE_SUCCESS,
    FETCH_COUNTRY_SHORT_CODE_FAILS,
    ASSIGN_COUNTRY_SHORT_CODE,
    FETCH_IPLOCATION_SUCCESS,
    FETCH_IPLOCATION_FAILS
} from './locationActionTypes';

export const appReducer = (
    state = {
        fetching: false
    },
    action
) => {
    switch (action.type) {
        case FETCH_COUNTRY_SHORT_CODE:
            return Object.assign({}, state, { fetching: true });

        case FETCH_COUNTRY_SHORT_CODE_SUCCESS:
            return {
                ...state,
                fetching: false,
                data: { ...action.payload }
            };
        case FETCH_COUNTRY_SHORT_CODE_FAILS:
            return Object.assign({}, { data: [] }, { fetching: false }, { error: { status: true, message: '' } });

        case ASSIGN_COUNTRY_SHORT_CODE:
            return {
                ...state,
                data: { ...action.payload }
            };
        case FETCH_IPLOCATION_SUCCESS:
            if (action.payload?.location) {
            return {
                ...state,
                coordinates: {
                    lat : action.payload?.location?.lat,
                    lon : action.payload?.location?.lng
                }
            };
            } else {
                return {
                    ...state,
                    coordinates: action.payload
                };
            }

        default:
            return state;
    }
};
