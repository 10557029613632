import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import welcomeImg from '../../../assets/images/space-listing/welcome-yoga.svg';
import FlowHandlingButtons from "../Common/HandleNavigationAndSubmit";
import { BACK_TO_HOME, LETS_START } from '../SpaceListingConstants';
import arrowIcon from "../../../assets/images/common/black-arrow.svg";
import { useHistory } from 'react-router-dom';
import { getProfileData } from '../Common/DataController';
import { push } from "connected-react-router";
import { changeLoginModalVisibility } from "../../../redux/auth/authAction";
import decode from "jwt-decode";

const WelcomePage = ({ currentBlock, authDetails, setWelcomeNextClick }) => {
  const country = useSelector((state) => state?.locationMeta?.data?.country_longCode?.replace(" ", "-") || null);
  const history = useHistory();
  const dispatch = useDispatch();

  const goToPreviousPath = () => {
    history.push(`/${country}`);
  };

  const goToNext = () => {
    const usersDetails = authDetails ? decode(authDetails) : "";
    sessionStorage.setItem('nextClick', true);

    if (usersDetails !== "" || usersDetails !== "") {
      const nextBlock = getProfileData()?.routeList?.indexOf(window.location.pathname) + 1;
      dispatch(push(getProfileData()?.routeList?.[nextBlock]));
    }else {
      dispatch(changeLoginModalVisibility());
    }
  }

  return (
    <>
    <div className="left-side-inner-wrapper welcome-screen">
      <h1 className="listing-page-title" >Welcome to MillionSpaces space listing</h1>
      <div className="paragraph-wrapper">
        <p>You are about to start your space listing. If you’re a typing wizard, it may take you much less time but we recommend you allocate   
          <span className="text-bold"> 20-30 minutes </span>to this process.
        </p> 
        <p>
          <span className="text-bold text-italic"> “What is once well done is done forever” Henry David Thoreau.</span>
        </p>
        <p>Thank you for your time and we look forward to working with you. The MillionSpaces team.</p>
      </div>
      <div className="img-wrapper">
          <img className="img" src={welcomeImg} alt="welcome" />
      </div>
    </div>

    <div className="floating-btn-wrapper">
        <div className="floating-btn-inner-wrapper">
            <button className={`btn back-btn`} onClick={goToPreviousPath} id={"welcomePrev"}>
              <img className="back-icon" src={arrowIcon} alt="back" />
              {BACK_TO_HOME}
            </button>
            <button className={`btn next-btn`} id={"welcomeNext"} onClick={() => goToNext()}>
              {LETS_START}
            </button>
        </div>
      </div>

    {/* <FlowHandlingButtons backButtonText={BACK_TO_HOME} nextButtonText={LETS_START}/> */}
    </>
  );
};

export default WelcomePage;
