import React, {useState} from 'react';
import { BLOCK_OPTION, DAILY_OPTION, HOURLY_OPTION, MONTHLY_OPTION, WEEKLY_OPTION, DEFAULT_DATA_OBJ, SPACE_AND_NOTICE } from '../../SpaceListingConstants';
import BlockPricing from './BlockPricing/BlockPricing';
import { Redirect, Route, Switch } from 'react-router';
import DailyPricing from './DailyPricing/DailyPricing';
import MonthlyPricing from './MonthlyPricing/MonthlyPricing';
import WeeklyPricing from './WeeklyPricing/WeeklyPricing';
import HourlyPricing from './HourlyPricing/HourlyPricing';
import { useSelector } from 'react-redux';
import { getProfileData } from '../../Common/DataController';
import { spaceListingData } from '../../Common/ProfileListingDataModal';

const PricingOptions = () => {
  const country = useSelector((state) => state?.locationMeta?.data?.country_longCode?.replace(" ", "-") || null);
  const baseRouteURL = `/${country}/dashboard/list-a-space/pricing_options/`;
  const [seatingAndCommonDetails, setSeatingAndCommonDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));

  const chargingType = seatingAndCommonDetails?.[SPACE_AND_NOTICE]?.[0]?.['activity']?.[0]?.code;
  return (
    <>
      <Switch>
        <Route
          path={baseRouteURL + BLOCK_OPTION}
          component={() => {
            return <BlockPricing/>;
          }}
        />
        <Route path={baseRouteURL + DAILY_OPTION}>
          <DailyPricing chargingType={chargingType}/>
        </Route>
        <Route path={baseRouteURL + MONTHLY_OPTION}>
          <MonthlyPricing chargingType={chargingType}/>
        </Route>
        <Route path={baseRouteURL + WEEKLY_OPTION}>
          <WeeklyPricing chargingType={chargingType}/>
        </Route>
        <Route
          path={baseRouteURL + HOURLY_OPTION}
          component={() => {
            return <HourlyPricing/>;
          }}
        />
        <Route
          path={'*'}
          component={() => <Redirect to={`/${country}/dashboard/list-a-space/seating_and_common`}/>}
        />
      </Switch>
    </>
  );
}

export default PricingOptions;
