import React, { useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { gapi } from 'gapi-script';
import FlowHandlingButtons from '../Common/HandleNavigationAndSubmit';
import googleIcon from '../../../assets/images/calendarSync/googleicon.svg';
import yahooIcon from '../../../assets/images/calendarSync/yahoo.svg';
import outlookIcon from '../../../assets/images/calendarSync/outlook.svg';
import correct from '../../../assets/images/calendarSync/correct.svg';
import cancel from '../../../assets/images/calendarSync/cancel.svg';
import { setGoogleTokens, outlookStatus } from '../ListingAction';
import { client_id, REDIRECT_URL, scopes } from '../../../settings';
import { store } from '../../../index';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../authConfig';

const CLIENT_ID = client_id;
const SCOPES = scopes;

const CalendarSync = () => {
  const [googleStatus, setGoogleStatus] = useState(localStorage.getItem('googleSynced') === 'true' ? 1 : 0);
  const [outlookEnable, setoutlookEnable] = useState(0);
  const [disabled ,setDisabled] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: CLIENT_ID,
        plugin_name: 'millionspaces',
        scope: SCOPES,
      });
    };
    gapi.load('client:auth2', initClient);
  });

  const btnOutlookEnable = () => {
    handleLogin('popup');
  };

  const responseGoogle = (response) => {
    dispatch(setGoogleTokens(response.code));
    setGoogleStatus(1);
    setDisabled(true);
    localStorage.setItem('googleSynced', true);
  };

const revokeGoogleSync = () => {
  setGoogleStatus(0);
  setDisabled(false);
  localStorage.removeItem('googleSynced');
};

useEffect(() => {
  window.addEventListener('beforeunload', revokeGoogleSync);
  return () => {
  window.removeEventListener('beforeunload', revokeGoogleSync);
  };
}, []);

  useEffect(() => {
    const synced = localStorage.getItem('googleSynced') === 'true';
    if (synced && googleStatus === 0) {
      setGoogleStatus(1);
    } else if (!synced && googleStatus === 1) {
      setGoogleStatus(0);
    }
  }, [googleStatus]);

  const responseError = (error) => {
    console.log('Error', error);
  };

  //MSAL outlook
  const { instance } = useMsal();

  const handleLogin = (loginType) => {
    if(loginType === 'popup'){
    instance
      .loginPopup(loginRequest)
      .then(() => {
        setoutlookEnable(1);
        setDisabled(true);
        let statusOutlook = 1;
        store.dispatch(outlookStatus(statusOutlook));
      })
      .catch((e) => {
        console.log(e);
      });
    }
  };

  return (
    <>
      <div className="left-side-inner-wrapper">
        <h1 className="listing-page-title page-title-no-margin">Calendar Sync</h1>
        <p className="gray-text sm-text">
          This is an optional step where you can sync your calendar with Millionspaces. Please sign-in with the following options.(You can do the
          calendar syncing later as well)
        </p>

        <div className="calendar-section">
          <GoogleLogin
            clientId={CLIENT_ID}
            render={(btnGoogleEnable) => (
              <button className="btn calendar-btn" disabled={disabled}  onClick={btnGoogleEnable.onClick} style={!googleStatus ? {} : { border: '1px solid #19543E' }}>
                <img src={googleIcon} className="img" alt="img" />
                &nbsp;&nbsp;
                <p className="btn-name" style={googleStatus ? { display: 'none' } : {}}>
                  Google
                </p>
                {googleStatus ? (
                  <>
                    <div className="msg-and-icon">
                      <div className="google-and-msg">
                        &nbsp;&nbsp;Google
                        <p className="success-msg">You have successfully sync with Google&nbsp;</p>
                      </div>
                      <div className="correct-cancel">
                        <div className="correct-img">
                          <img src={correct}></img>
                        </div>
                        <div className="cancel-img">
                          <img src={cancel}></img>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </button>
            )}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseError}
            cookiePolicy={'single_host_origin'}
            responseType="code"
            accessType="offline"
            prompt="consent"
            scope={SCOPES}
            uxMode={'redirect'}
            redirectUri={REDIRECT_URL}
          />
      
          <button disabled={disabled}
           className="btn calendar-btn" onClick={() => btnOutlookEnable()} style={!outlookEnable ? {} : { border: '1px solid #19543E' }}>
            <img src={outlookIcon} className="img" alt="img" />
            &nbsp;&nbsp;
            <p className="btn-name" style={outlookEnable ? { display: 'none' } : {}}>
              Outlook
            </p>
            {outlookEnable ? (
              <>
                <div className="msg-and-icon">
                  <div className="google-and-msg">
                    &nbsp;&nbsp;Outlook
                    <p className="success-msg">You have successfully sync with Outlook</p>
                  </div>
                  <div className="correct-cancel">
                    <div className="correct-img">
                      <img src={correct}></img>
                    </div>
                    <div className="cancel-img">
                      <img src={cancel}></img>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
          </button>
        </div>
      </div>
      <FlowHandlingButtons />
    </>
  );
};

export default CalendarSync;
