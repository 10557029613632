import React from 'react';
import { TextField } from 'react-md';
import PropTypes from 'prop-types';

const CommonTextField = ({ label, placeholder, floating, disabled, leftIcon, value, onChange, type, active, name,onkeypress, autoComplete="off" }) => (
  <TextField
    id="floating-center-title"
    label={label}
    lineDirection="center"
    placeholder={placeholder}
    className="md-cell md-cell--bottom"
    floating={floating}
    disabled={disabled}
    leftIcon={leftIcon}
    value={value}
    onChange={value => onChange(value, name)}
    type={type}
    active={active}
    onKeyPress={onkeypress}
    autoComplete={autoComplete}
  />
);

CommonTextField.prototype = {
  placeholder: PropTypes.string,
  floating: PropTypes.bool,
  disabled: PropTypes.bool,
  leftIcon: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  type: PropTypes.string,
  active: PropTypes.bool,
  name: PropTypes.string
};

export default CommonTextField;
