import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { TimePicker } from 'antd';
import Select from 'antd/es/select';
import { TextField } from 'react-md';
import FlowHandlingButtons from '../../../Common/HandleNavigationAndSubmit';
import { contentIsNotNull } from '../../../Common/Validator';
import { spaceListingData } from '../../../Common/ProfileListingDataModal';
import { getProfileData, setProfileData } from '../../../Common/DataController';
import removeIcon from '../../../../../assets/images/space-listing/remove-red.svg';
import {
  DEFAULT_DATA_OBJ,
  ERROR_NUMBER_EXCEEDED,
  ERROR_ONLY_NUMBERS,
  HOURLY_OPTION,
  PRICING_OPTIONS
} from '../../../SpaceListingConstants';
import { showErrorMessage } from '../../../Common/Common';
import optionIcon from '../../../../../assets/images/space-listing/daily-pricing-icon.svg';
import { useParams } from 'react-router-dom';
import { IMAGES_BASE_URL } from '../../../../../settings';

const { Option, OptGroup } = Select;
const HourlyPricingAndAvailabilityDetails = ({pricingOption}) => {
  const { country } = useParams();
  const [hourlyPricingDetails, setHourlyPricingDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));
  const [isNextBtnActive, setIsNextBtnActive] = useState(false);
  const [selectedDiscounts, setSelectedDiscounts] = useState(hourlyPricingDetails?.["pricing_options"]?.[0]?.[HOURLY_OPTION]?.["pricingDetails"]?.["discountsTypes"] || []);

  useEffect(() => {
    setIsNextBtnActive(
      (getBlockValue()?.halfHourBookings !== undefined &&
        contentIsNotNull(getBlockValue()?.minimumBookingPeriod, 0)) &&
      (getBlockValue()?.discounts?.filter(value => {
        return !value?.slots?.filter(slot => {
          return !(slot?.discountPercentage > 0 && slot?.discountPercentage < 100);
        }).length > 0;
      }).length || getBlockValue()?.discounts?.length === 0)
    );
  }, [hourlyPricingDetails]);

  const handlePricingDetails = (type, price) => {
    const tempObj = Object.assign({}, hourlyPricingDetails, {
      ...hourlyPricingDetails,
      pricing_options: hourlyPricingDetails?.["pricing_options"].map((value) => {
        value[HOURLY_OPTION]["pricingDetails"][type] = price;
        return value;
      }),
    });
    setProfileData(tempObj);
    setHourlyPricingDetails(tempObj);
  };

  const handleDiscountTypes = (newValue) => {
    if (selectedDiscounts.length < newValue.length) {
      let differentValue = newValue.filter((value) => !selectedDiscounts.includes(value));
      addDiscounts(differentValue[0]);
    } else {
      let differentValue = selectedDiscounts.filter((value) => !newValue.includes(value));
      let loopCount = differentValue.length;
      while (loopCount--) {
        removeDiscounts(differentValue[loopCount]);
      }
    }
    setSelectedDiscounts(newValue);
    handlePricingDetails("discountsTypes", newValue);
  };

  const addDiscounts = (type) => {
    const tempObj = Object.assign({}, hourlyPricingDetails, {
      ...hourlyPricingDetails,
      pricing_options: hourlyPricingDetails?.["pricing_options"].map((value) => {
        value[HOURLY_OPTION]["pricingDetails"]["discounts"] = [
          ...value[HOURLY_OPTION]["pricingDetails"]["discounts"],
          {
            type: type,
            slots: [{ applicableFrom: undefined, discountPercentage: undefined }],
          },
        ];
        return value;
      }),
    });
    setProfileData(tempObj);
    setHourlyPricingDetails(tempObj);
  };

  const addSlot = (index) => {
    const tempObj = Object.assign({}, hourlyPricingDetails, {
      ...hourlyPricingDetails,
      pricing_options: hourlyPricingDetails?.["pricing_options"].map((value) => {
        value[HOURLY_OPTION]["pricingDetails"]["discounts"][index]["slots"] = [...value[HOURLY_OPTION]["pricingDetails"]["discounts"][index]["slots"], { applicableFrom: undefined, discountPercentage: undefined }];
        return value;
      }),
    });
    setProfileData(tempObj);
    setHourlyPricingDetails(tempObj);
  };

  const removeSlot = (discountTypeIndex, slotIndex) => {
    const tempObj = Object.assign({}, hourlyPricingDetails, {
      ...hourlyPricingDetails,
      pricing_options: hourlyPricingDetails?.["pricing_options"].map((value) => {
        value[HOURLY_OPTION]["pricingDetails"]["discounts"][discountTypeIndex]["slots"] = value?.[HOURLY_OPTION]?.["pricingDetails"]?.["discounts"]?.[discountTypeIndex]?.["slots"]?.filter((value, index) => {
          return index !== slotIndex;
        });
        return value;
      }),
    });
    setProfileData(tempObj);
    setHourlyPricingDetails(tempObj);
  };

  const updateSlot = (discountTypeIndex, slotIndex, type, newValue, onlyNumbers=false) => {
    if(onlyNumbers){
      newValue = newValue.replace(/^0+/, '');
    }
    if(onlyNumbers && Number(newValue) > 99){
      showErrorMessage(ERROR_NUMBER_EXCEEDED);
    } else  if (onlyNumbers && !(/^[0-9.]{0,60}$/i).test(newValue) && newValue !== '') {
      showErrorMessage(ERROR_ONLY_NUMBERS);
    } else {
      const tempObj = Object.assign({}, hourlyPricingDetails, {
        ...hourlyPricingDetails,
        pricing_options: hourlyPricingDetails?.["pricing_options"].map((value) => {
          value[HOURLY_OPTION]["pricingDetails"]["discounts"][discountTypeIndex]["slots"] = value?.[HOURLY_OPTION]?.["pricingDetails"]?.["discounts"]?.[discountTypeIndex]?.["slots"]?.map((value, index) => {
            if (index === slotIndex) {
              value[type] = newValue;
            }
            return value;
          });
          return value;
        }),
      });
      setProfileData(tempObj);
      setHourlyPricingDetails(tempObj);
    }
  };

  const removeDiscounts = (type) => {
    if (selectedDiscounts?.includes(type)) {
      const updatedArray = selectedDiscounts?.filter((value) => value !== type);
      setSelectedDiscounts(updatedArray);
      handlePricingDetails("discountsTypes", updatedArray);
    }
    const tempObj = Object.assign({}, hourlyPricingDetails, {
      ...hourlyPricingDetails,
      pricing_options: hourlyPricingDetails?.["pricing_options"].map((value) => {
        value[HOURLY_OPTION]["pricingDetails"]["discounts"] = value?.[HOURLY_OPTION]?.["pricingDetails"]?.["discounts"]?.filter((value) => {
          return value?.type !== type;
        });
        return value;
      }),
    });
    setProfileData(tempObj);
    setHourlyPricingDetails(tempObj);
  };

  const getBlockComponents = () => {
    return getBlockValue()?.discounts?.map((value, dayIndex) => (
      <div className="content-wrapper sm-input-height">
        <div className="card-wrapper">
          <div className="card-border">
            <div className="title-btn-wrapper">
              <span className="title">{value?.type}</span>
              <span className="remove-btn" onClick={() => removeDiscounts(value?.type)}>X</span>
            </div>

            {value?.slots?.map((slotValues, slotIndex) => (
              <div className="card">
                <div className="pickers-wrapper">
                  <div className="label">
                  Applicable from
                  </div>
                  <TimePicker
                    className={`input-margin-wrapper pricing-options`}
                    id="startTime"
                    popupClassName="card-hourly-picker-popup"
                    minuteStep={getBlockValue()?.halfHourBookings ? 30 : 60}
                    onChange={(value) => updateSlot(dayIndex, slotIndex, "applicableFrom", value?.format("HH:mm"))}
                    value={moment(slotValues?.applicableFrom || "00:00", "HH:mm")}
                    disabledHours={() => [9, 10, 11,12,13,14,15,16,17,18,19,20,21,22,23,24]}
                    hideDisabledOptions={true}
                    placeholder={"Applicable from"}
                    format={"HH:mm"}
                  />
                  <TextField
                    id="discount"
                    className="input-margin-wrapper"
                    label="Discount (%)"
                    lineDirection="center"
                    value={slotValues?.discountPercentage}
                    onChange={(value) => updateSlot(dayIndex, slotIndex, "discountPercentage", value, true)}
                  />
                  <div className="minus-btn" onClick={() => removeSlot(dayIndex, slotIndex)}>
                    <img className="icon" src={removeIcon} alt={"Remove icon"} />
                  </div>
                </div>
              </div>
            ))}

            {getNumberOfSlotCount() !== 2 && (<div className="link-text-wrapper" onClick={() => addSlot(dayIndex)}>
              <span className="link-text">+ Add More</span>
            </div>)}
          </div>
        </div>
      </div>
    ));
  };

  const getBlockValue = () => {
    return hourlyPricingDetails?.[PRICING_OPTIONS]?.[0]?.[HOURLY_OPTION]?.["pricingDetails"];
  };

  const getDiscountBlocks = () => {
    getNumberOfSlotCount()
    if(getNumberOfSlotCount() === 2){
      return  getBlockValue()?.discountsTypes?.map(value=><Option value={value}>{value}</Option>)
    } else {
      return getProfileData()?.savings?.[0]?.data?.map((value) => (
        <Option value={value?.name}>{value?.name}</Option>
      ))
    }
  }

  const getNumberOfSlotCount = () =>{
    return getBlockValue()?.discounts?.map(value => {
      return value.slots?.length;
    }).reduce((a, b) => a + b, 0);
  }

  return (
    <>
      <div className="left-side-inner-wrapper availability-detais-wrapper hourly-pricing">
        <div className="pricing-option-label">
          <img className="icon" src={IMAGES_BASE_URL + getProfileData()?.seating_and_common?.[0]?.pricingOptionRaw?.filter(value => value?.name?.toLowerCase() === pricingOption)?.[0]?.icon} alt={"Option Icon"} alt={"Option Icon"}/>
          <div className="title">Hourly pricing</div>
        </div>
        <h1 className="listing-page-title">Hourly pricing option</h1>
        <Select
          className={`input-margin-wrapper ${getBlockValue()?.halfHourBookings !== undefined ? "placeHolderTop" : ""}`}
          value={getBlockValue()?.halfHourBookings}
          dropdownClassName="space-listing-drop-down"
          placeholder={"Accept 0.5hr for bookings?"}
          onSelect={(value) => {
            handlePricingDetails("halfHourBookings", value);
            handlePricingDetails("minimumBookingPeriod", undefined);
            handlePricingDetails("bufferPeriod", undefined);
          }}
        >
          <OptGroup label={<span className="introduction">Are you allowing user to book your spaces in 0.5 hr period? (Eg: 8:30am)</span>}>
            <Option value={false}>No</Option>
            <Option value={true}>Yes</Option>
          </OptGroup>
        </Select>

        {getBlockValue()?.halfHourBookings !== undefined && (
          <>
            <div className="time-picker-wrapper">
              <div className="label">
                Minimum Booking Period (hr)
              </div>
              <TimePicker
                className="hourly-picker"
                popupClassName="hourly-picker-popup"
                minuteStep={getBlockValue()?.halfHourBookings ? 30 : 60}
                onChange={(value) => handlePricingDetails("minimumBookingPeriod", value?.format("HH:mm") || "00:00")}
                value={moment(getBlockValue()?.minimumBookingPeriod || "00:00", "HH:mm")}
                placeholder={"Minimum Booking Period? (hr)"}
                format={"HH:mm"}
              />
            </div>

            {getBlockValue()?.minimumBookingPeriod !== "00:00" && (
              <>
              <div className="time-picker-wrapper">
                <div className="label">
                  Buffer period (hr)
                </div>
                <TimePicker
                    className="hourly-picker"
                    popupClassName="hourly-picker-popup"
                    minuteStep={getBlockValue()?.halfHourBookings ? 30 : 60}
                    onChange={(value) => handlePricingDetails("bufferPeriod", value?.format("HH:mm") || "00:00")}
                    value={moment(getBlockValue()?.bufferPeriod || "00:00", "HH:mm")}
                    placeholder={"Minimum Booking Period? (hr)"}
                    format={"HH:mm"}
                  />
              </div>

                {getBlockValue()?.bufferPeriod !== "00:00" && (
                  <>
                    <hr className="divider" />
                    <div className="sub-title">Discount details</div>
                    <Select
                      className={`input-margin-wrapper placeHolderTop`}
                      dropdownClassName="space-listing-drop-down"
                      mode="multiple"
                      allowClear
                      placeholder="Discount type"
                      value={getBlockValue()?.discountsTypes}
                      onChange={(value) => handleDiscountTypes(value)}
                    >
                      <OptGroup label={<span className="introduction">You can add maximum two discount types. Eg: Two “Deals” or one “Deal” and a “Host discount”.</span>}>
                        {getDiscountBlocks()}
                      </OptGroup>
                    </Select>

                    {getBlockComponents()}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>

      <FlowHandlingButtons isActive={isNextBtnActive} />
    </>
  );
};

export default HourlyPricingAndAvailabilityDetails;
