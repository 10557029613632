import {
    SCHEDULE_VISIT,
    SCHEDULE_VISIT_SUCCESS,
    SCHEDULE_VISIT_FAILS,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_FAILS,
    SCHEDULE_VISIT_RESET
  } from './ScheduleActionTypes';
  
  export const scheduleVisitReducer = (
    state = {
        schedueVisit : {
          fetching: false,
          error: { status: false, message: "" },
          data: []
        },
        userDetails : {
          fetching: false,
          error: { status: false, message: "" },
          data: []
        },
    },action) => {
    switch (action.type) {
  
      case SCHEDULE_VISIT_SUCCESS:
        return {
          ...state,
          schedueVisit : {
            ...state.schedueVisit,
            fetching: false,
            schedueVisitSuccess: true,
            data: [],
            error: { status: false, message: "" }
          }
        };
      case SCHEDULE_VISIT_FAILS:
        return {
          ...state,
          schedueVisit : {
              ...state.schedueVisit,
              fetching: false,
              schedueVisitSuccess: false,
              data: []
          }
      };

      case SCHEDULE_VISIT_RESET:
        return {
          ...state,
          schedueVisit : {
          }
      };
        
      case GET_USER_DETAILS_SUCCESS:
        return {
          ...state,
          userDetails : {
            ...state.userDetails,
            fetching: false,
            userDetailsSuccess: true,
            data: action.payload,
            error: { status: false, message: "" }
          }
        };
      case GET_USER_DETAILS_FAILS:
        return {
          ...state,
          userDetails : {
              ...state.userDetails,
              fetching: false,
              userDetailsSuccess: false,
              data: [],
          }
      };
    
      default:
        return state;
    }
  };
  