import React, { useState } from 'react';
import moment from 'moment';
import { Checkbox, Button } from 'antd';

const YearMonthFilter = ({ onChange, filterType }) => {
  const [value, setValue] = useState([]);
  const years = [];
  const currentYear = moment().year().toString();
  const currentMonth = moment.months();

  for (let i = currentYear - 5; i <= currentYear; i++) {
    years.push(i);
  }

  const handleChange = (value) => {
    setValue(value);
  };

  const handleChangeSearch = () => {
    onChange(value);
  };

  const handleReset = () => {
    setValue([]);
    onChange([]);
  };

  return (
    <div className="report-dropdown-input">
      {filterType === 'year' ? (
        <div>
          <Checkbox.Group style={{ width: '100%', display: 'grid' }} value={value} onChange={handleChange}>
            <Checkbox value={'before'}> Before {currentYear - 5}  </Checkbox>
            {years.map(year => (
              <div>
                <Checkbox value={year}> {year} </Checkbox>
                <br/>
              </div>
            ))}
            <Checkbox value={'after'}> After {currentYear} </Checkbox>
          </Checkbox.Group>
        </div>
      ) : filterType === 'month' ? (
        <div>
          <Checkbox.Group options={currentMonth} style={{ width: '100%', display: 'grid' }} value={value} onChange={handleChange} />
        </div>
      ) : (
        <></>
      )}
      <div className="mt-3 border-top">
        <Button className="btn-reset" onClick={handleReset}>
          Reset
        </Button>
        <Button className="btn-search" onClick={handleChangeSearch}>
          Search
        </Button>
      </div>
    </div>
  );
};

export default YearMonthFilter;
