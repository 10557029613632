import {
  FETCH_SPACES_STATUS,
  FETCH_SPACES_STATUS_SUCCESS,
  FETCH_SPACES_STATUS_FAILS,

  FETCH_ALL_SPACES,
  FETCH_ALL_SPACES_SUCCESS,
  FETCH_ALL_SPACES_FAILS,

  FETCH_FILTERED_SPACE_DETAILS,
  FETCH_FILTERED_SPACE_DETAILS_SUCCESS,
  FETCH_MORE_SPACE_DETAILS_SUCCESS,
  FETCH_FILTERED_SPACE_DETAILS_FAILS,

  FETCH_SPACES_DETAILS,
  FETCH_SPACES_DETAILS_SUCCESS,
  FETCH_SPACES_DETAILS_FAILS,

  FETCH_CALENDER_BOOKING_DATA,
  FETCH_CALENDER_BOOKING_DATA_SUCCESS,
  FETCH_CALENDER_BOOKING_DATA_FAILS,

  FETCH_CALENDER_VISITS_DATA,
  FETCH_CALENDER_VISITS_DATA_SUCCESS,
  FETCH_CALENDER_VISITS_DATA_FAILS,
  UPDATE_VISIT_STATUS_SUCCESS,

  FETCH_SPACE_AVAILABILITY,
  FETCH_SPACE_AVAILABILITY_SUCCESS,
  FETCH_SPACE_AVAILABILITY_FAILS,
  CLEAR_SPACE_AVAILABILITY,

  FETCH_SELECTED_SPACE,
  FETCH_SELECTED_SPACE_SUCCESS,
  FETCH_SELECTED_SPACE_FAIL,
  CREATE_RESERVATION,
  CREATE_RESERVATION_SUCCESS,
  CREATE_RESERVATION_FAILED,
  CANCEL_SELECTED_BOOKING, UPDATE_RESERVATION, UPDATE_RESERVATION_SUCCESS, UPDATE_RESERVATION_FAILED
} from './HostDashboardActionTypes';
import {
  CANCEL_SELECTED_BOOKING_FAIL,
  CANCEL_SELECTED_BOOKING_SUCCESS
} from '../BookingCancelation/BookingCancellationActionTypes';
import { CLEAR_ALL } from '../../redux/actionTypes';

export const HostDashboardReducer = (
  state = {
    fetching : false,
    error: '',

    data: {
      spacesStatus: [],

      allListedSpacesNames: [],
  
      filteredSpaceDetails: [],

      spaceDetails : [],
  
      calenderData: [],
      },

      availability : {
        fetching : false,
        error : {message : null, status : null},
        data : []
      }
  },
  action
) => {
  switch (action.type) {
    case FETCH_SPACES_STATUS:
      return {
        ...state,
        fetching: true,
      }

    case FETCH_SPACES_STATUS_SUCCESS:
      return {
        ...state,
        fetching: false,
        data:{
          ...state.data,
          spacesStatus: action.payload
        },
      };
    case FETCH_SPACES_STATUS_FAILS:
      return {
        ...state,
        fetching: false,
        spacesStatus_error: action.payload.response
      };

    case FETCH_ALL_SPACES:
      return {
        ...state,
        fetching: true,
        error: ''
      }

    case FETCH_ALL_SPACES_SUCCESS:
      return {
        ...state,
        fetching: false,
        data:{
          ...state.data,
          allListedSpacesNames: action.payload
        },
      };
    case FETCH_ALL_SPACES_FAILS:
      return {
        ...state,
        fetching: false,
        error: action.payload.response
      };

    case FETCH_FILTERED_SPACE_DETAILS:
      return {
        ...state,
        fetching: true,
        error: ''
      }

    case FETCH_FILTERED_SPACE_DETAILS_SUCCESS:
      return {
        ...state,
        fetching: false,
        data:{
          ...state.data,
          filteredSpaceDetails: action.payload
        },
        error: ''
      };

    case FETCH_MORE_SPACE_DETAILS_SUCCESS:
      return {
        ...state,
        fetching: false,
        data:{
          ...state.data,
          filteredSpaceDetails: state.data.filteredSpaceDetails.concat(action.payload)
        },
        error: ''
      };
    case FETCH_FILTERED_SPACE_DETAILS_FAILS:
      return {
        ...state,
        fetching: false,
        error: action.payload.response
      };

    case FETCH_SPACES_DETAILS:
      return {
        ...state,
        fetching: true,
        error: ''
      }

    case FETCH_SPACES_DETAILS_SUCCESS:
      return {
        ...state,
        fetching: false,
        data:{
          ...state.data,
          spaceDetails: action.payload
        },
        error: ''
      };

    case FETCH_SPACES_DETAILS_FAILS:
      return {
        ...state,
        fetching: false,
        error: action.payload.response
      };

    case FETCH_CALENDER_BOOKING_DATA:
      return {
        ...state,
        fetching: true,
        error: ''
      }

    case FETCH_CALENDER_BOOKING_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        data:{
          ...state.data,
          calenderData: action.payload
        },
      };
    case FETCH_CALENDER_BOOKING_DATA_FAILS:
      return {
        ...state,
        fetching: false,
        error: action.payload.response
      };

    case FETCH_CALENDER_VISITS_DATA:
      return {
        ...state,
        fetching: true,
        error: ''
      }

    case FETCH_CALENDER_VISITS_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        data:{
          ...state.data,
          calenderData: action.payload
        },
      };
    case FETCH_CALENDER_VISITS_DATA_FAILS:
      return {
        ...state,
        fetching: false,
        error: action.payload.response
      };

    case FETCH_SPACE_AVAILABILITY:
      return {
        ...state,
        availability : {
          ...state.availability,
          fetching : true,
          error : {message : null, status : null},
          data : []
        }
      }

    case FETCH_SPACE_AVAILABILITY_SUCCESS:
        return {
          ...state,
          availability : {
            ...state.availability,
            fetching : false,
            error : {message : null, status : null},
            data : action.payload
          }
        };

    case FETCH_SPACE_AVAILABILITY_FAILS: 
        return {
          ...state,
          availability : {
            ...state.availability,
            fetching : false,
            error : {message : action.payload, status : true},
            data : []
          }
        };

    case CLEAR_SPACE_AVAILABILITY: 
        return {
          ...state,
          fetching: false,
          data:{
            ...state.data,
            spaceAvailability : []
          },
        };

    case FETCH_SELECTED_SPACE:
      return {
        ...state,
        fetching: true,
        error: ''
      }

    case FETCH_SELECTED_SPACE_SUCCESS:
        return {
          ...state,
          fetching: false,
          data:{
            ...state.data,
            selectedSpaceData : action.payload
          },
        };

    case FETCH_SELECTED_SPACE_FAIL: 
        return {
          ...state,
          fetching: false,
          data: { 
            ...state, 
            selectedSpaceData: {}
          },
          error: action.payload.response
        };

    case CREATE_RESERVATION:
      return {
        ...state,
        fetching: true,
        error: ''
      }

    case CREATE_RESERVATION_SUCCESS:
        return {
          ...state,
          fetching: false,
          data:{
            ...state.data,
            createReservation : action.payload
          },
        };

    case CREATE_RESERVATION_FAILED:
        return {
          ...state,
          fetching: false,
          data:{
            ...state.data,
            createReservation : {}
          },
          error: action.payload.response
        };


    case CANCEL_SELECTED_BOOKING:
      return {
        ...state,
        fetching: true,
        error: ''
      }

    case CANCEL_SELECTED_BOOKING_SUCCESS:
        return {
          ...state,
          fetching: false,
          data:{
            ...state.data,
            cancelReservation : {
              date:new Date(),
              payLoad:action.payload
            },
          },
        };

    case CANCEL_SELECTED_BOOKING_FAIL:
        return {
          ...state,
          fetching: false,
          data: {
            ...state,
            cancelReservation: {}
          },
          error: action.payload.response
        };


    case UPDATE_RESERVATION:
      return {
        ...state,
        fetching: true,
        error: ''
      }

    case UPDATE_RESERVATION_SUCCESS:
        return {
          ...state,
          fetching: false,
          data:{
            ...state.data,
            updateReservation : {
              date:new Date(),
              payLoad:action.payload
            },
          },
        };

    case UPDATE_RESERVATION_FAILED:
        return {
          ...state,
          fetching: false,
          data: {
            ...state.data,
            updateReservation: {}
          },
          error: action.payload.response
        };
    
    case UPDATE_VISIT_STATUS_SUCCESS:
      return {
        ...state,
        fetching: false,
        data:{
          ...state.data,
          visitSucess: true
        },
      };
    
    case CLEAR_ALL:
      return {}

    default:
      return state;
  }
};

