import {
    FETCH_SPACE_ACTIVITIES,
    FETCH_SPACE_ACTIVITIES_SUCCESS,
    FETCH_SPACE_ACTIVITIES_FAILS,
  } from '../../redux/actionTypes';

  export const spaceActivityReducer = (
    state = {
      error: { status: false, message: '' },
      data: [],
    },
    action
  ) => {
    switch (action.type) {

      case FETCH_SPACE_ACTIVITIES:
        return {
          ...state,
          data : []
        };
  
      case FETCH_SPACE_ACTIVITIES_SUCCESS:
        //Training activity removed temporarily
        const filteredPayload = action.payload.filter(category => category.name !== 'Train')
        return {
          ...state,
          data: filteredPayload,
        };
  
      case FETCH_SPACE_ACTIVITIES_FAILS:
        return {
          ...state,
          data: [],
        };
  
      default:
        return state;
    }
  };
  
  