const ui = 'ui/';
const search = 'search/';
export const spaceProfile = 'spaceProfile';
export const favorite = 'favorite';
export const filter = ui + 'filter';
export const spaces = 'spaces';
export const reviews = 'reviews';
export const space = 'space';
export const myprofile = 'myprofile';
export const mybooking = 'mybooking';
export const myfavourites = 'myfavourites';
export const countrycodes = 'countrycodes';
export const home = ui + 'home';
export const searchResult = search + 'filter';
export const spaceActivities = 'space/space-activities';
export const paymentToken = 'pay/client/token';
export const checkouts = 'pay/checkouts';
export const register_user = 'user';
export const user_login = 'user/auth';
export const priceRange = 'search/price-range';
export const filterMap= 'search/filter-map';
export const featuredMap= 'search/featured-space';
export const allMapSpaces= 'search/map';
