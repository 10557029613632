import { combineReducers } from 'redux';
import { spaceReducer } from '../components/Home/HomeReducer';
import { profileReducer } from '../components/SpaceProfile/ProfileReducer';
import { scheduleVisitReducer } from '../components/SpaceProfile/SubComponents/ScheduleVisit/ScheduleReducer';
import { connectRouter } from 'connected-react-router';
import { ResultReducer } from '../components/Result/ResultReducer';
import { HostDashboardReducer } from '../components/HostDashboard/HostDashboardReducer';
import { dashboardReducer } from '../components/Dashboard/DashboardReducer';
import { authReducer } from '../redux/auth/authReducer';
import { BookingReducer } from '../components/Booking/BookingReducer';
import { BookingCancelReducer } from '../components/BookingCancelation/BookingCancellationReducer';
import { appReducer } from './location/locationReducer';
import { paymentReducer } from '../components/SpaceProfile/Payments/paymentReducer';
import { stripeReducer } from '../components/SpaceProfile/Payments/PaymentMethod/Stripe/StripeReducer';
import { ReportReducer } from '../components/Reports/ReportReducer';
import { listingReducer } from '../components/SpaceListing/ListingReducer';
import {spaceActivityReducer} from './spaceActivity/spaceActivityReducer'
import { hostOnBoardingReducer } from '../components/HostOnBoarding/HostOnboardingReducer';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    locationMeta: appReducer,
    home: spaceReducer,
    spaceProfile: combineReducers({
      profileDetails: profileReducer,
      booking: BookingReducer,
      bookingCancel: BookingCancelReducer,
      payment: paymentReducer,
      scheduleVisit:scheduleVisitReducer,
    }),
    searchResult: ResultReducer,
    hostOnBoarding: hostOnBoardingReducer,
    spaceListing: listingReducer,
    dashboard: dashboardReducer,
    hostDashboard: HostDashboardReducer,
    auth: authReducer,
    stripe: stripeReducer,
    report: ReportReducer,
    activity: spaceActivityReducer
  });
