import { filter } from '../../api/resources';
import {
  FETCH_SEARCH_RESULT_FILTER_DATA,
  FETCH_SEARCH_RESULT_FILTER_DATA_SUCCESS,
  FETCH_SEARCH_RESULT_FILTER_DATA_FAILS,
  UPDATE_SEARCH_FILTER,
  CLEAR_SEARCH_FILTER,
  SET_SEARCH_FILTER,
  FETCH_SEARCH_RESULTS_DATA,
  FETCH_SEARCH_RESULTS_DATA_SUCCESS,
  FETCH_SEARCH_RESULTS_DATA_FAILS,
  SET_FILTER_OPEN,
  SET_FILTER_CLOSE,
  FETCH_SPACES_DATA,
  FETCH_SPACES_DATA_SUCCESS,
  FETCH_SPACES_DATA_FAILS,
  TOGGLE_MY_FAVOURITES_FAILS,
  TOGGLE_MY_FAVOURITES,
  TOGGLE_MY_FAVOURITES_SUCCESS,
  REFRESH_SPACES_DATA_SUCCESS,
  SET_CLEAR_ALL_SEARCH_FILTERS,
  SET_CLEAR_ALL_SEARCH_FILTERS_DONE,
  CLEAR_ALL_SEARCH_RESULTS_DATA_SUCCESS,
  FILTER_CHANGE_DONE,
  FETCH_SPACE_ACTIVITIES,
  FETCH_SPACE_ACTIVITIES_SUCCESS,
  FETCH_SPACE_ACTIVITIES_FAILS,
  UPDATE_MOUSE_OVER, FETCH_ALL_SPACES_FAILS, FETCH_ALL_SPACES_SUCCESS, FETCH_ALL_SPACES, FETCH_MRT_SUCCESS, CHANGE_FILTER_USED_STATE

} from '../../redux/actionTypes';

const updateFilterValue = (value, FilterType, changeValue) => {
    
      if(value.field === FilterType) {
        return Object.assign({}, value, { defaultValue: changeValue, isUsed: true });
      }else{
        if(value.sort){
          return  Object.assign({}, value, { sort : Object.assign({}, value.sort, updateFilterValue(value.sort, FilterType, changeValue))});
        }
        return value
      }
};

const setFilterValues = (filters, FilterType, changeValue) => {
  return filters.map((value => updateFilterValue(value, FilterType, changeValue)))
  // return filters.map(value => {
  //   if (value.field === FilterType) {
  //     return Object.assign({}, value, { defaultValue: changeValue, isUsed: true });
  //   } else {
  //     return value;
  //   }
  // });
};

const clearFilterValues = (filters, FilterType, changeValue) => {
  return filters.map(value => {
    if (value.field == FilterType) {
      return Object.assign({}, value, { defaultValue: changeValue, isUsed: false });
    } else {
      return value;
    }
  });
};



const setFavourite = (payload, spaces) => {
  const spaceId = payload.spaceId;
  const state = !payload.isFavourite;

  return spaces.map(space => {
    if (space.id == spaceId) {
      return Object.assign({
        ...space,
        isFavourite: true
      });
    } else {
      return space;
    }
  });
};

const sortUIFilters = (filters) => {
  return filters.sort(function(a,b){return a.more-b.more});
}

const sortFilters = (value) => {
  if(Boolean(Object.values(value).length)){
    return {...value, uiFilters : sortUIFilters(value.uiFilters) }
  }else{
    return []
  }
}

export const ResultReducer = (
  state = {
    fetchingFilters: false,
    fetchingSpaces: true,
    fetchingFavourites: false,
    error: { status: false, message: '' },
    data: [],
    isFilterChange: false,
    mrtDetails: []
  },
  action
) => {
  switch (action.type) {
    case FETCH_SEARCH_RESULT_FILTER_DATA:
      return Object.assign({}, state, { fetchingFilters: true });

    case FETCH_SEARCH_RESULT_FILTER_DATA_SUCCESS:
      return {
        ...state,
        fetchingFilters: false,
        data: { ...sortFilters(action.payload), isUsed: false, clearAll: false, isFilterChange: true }
      };
    case FETCH_SEARCH_RESULT_FILTER_DATA_FAILS:
      return Object.assign({}, { data: [] }, { fetchingFilters: false }, { error: { status: true, message: '' } });

    case SET_SEARCH_FILTER:
      return {
        ...state,
        data: {
          ...state.data,
          uiFilters: [...setFilterValues(state?.data?.uiFilters||[], action.filterField, action.payload)],
          isUsed: true
        }
      };
    case TOGGLE_MY_FAVOURITES:
      return {
        ...state,
        fetchingFavourites: true
      };

    case TOGGLE_MY_FAVOURITES_SUCCESS:
      return {
        ...state,
        fetchingFavourites: false,
        spaces: {
          ...state.spaces,
          data: setFavourite(action.payload, state.spaces.data)
        }
      };

    case TOGGLE_MY_FAVOURITES_FAILS:
      return {
        ...state,
        fetchingFavourites: false
      };

    case UPDATE_SEARCH_FILTER:
      return {
        ...state,
        data: {
          ...state.data,
          uiFilters: [...setFilterValues(state?.data?.uiFilters||[], action.filterField, action.payload)],
          isUsed: true,
          isFilterChange: false
        }
      };

    case CLEAR_SEARCH_FILTER:
      return {
        ...state,
        data: {
          ...state.data,
          uiFilters: [...clearFilterValues(state.data.uiFilters, action.filterField, action.payload)]
        }
      };

    case SET_CLEAR_ALL_SEARCH_FILTERS:
      return {
        ...state,
        data: {
          ...state.data,
          clearAll: true
        }
      };

    case SET_CLEAR_ALL_SEARCH_FILTERS_DONE:
      return {
        ...state,
        data: {
          ...state.data,
          clearAll: false
        }
      };


    case FETCH_SEARCH_RESULTS_DATA:
      return Object.assign({}, state, { fetchingFilters: true });

    case FETCH_SEARCH_RESULTS_DATA_SUCCESS:
      return {
        ...state,
        fetchingFilters: false,
        spaces: action.payload
      };


    case FETCH_SEARCH_RESULTS_DATA_FAILS:
      return Object.assign({}, { data: [] }, { fetchingFilters: false }, { error: { status: true, message: '' } });

    case SET_FILTER_OPEN:
      return {
        ...state,
        isFilterOpen: true
      };

    case SET_FILTER_CLOSE:
      return {
        ...state,
        isFilterOpen: false
      };

    case FETCH_SPACES_DATA:
      return Object.assign({}, state, { fetchingSpaces: true });

    case FETCH_SPACES_DATA_SUCCESS:
      return {
        ...state,
        fetchingSpaces: false,
        spaces: { data: action.payload }
      };

    case FETCH_ALL_SPACES:
      return Object.assign({}, state, { fetchingAllSpaces: true });

    case FETCH_ALL_SPACES_SUCCESS:
      return {
        ...state,
        fetchingAllSpaces: false,
        allSpaces: { data: action.payload }
      };
    case FETCH_ALL_SPACES_FAILS:
      return {
        ...state,
        fetchingAllSpaces: false,
        allSpaces: {  }
       };

    case REFRESH_SPACES_DATA_SUCCESS:
      return {
        ...state,
        fetchingSpaces: false,
        spaces: { ...state.spaces, data: action.payload }
      };

    case CLEAR_ALL_SEARCH_RESULTS_DATA_SUCCESS:
      return {
        ...state,
        spaces: {},
        data: {}
      };

    case FILTER_CHANGE_DONE:
      return {
        ...state,
        data: {
          ...state.data,
          isFilterChange: false
        }
      };

    case FETCH_SPACE_ACTIVITIES:
      return {
        ...state,
        spaceActivities: {
          ...state.spaceActivities,
          isFetching: true
        }
      };

    case FETCH_SPACE_ACTIVITIES_SUCCESS:
      //Training activity removed temporarily
      const filteredPayload = action.payload.filter(category => category.name !== 'Train')
      return {
        ...state,
        spaceActivities: {
          ...state.spaceActivities,
          isFetching: false,
          data: filteredPayload,
          error: { status: false, message: 'success' }
        }
      };

    case FETCH_SPACE_ACTIVITIES_FAILS:
      return {
        ...state,
        spaceActivities: {
          ...state.spaceActivities,
          isFetching: false,
          data: [],
          error: { status: true, message: action.payload }
        }
      };

    case UPDATE_MOUSE_OVER:
      return {
        ...state,
        infoWindow: action.payload.infoWondow,
        colorChange: action.payload.colorChange
    };
    
    case FETCH_MRT_SUCCESS:
      return {
        ...state,
        mrtDetails : action.payload,
        data: {
          ...state.data,
          uiFilters: [...(state?.data?.uiFilters||[]).map(filter => {
            if(filter.field === 'nearMrt'){
              return Object.assign({}, filter, { value: action.payload, isUsed : true }, )
            }
            return filter
          })],
          isUsed: false,
          isFilterChange: false
        }
    };

    case CHANGE_FILTER_USED_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          isUsed: true,
          isFilterChange: false
        }
    };
    
    default:
      return state;
  }
};

