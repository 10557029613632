import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { setProfileData } from '../Common/DataController';
import { hostOnBoardingData } from '../Common/HostOnBoardingDataModal';
import CongratsImg from '../../../assets/images/space-listing/congratulations.svg';

const Congratulations = () => {
  const { country } = useParams();
  const history = useHistory();
  const countryLongName = useSelector((state) => state?.locationMeta?.data?.country_longCode?.replace(" ", "-") || null);

  useEffect(()=>{
    setProfileData(hostOnBoardingData(country));
  },[])

  return (
    <>
      <div className="left-side-inner-wrapper congrats-screen">
        <h1 className="listing-page-title">Welcome to MillionSpaces</h1>
        <div className="content-wrapper">
          <div className="paragraph-wrapper">
            <p>Thank you & welcome to MillionSpaces!</p>
            <p> Our Host Relations team will review your details and get back to you in the next 24h to have your spaces live and ready to book!</p>
            <p> A confirmation has been sent to your email address. <span className="text">(it may sometimes land in your SPAM folder)</span></p>
          </div>
          <img className="congrats-img" src={CongratsImg} alt="congratulations" />
          <div className="btn-wrapper">
            <div className="btn yellow-btn" onClick={()=>history.push(`/${countryLongName}`)}>go to home</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Congratulations;
