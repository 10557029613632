import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Autocomplete, DialogContainer } from 'react-md';
import { useRouteMatch } from "react-router-dom";
import { push } from 'connected-react-router';
import { USER } from '../Common/constants';
import decode from "jwt-decode";

import searchIcon from '../../assets/images/common/search-black.svg';
import edit from '../../assets/images/host/edit-teal.svg';
import duplicateIcon from '../../assets/images/host/duplicate.svg';

import useWindowDimensions from '../Common/Hooks/useWindowDimensions';
import { fetchAllSpaces, getFilteredSpaces, getMoreSpaces } from './HostDashboardActions';
import HostFooter from './HostFooter';
import ProgressMessage from '../Common/ProgressMessage';
import SpaceList from './SpacesList';
import { setBookingFlowStatus } from '../Booking/BookingAction';

const content = (
    <div className="action-popover">
        <span className="list-items">
            <img src={edit} alt="img" className="icon" />Edit
        </span>
        <span className="list-items">
            <img src={duplicateIcon} alt="img" className="icon" />Duplicate
        </span>
    </div>
);

const Space = ({ divFocus, setDivFocus, bookingStatus, setClickedStatus, setScrolledPosition, isBackClicked, handleBackClicked, scrolledPosition }) => {
    const dimensions = useWindowDimensions();
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const { status: urlStatus, country } = useParams();

    const authToken = useSelector(state => state?.auth?.loginDetails?.data?.token);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': authToken
    }

    const activities = useSelector(state => state?.searchResult?.spaceActivities?.data || []);
    const listedSpaces = useSelector(state => state?.hostDashboard?.data?.allListedSpacesNames || []);
    const filteredSpaces = useSelector(state => state?.hostDashboard?.data?.filteredSpaceDetails || []);
    const authDetails = useSelector((state) => state?.auth?.loginDetails?.data?.token || null);

    const [mobileFilterVisibility, setMobileFilterVisibility] = useState(false);

    const [eventType, seteventType] = useState();
    const [eventTypeId, seteventTypeId] = useState();

    const [selectedStatus, setSelectedStatus] = useState('');
    const [statusAllSelected, setStatusAllSelected] = useState(true);

    const [spaceCategory, setspaceCategory] = useState('');
    const [spaceCategoryId, setSpaceCategoryId] = useState('');
    const [categoryAllSelected, setCategoryAllSelected] = useState(true);

    const [activityAllSelected, setActivityAllSelected] = useState(true);

    const [autoComplete, setAutocomplete] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [pageCount, setPageCount] = useState(1);
    const pageSize = 10;
    const [visibleComingSoon, setVisibleComingSoon] = useState(false);

    const mobileView = dimensions.width < 992;
    const truncationLimit = mobileView ? 15 : 30;
    const truncate = longText => typeof longText == 'string' && longText.length > truncationLimit ? `${longText.substring(0, truncationLimit)}...` : longText;

    const [userType, setUserType] = useState(null);

    useEffect(() => {
        const handleScrollChange = () => {
          setScrolledPosition(window.pageYOffset);
        };
        window.addEventListener('scroll', handleScrollChange);
    
        return () => {
          window.removeEventListener('scroll', handleScrollChange)
        }
      }, []);

    useEffect(() => {
        if(isBackClicked){
            window.scrollTo(0, scrolledPosition);
            handleBackClicked(!isBackClicked);
        }
    }, [isBackClicked])
      
    useEffect(() => {
        if (authToken) {
            setUserType(decode(authDetails)['authorities'][0])
        }
    }, [authToken])

    const handleSearch = () => {
        setDivFocus(false);
        setMobileFilterVisibility(false);
        const payload = {
            page: 1,
            size: 10,
            name: String(searchText),
            status: selectedStatus,
            categoryId: spaceCategoryId,
            activityId: eventTypeId
        }
        setSearchText("");
        dispatch(getFilteredSpaces(payload, headers));
    };

    const handleSearchBarOnBlur = () => {
        setDivFocus(false);
    };

    const handleScroll = () => {
        setDivFocus(false);
        setAutocomplete(false)
    }

    const handleStatusChange = (status) => {
        // This Feature is not needed for the MVP
        // setStatusAllSelected(false);
        // setSelectedStatus(status);
    }

    const handleStatusSelection = (status) => {
        if (status) {
            setPageCount(value => 1);
            dispatch(getFilteredSpaces({ page: 1, size: pageSize, status: status }, headers));
        }
        else
            dispatch(getFilteredSpaces({ page: 1, size: pageSize }, headers));

    };

    const handlespaceCategorySelection = (spaceCategory, index, spaceCategoryId) => {
        setCategoryAllSelected(false);
        setspaceCategory(spaceCategory);
        setSpaceCategoryId(spaceCategoryId);
        handlespaceActivityAllSelection();
    }

    const handlespaceCategoryAllSelection = () => {
        setCategoryAllSelected(true);
        setspaceCategory('All');
        setSpaceCategoryId('');
        handlespaceActivityAllSelection();
    }

    const handlespaceActivityAllSelection = () => {
        setActivityAllSelected(true);
        seteventType('All');
        seteventTypeId('');
    }

    const handleActivitySelection = (venueSelection, venueId) => {
        setActivityAllSelected(false);
        seteventType(venueSelection);
        seteventTypeId(venueId);
    }

    const handleOnCloseFilterMobileModal = () => {
        setMobileFilterVisibility(false)
        setDivFocus(false);
    }

    const handleAutocompleteOnClick = () => {
        const payload = {
            page: 1,
            size: 10,
            text: searchText
        }
        dispatch(fetchAllSpaces(payload, headers));
        if (dimensions?.width < 992) {
            setMobileFilterVisibility(true);
        } else {
            setDivFocus(true);
        }
    }

    const handleOnAutocomplete = (searchText) => {
        setSearchText(searchText);
    }

    const handleOnChange = (searchText) => {
        setSearchText(searchText);
        setAutocomplete(true);

        const payload = {
            page: 1,
            size: 10,
            text: searchText || ''
        }
        dispatch(fetchAllSpaces(payload, headers));
    }

    const handleSpaceCardOnClick = (space) => {
        const URLtoPush = `/${country}/dashboard/myspace/${space.id}`
        dispatch(push(URLtoPush));
    }

    useEffect(
        () => {
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll)
        }
    )

    const fetchMoreListItems = () => {
        const payload = {
            page: pageCount + 1,
            size: 10,
            name: searchText,
            status: bookingStatus,
            categoryId: spaceCategoryId,
            activityId: eventTypeId
        }
        dispatch(getMoreSpaces(payload, headers));
        setPageCount(pageCount + 1);
    };

    const capitalize = (status) => {
        if (typeof status !== 'string') return ''
        return status.charAt(0) + status.slice(1).toLowerCase()
    }

    const handleUrlStatusChange = (status) => {
        status === 'ALL'
            ? dispatch(getFilteredSpaces({ page: 1, size: pageSize }, headers))
            : dispatch(getFilteredSpaces({ page: 1, size: pageSize, status: status }, headers));

        setClickedStatus(status);
        setBookingFlowStatus(status)
    };

    useEffect(() => {
        if (urlStatus.toUpperCase() !== bookingStatus && !Boolean(filteredSpaces.length)) {
            handleUrlStatusChange(urlStatus.toUpperCase());
        }
    }, [urlStatus]);

    const isGuest = (authDetails) => authDetails ? decode(authDetails)?.authorities?.[0] === USER : true;

    return (
        <>
            <div className="host-search-bar-wrap">
                <Autocomplete
                    className="search-input"
                    placeholder='Search & Filter'
                    leftIcon={<img src={searchIcon} className="search-icon" />}
                    onClick={() => handleAutocompleteOnClick()}
                />
                {divFocus &&
                    <div
                        className="web-only-wrap"
                        tabindex="1"
                        onFocus={() => setDivFocus(true)}
                    >
                        <div class="arrow-up" ></div>
                        <div className="autocomplete-content" tabIndex="1">
                            <div className="wrap web">
                                <div className="list">
                                    <h5 className="title">Search by</h5>
                                    <Autocomplete
                                        className="search-input"
                                        placeholder='Search by space name'
                                        leftIcon={<img src={searchIcon} className="search-icon" />}
                                        data={listedSpaces}
                                        onAutocomplete={handleOnAutocomplete}
                                        onChange={(e) => handleOnChange(e)}
                                        onBlur={() => setAutocomplete(false)}
                                        ref={inputRef}
                                    />
                                </div>
                                {!autoComplete && <div className="list">
                                    <h5 className="title">Category</h5>
                                    {categoryAllSelected ?
                                        (<span
                                            className="list-item activity-list-item active"
                                            onClick={() => handlespaceCategoryAllSelection()}>
                                            All
                                        </span>) :
                                        (<span
                                            className="list-item activity-list-item"
                                            onClick={() => handlespaceCategoryAllSelection()}>
                                            All
                                        </span>)}
                                    {activities.map((spaceCategoryMap, spaceCategoryMapIndex) =>
                                        spaceCategoryMap.name == spaceCategory ? (
                                            <span
                                                className="list-item activity-list-item active"
                                                onClick={() => handlespaceCategorySelection(spaceCategoryMap.name, spaceCategoryMapIndex, spaceCategoryMap.id)}>
                                                {spaceCategoryMap.name}
                                            </span>
                                        ) : (
                                            <span
                                                className="list-item activity-list-item"
                                                onClick={() => handlespaceCategorySelection(spaceCategoryMap.name, spaceCategoryMapIndex, spaceCategoryMap.id)}>
                                                {spaceCategoryMap.name}
                                            </span>
                                        )
                                    )}
                                </div>}
                                {!autoComplete && !categoryAllSelected && <div className="list">
                                    <h5 className="title">Activity</h5>
                                    {activityAllSelected ?
                                        (<span
                                            className="list-item activity-list-item active"
                                            onClick={() => handlespaceActivityAllSelection()}
                                        >
                                            All
                                        </span>) :
                                        (<span
                                            className="list-item activity-list-item"
                                            onClick={() => handlespaceActivityAllSelection()}
                                        >
                                            All
                                        </span>)}
                                    {Boolean(activities.length) &&
                                        activities[0].venueType.length &&
                                        activities.find(activity => activity.name == spaceCategory).venueType.map(venue => {
                                            return venue.name == eventType ? (
                                                <span className="list-item activity-list-item active" onClick={() => handleActivitySelection(venue.name, venue.id)}>
                                                    {venue.name}
                                                </span>
                                            ) : (
                                                <span className="list-item activity-list-item" onClick={() => handleActivitySelection(venue.name, venue.id)}>
                                                    {venue.name}
                                                </span>
                                            );
                                        })}
                                </div>}
                            </div>
                            <div className="footer"><span className="search-btn" onClick={handleSearch}>Search</span></div>
                        </div>
                    </div>}
            </div>
            <SpaceList
                country={country}
                pageCount={pageCount}
                setPageCount={setPageCount}
                searchText={searchText}
                bookingStatus={bookingStatus}
                spaceCategoryId={spaceCategoryId}
                eventTypeId={eventTypeId}
            />
            <DialogContainer
                id="simple-full-page-dialog"
                visible={mobileFilterVisibility}
                fullPage
                className="host-dialog host-search-bar-wrap"
            >
                <div className="autocomplete-content" tabIndex="1">
                    <div class="dialog-header">
                        <div className="right-wrap">
                            <div class="close-icon-wrap" onClick={() => handleOnCloseFilterMobileModal()}>
                                <img class="close-icon" src="/static/media/close.efd71c8c.svg" />
                            </div>
                        </div>
                    </div>
                    <div className="wrap web">
                        <div className="list">
                            <h5 className="title">Search by</h5>
                            <Autocomplete
                                className="search-input"
                                placeholder='Search by space name'
                                leftIcon={<img src={searchIcon} className="search-icon" />}
                                data={listedSpaces}
                                onAutocomplete={handleOnAutocomplete}
                                onChange={(e) => handleOnChange(e)}
                                onBlur={() => setAutocomplete(false)}
                            />
                        </div>
                        {!autoComplete && <div className="list">
                            <h5 className="title">Category</h5>
                            {categoryAllSelected ?
                                (<span
                                    className="list-item activity-list-item active category-list-item"
                                    onClick={() => handlespaceCategoryAllSelection()}>
                                    All
                                </span>) :
                                (<span
                                    className="list-item activity-list-item category-list-item"
                                    onClick={() => handlespaceCategoryAllSelection()}>
                                    All
                                </span>)}
                            {activities.map((spaceCategoryMap, spaceCategoryMapIndex) =>
                                spaceCategoryMap.name == spaceCategory ? (
                                    <span
                                        className="list-item activity-list-item active category-list-item"
                                        onClick={() => handlespaceCategorySelection(spaceCategoryMap.name, spaceCategoryMapIndex, spaceCategoryMap.id)}>
                                        {spaceCategoryMap.name}
                                    </span>
                                ) : (
                                    <span
                                        className="list-item activity-list-item category-list-item"
                                        onClick={() => handlespaceCategorySelection(spaceCategoryMap.name, spaceCategoryMapIndex, spaceCategoryMap.id)}>
                                        {spaceCategoryMap.name}
                                    </span>
                                )
                            )}
                        </div>}
                        {!autoComplete && !categoryAllSelected && <div className="list">
                            <h5 className="title">Activity</h5>
                            {activityAllSelected ?
                                (<span
                                    className="list-item activity-list-item active"
                                    onClick={() => handlespaceActivityAllSelection()}
                                >
                                    All
                                </span>) :
                                (<span
                                    className="list-item activity-list-item"
                                    onClick={() => handlespaceActivityAllSelection()}
                                >
                                    All
                                </span>)}
                            {Boolean(activities.length) &&
                                activities[0].venueType.length &&
                                spaceCategory !== 'All' &&
                                activities.find(activity => activity.name == spaceCategory).venueType.map(venue => {
                                    return venue.name == eventType ? (
                                        <span className="list-item activity-list-item active" onClick={() => handleActivitySelection(venue.name, venue.id)}>
                                            {venue.name}
                                        </span>
                                    ) : (
                                        <span className="list-item activity-list-item" onClick={() => handleActivitySelection(venue.name, venue.id)}>
                                            {venue.name}
                                        </span>
                                    );
                                })}
                        </div>}
                    </div>
                    <div className="btn-wrap">
                        <span
                            className="search-btn"
                            onClick={handleSearch}>
                            Search
                        </span>
                    </div>
                </div>
            </DialogContainer>
            {mobileView && <HostFooter />}
            {visibleComingSoon && (<ProgressMessage handleComingSoonModalClose={() => setVisibleComingSoon(false)} />)}
        </>
    );
}

export default Space;
