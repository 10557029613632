import React, { useCallback, useEffect, useState } from 'react';
import FlowHandlingButtons from '../Common/HandleNavigationAndSubmit';
import { contentIsNotNull } from '../Common/Validator';
import { spaceListingData } from '../Common/ProfileListingDataModal';
import { IMAGE_CLOUD_FOLDER, IMAGE_CLOUD_NAME, IMAGE_CLOUD_PRESET } from '../../../settings';
import { getProfileData, setProfileData } from '../Common/DataController';
import { DEFAULT_DATA_OBJ } from '../SpaceListingConstants';
import { Col, Row } from 'antd';
import uploadIcon from '../../../assets/images/space-listing/uploadIcon.svg';
import checkIconWhite from '../../../assets/images/space-listing/check-icon-white.svg';
import deleteIconWhite from '../../../assets/images/space-listing/delete-white.svg';
import checkIconGreen from '../../../assets/images/space-listing/check-icon-green.svg';
import { useParams } from 'react-router-dom';

const UploadImages = ({ currentBlock }) => {
  const { country } = useParams();
  const [uploadImageDetails, setUploadImageDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));
  const [isNextBtnActive, setIsNextBtnActive] = useState(false);

  useEffect(() => {
   const isImages = getFieldCurrentValue('uploadedImages').filter(key => key?.resource_type !== 'video')
   setIsNextBtnActive(contentIsNotNull(isImages, 0, 10));
  }, [uploadImageDetails]);

  const handleUploadImages = useCallback((name, newValue) => {
    if (newValue) {
      const tempObj = Object.assign({}, uploadImageDetails, {
        ...uploadImageDetails,
        uploading_images: uploadImageDetails?.[currentBlock].map((value) => {
          value['uploadedImages'].push(newValue);
          return value;
        }),
      });
      setProfileData(tempObj);
      setUploadImageDetails(tempObj);
    }
  }, []);

  const changeSelectedImage = useCallback((name, newValue) => {
    if (newValue) {
      const tempObj = Object.assign({}, uploadImageDetails, {
        ...uploadImageDetails,
        uploading_images: uploadImageDetails?.[currentBlock].map((value) => {
          value['selectedImage'] = newValue;
          return value;
        }),
      });
      setProfileData(tempObj);
      setUploadImageDetails(tempObj);
    }
  }, []);

  const removeSelectedImage = (id) => {
    const tempObj = Object.assign({}, uploadImageDetails, {
      ...uploadImageDetails,
      uploading_images: uploadImageDetails?.[currentBlock].map((value) => {
        value['uploadedImages'] = value['uploadedImages'].filter((value1, index1) => value1?.asset_id !== id);
        return value;
      }),
    });
    setProfileData(tempObj);
    setUploadImageDetails(tempObj);
  };

  const removeSelectedVideo = (id) => {
    const tempObj = Object.assign({}, uploadImageDetails, {
      ...uploadImageDetails,
      uploading_images: uploadImageDetails?.[currentBlock].map((value) => {
        value['uploadedImages'] = value['uploadedImages'].filter((value1, index1) => value1?.asset_id !== id);
        return value;
      }),
    });
    setProfileData(tempObj);
    setUploadImageDetails(tempObj);
  };

  const getFieldCurrentValue = (type) => {
    return getProfileData()?.[currentBlock]?.[0]?.[type];
  };

  const openWidget = () => {
    window.cloudinary.openUploadWidget(
      {
        cloud_name: IMAGE_CLOUD_NAME,
        upload_preset: IMAGE_CLOUD_PRESET,
        theme: 'white',
        folder: IMAGE_CLOUD_FOLDER,
        client_allowed_formats: ['png', 'jpeg', 'pdf'],
        cropping: 'true',
        croppingCoordinatesMode: 'custom',
      },
      (error, result) => {
        if (!error && result && result?.event === 'success' && result?.info !== null && result?.info !== undefined) {
          handleUploadImages('uploadedImages', result?.info);
          changeSelectedImage('selectedImage', result?.info?.['asset_id']);
        }
      }
    );
  };

  const openVideoWidget = () => {
    window.cloudinary.openUploadWidget(
      {
        cloud_name: IMAGE_CLOUD_NAME,
        upload_preset: IMAGE_CLOUD_PRESET,
        theme: 'white',
        folder: IMAGE_CLOUD_FOLDER,
        client_allowed_formats: ['mp4'],
        cropping: 'true',
        croppingCoordinatesMode: 'custom',
      },
      (error, result) => {
        if (!error && result && result?.event === 'success' && result?.info !== null && result?.info !== undefined) {
          handleUploadImages('uploadedImages', result?.info);
          // changeSelectedImage('selectedImage', result?.info?.['asset_id']);
        }
      }
    );
  };

  const selectImage = (asset_id) => {
    changeSelectedImage('selectedImage', asset_id);
  };

  return (
    <>
      <div className="left-side-inner-wrapper upload-img-page">
        <div>
          <h1 className="listing-page-title page-title-no-margin">Uploads</h1>
          <p className="gray-text sm-text">
            Upload your best images. The human brain processes visuals up to 60,000 times faster than text: the better the images, the more bookings
            you’ll get!
          </p>
          <h4 className="upload-subtitle">
            <strong>Images</strong>
          </h4>
          <p className="gray-text sm-text upload-description">Minimum image resolution is 800 X 410px.</p>
          <div>
            <Row className="img-tile-wrapper">
              {getFieldCurrentValue('uploadedImages') &&
                getFieldCurrentValue('uploadedImages')
                  .filter((value) => value?.resource_type == 'image')
                  .map((value, index) => (
                    <Col className="img-tile">
                      <div 
                        onClick={() => selectImage(value?.['asset_id'])}
                        className={`img-tile-bgimg ${value?.['asset_id'] === getFieldCurrentValue('selectedImage') ? `select` : `unselect`}`}
                        // style={{ backgroundImage: `url(${value.url})` }}
                      >
                        <img className="tick-icon" src={checkIconGreen} />
                        <div className="hover-content">
                          <div className="hover-content-inner-wrapper">
                            <div className="btn-wrapper">
                              <img className="img" src={checkIconWhite} />
                              <span className="title">primary</span>
                            </div>
                            <div className="btn-wrapper" onClick={() => removeSelectedImage(value?.asset_id)}>
                              <img className="img" src={deleteIconWhite} />
                              <span className="title">delete</span>
                            </div>
                            {/* use this for mobile view

                        <div  onClick={() => removeSelectedImage(index)}>
                          <img className="img" src={deleteIconWhite} />
                        </div>
                        <div  onClick={() => removeSelectedImage(index)}>
                          <img className="img" src={checkIconWhite} />
                        </div> */}
                          </div>
                        </div>
                        <img src={`${value.url}`} className={'img-tile-bgimg'} />
                      </div>
                    </Col>
                  ))}
              {getFieldCurrentValue('uploadedImages').length < 10 && (
                <Col className="img-tile img-upload-wrapper" onClick={() => openWidget(true)}>
                  <div className="img-upload">
                    <img className="img" src={uploadIcon} alt="upload" />
                    <span className="title">upload</span>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </div>

        <div className="video-upload-section">
          <h4 className="upload-subtitle">
            <strong>Videos</strong>
          </h4>
          <p className="gray-text sm-text upload-description">
            Maximum video resolution is 720 X 480px and duration is 1 minute and the capacity is 100MB.
          </p>
          <div>
            <Row className="img-tile-wrapper">
              {getFieldCurrentValue('uploadedImages') &&
                getFieldCurrentValue('uploadedImages')
                  .filter((value) => ['mp4']?.includes(value?.format))
                  .map((value, index) => (
                    <Col className="img-tile">
                      <div
                        onClick={() => selectImage(value?.['asset_id'])}
                        className={`img-tile-bgimg ${value?.['asset_id'] === getFieldCurrentValue('selectedImage') ? `select` : `unselect`}`}
                      >
                        <img className="tick-icon" src={checkIconGreen} />
                        <div className="hover-content">
                          <div className="hover-content-inner-wrapper">
                            <div className="btn-wrapper">
                              <img className="img" src={checkIconWhite} />
                              <span className="title">primary</span>
                            </div>
                            <div className="btn-wrapper" onClick={() => removeSelectedVideo(value?.asset_id)}>
                              <img className="img" src={deleteIconWhite} />
                              <span className="title">delete</span>
                            </div>
                          </div>
                        </div>
                        <video src={`${value.url}`} className={'img-tile-bgimg'} />
                      </div>
                    </Col>
                  ))}
              {/* {getFieldCurrentValue("uploadedVideos").length < 10 && ( */}
              <Col className="img-tile img-upload-wrapper" onClick={() => openVideoWidget(true)}>
                <div className="img-upload">
                  <img className="img" src={uploadIcon} alt="upload" />
                  <span className="title">upload</span>
                </div>
              </Col>
              {/* )} */}
            </Row>
          </div>
        </div>
      </div>
      <FlowHandlingButtons isActive={isNextBtnActive} />
    </>
  );
};

export default UploadImages;
