import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import {
  AVAILABILITY, HOURLY_OPTION,
  MONTHLY_OPTION,
  PACKAGES, PRICING_OPTIONS,
} from '../../../SpaceListingConstants';
import MonthlyPricingDetails from './MonthlyPricingDetails';
import { useSelector } from 'react-redux';
import PricingAndAvailability from '../../../Common/PricingAndAvailablity';
import MonthlyPricingAvailability from './MonthlyPricingAvailablity';
import PackageComponent from '../../../Common/Packages';

const MonthlyPricing = ({chargingType}) => {
  const country = useSelector((state) => state?.locationMeta?.data?.country_longCode?.replace(" ", "-") || null);
  const baseRouteURL = `/${country}/dashboard/list-a-space/pricing_options/monthly/`;

  return (
    <>
      <Switch>
        <Route path={baseRouteURL + PRICING_OPTIONS}>
            <MonthlyPricingDetails chargingType={chargingType}/>
        </Route>
        <Route
          path={baseRouteURL + AVAILABILITY}
          component={() => {
            return <MonthlyPricingAvailability/>;
          }}
        />

        <Route
          path={baseRouteURL + PACKAGES}
          component={() => {
            return <PackageComponent pricingOption={MONTHLY_OPTION}/>;
          }}
        />
        <Route
          path={'*'}
          component={() => <Redirect to={baseRouteURL + AVAILABILITY}/>}
        />
      </Switch>
    </>
  );
}

export default MonthlyPricing;
