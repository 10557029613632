import React, { Component } from 'react';
import popularSpaceBadge from '../../assets/images/search-result/popularSpaceBadge.svg';

function Spacecard({ visibleMap, setImageQuality, space, IMAGES_BASE_URL, personWhite, locationWhite }) {

  return (
      <>
        <div
            className="search-result-thumbnail"
            style={{ backgroundImage: `url(${IMAGES_BASE_URL + setImageQuality() + space?.image})` }}
        >
          <div className="number-of-people">
            <img src={personWhite} className="img" alt="person" /> <span className="people">{space?.capacity}</span>
          </div>
          {space?.location?.distance < 999 && (
              <div className="distance-wrap">
                <img src={locationWhite} className="img" alt="person" />
                <div className="distance">
                  <b>{Math.round(space?.location?.distance)}</b> km
                </div>
              </div>
          )}
          <div className="popular-picks-icon">
              <div>{space?.popularPicks ? <img src={popularSpaceBadge} className="img" alt="icon" /> : ""}
                <div className="tooltip-wrapper">This is a popular space</div>
              </div>
          </div>
        </div>
      </>
  );
}

export default React.memo(Spacecard);
