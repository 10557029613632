export const FETCH_LOGIN = 'FETCH_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILS = 'LOGIN_FAILS';
export const UPDATE_AUTH_TOKEN = 'UPDATE_AUTH_TOKEN';
export const REMOVE_LOGIN_FAILS = 'REMOVE_LOGIN_ERROR';

export const REMOVE_AUTH_DETAILS = 'REMOVE_AUTH_DETAILS';
export const SUBMIT_USER_DETAILS = 'SUBMIT_USER_DETAILS';
export const SUBMIT_USER_DETAILS_SUCCESS = 'SUBMIT_USER_DETAILS_SUCCESS';
export const SUBMIT_USER_DETAILS_FAILS = 'SUBMIT_USER_DETAILS_FAILS';
export const SUBMIT_USER_DETAILS_EMAIL_SENT ='SUBMIT_USER_DETAILS_EMAIL_SENT';

export const CHANGE_LOGIN_VISIBILITY = 'CHANGE_LOGIN_VISIBILITY';

export const TOKEN_SUBMIT = 'TOKEN_SUBMIT';
export const TOKEN_SUBMIT_SUCCESS = 'TOKEN_SUBMIT_SUCCESS';
export const TOKEN_SUBMIT_ERROR = 'TOKEN_SUBMIT_ERROR';
export const TOKEN_ERROR_REMOVE = 'TOKEN_ERROR_REMOVE';
export const CLEAR_TOKEN = 'CLEAR_TOKEN'

export const SEND_FORGOT_PASSWORD_REQUEST ='SEND_FORGOT_PASSWORD_REQUEST';
export const SEND_FORGOT_PASSWORD_REQUEST_SUCCESS ='SEND_FORGOT_PASSWORD_REQUEST_SUCCESS';
export const SEND_FORGOT_PASSWORD_REQUEST_ERROR ='SEND_FORGOT_PASSWORD_REQUEST_ERROR';
export const UPDATE_FORGOT_PASSWORD_TOKEN = 'UPDATE_FORGOT_PASSWORD_TOKEN';
export const REMOVE_FORGOT_PASSWORD_TOKEN = 'REMOVE_FORGOT_PASSWORD_TOKEN';

export const SEND_VERIFY_PASSWORD = 'SEND_VERIFY_PASSWORD';
export const SEND_VERIFY_PASSWORD_SUCCESS = 'SEND_VERIFY_PASSWORD_SUCCESS';
export const SEND_VERIFY_PASSWORD_ERROR = 'SEND_VERIFY_PASSWORD_ERROR';

export const MOBILE_NUMBER_SUBMIT = 'MOBILE_NUMBER_SUBMIT';
export const MOBILE_NUMBER_SUBMIT_SUCCESS = 'MOBILE_NUMBER_SUBMIT_SUCCESS';
export const MOBILE_NUMBER_SUBMIT_ERROR = 'MOBILE_NUMBER_SUBMIT_ERROR';

export const OTP_SUBMIT = 'OTP_SUBMIT';
export const OTP_SUBMIT_SUCCESS = 'OTP_SUBMIT_SUCCESS';
export const OTP_SUBMIT_ERROR = 'OTP_SUBMIT_ERROR';

export const COUNTRYCODE_SUBMIT = 'COUNTRYCODE_SUBMIT';
export const COUNTRYCODE_SUBMIT_SUCCESS = 'COUNTRYCODE_SUBMIT_SUCCESS';
export const COUNTRYCODE_SUBMIT_ERROR = 'COUNTRYCODE_SUBMIT_ERROR';

export const GET_USER_DETAIL_BY_TOKEN  = 'GET_USER_DETAIL_BY_TOKEN';
export const GET_USER_DETAIL_BY_TOKEN_SUCCESS  = 'GET_USER_DETAIL_BY_TOKEN_SUCCESS';
export const GET_USER_DETAIL_BY_TOKEN_ERROR  = 'GET_USER_DETAIL_BY_TOKEN_ERROR';

export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';

export const RESEND_EMAIL_VERIFICATION = 'RESEND_EMAIL_VERIFICATION';
export const RESEND_EMAIL_VERIFICATION_SUCCESS = 'RESEND_EMAIL_VERIFICATION_SUCCESS';
export const RESEND_EMAIL_VERIFICATION_ERROR = 'RESEND_EMAIL_VERIFICATION_ERROR';
export const REMOVE_RESEND_EMAIL_VERIFICATION_DETAILS = 'REMOVE_RESEND_EMAIL_VERIFICATION_DETAILS';

export const SKIP_MOBILE_NUMBER = 'SKIP_MOBILE_NUMBER';

export const CLEAR_VERIFY_PASSWORD = 'CLEAR_VERIFY_PASSWORD';