export const getValidColoreForSpaceStatus = status => {
    switch (status) {
      case 'ACTIVE':
        return 'active';
      case 'PENDING':
        return 'pending';
      case 'DECLINED':
        return 'declined';
      case 'DEACTIVATED':
        return 'declined';
      default:
        return 'declined';
    }
};