import React, { useCallback, useState } from "react";
import { Checkbox } from "antd";
import { DEFAULT_DATA_OBJ } from "../HostOnboardingConstants";
import { singaporeAgreement } from "../Common/SingaporeAgreement";
import { sriLankanAgreement } from "../Common/SriLankanAgreement";
import FlowHandlingButtons from "../Common/HandleNavigationAndSubmit";
import { hostOnBoardingData } from "../Common/HostOnBoardingDataModal";
import { getProfileData, setProfileData } from "../Common/DataController";
import downloadIcon from "../../../assets/images/space-listing/download.svg";
import { SRI_LANKA_SHORT_CODE } from "../../SpaceListing/SpaceListingConstants";
import { getFieldCurrentValue } from '../Common/Common';
import { useParams } from 'react-router-dom';

const Agreement = ({ currentBlock }) => {
  const { country } = useParams();
  const [agreementDetails, setAgreementDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : hostOnBoardingData(country));

  const handleAgreementConfirmation = useCallback((name, newValue) => {
    const tempObj = Object.assign({}, agreementDetails, {
      ...agreementDetails,
      processStarted: true,
      [currentBlock]: agreementDetails?.[currentBlock].map((value) => {
        value[name] = newValue;
        return value;
      }),
    });
    setAgreementDetails(tempObj);
    setProfileData(tempObj);
  }, []);

  const isSriLanka = () => {
    return agreementDetails?.["organization_details"]?.[0]?.["country"] === SRI_LANKA_SHORT_CODE;
  };

  return (
    <>
      <div className="left-side-inner-wrapper host-agreement">
        <div className="title-btn-wrapper">
          <h1 className="listing-page-title">Host agreement</h1>
          <a href={isSriLanka() ? "https://drive.google.com/uc?export=download&id=1B5vvB6b9RyqZDBaOF4CGJtR7h2mVs8Dy" : "https://drive.google.com/uc?export=download&id=1LeRwwvZaJuiL_dnDXFIKk7vEWB5Yt1ch"}>
            <div className="download-btn">
              <img className="icon" src={downloadIcon} alt={"Download Icon"} />
              <div className="text">Download</div>
            </div>
          </a>
        </div>
        {isSriLanka() ? sriLankanAgreement() : singaporeAgreement()}
        <Checkbox className="check-box" value={getFieldCurrentValue(currentBlock,"status")} defaultChecked={getFieldCurrentValue(currentBlock,"status")} onChange={(value) => handleAgreementConfirmation("status", value?.target?.checked)}>
          I have read and accept the host agreement
        </Checkbox>
      </div>
      <FlowHandlingButtons nextButtonText={"Submit"} isSubmit={true} isActive={getFieldCurrentValue(currentBlock,"status")} />
    </>
  );
};

export default Agreement;
