export const FEATURED_DECK = 'FEATURED_DECK';
export const LOGO = 'LOGO';
export const LIST = 'LIST';
export const BLANK = 'BLANK';
export const IMAGE_GRID = 'IMAGE_GRID';
export const SLIDING_IMAGE_DECK = "SLIDING_IMAGE_DECK";
export const IMAGE_CARD_DECK = "IMAGE_CARD_DECK";
export const SUBSCRIBE = "SUBSCRIBE";
export const SPACE_CARD_DECK = "SPACE_CARD_DECK";
export const SINGLE_CARD_SLIDER = "SINGLE_CARD_SLIDER";
export const FOOTER = "FOOTER";

export const GET_CITY="GET_CITY"
export const GET_CITY_FAILS="GET_CITY_FAILS"


// Promo code popup feature.
export const FETCH_PROMO_CODE_UPDATE = "FETCH_PROMO_CODE_UPDATE";
export const FETCH_PROMO_CODE_UPDATE_SUCCESS = "FETCH_PROMO_CODE_UPDATE_SUCCESS";
export const FETCH_PROMO_CODE_UPDATE_FAILS = "FETCH_PROMO_CODE_UPDATE_FAILS";

export const PROMO_CODE_UPDATE = "PROMO_CODE_UPDATE";
export const PROMO_CODE_UPDATE_SUCCESS = "PROMO_CODE_UPDATE_SUCCESS";
export const PROMO_CODE_UPDATE_FAILS = "PROMO_CODE_UPDATE_FAILS";
