import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from 'antd';
import { getSummaryTrackerData, getSearchResults, getSearchResultsAsLists } from '../ReportAction';
import {
  summaryTrackerObj,
  searchTextOnFilter,
  searchOnFilter,
  handleNullValues,
  arrayColumnFilter,
  searchFilterForEmptyFields,
  summaryTrackerResultsObj,
  summaryTrackerObjLists,
  setToolTip,
} from '../../../helper/ReportingHelper';
import InfiniteTable from '../../Common/Table/InfiniteTable';
import SearchText from '../common/SearchText';
import SearchFilters from '../common/SearchFilters';
import withTracker from '../HOCs/withTracker';
import HeaderButton from '../common/HeaderButton';
import decode from 'jwt-decode';

const SummaryTracker = ({ handleDownload, status }) => {
  const dispatch = useDispatch();
  const [searchingKey, setSearchingKey] = useState('');
  const [searchingStatus, setSearchingStatus] = useState(false);
  const [searchStatusList, setSearchStatusList] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataSourcesLists, setDataSourcesLists] = useState([]);
  const [tableData, setTableData] = useState({});
  const [dataLoading, setDataLoading] = useState(false);
  const dataloading = useSelector((state) => state?.report?.reports?.summary?.loading);
  const summaryTrackerSearchResult = useSelector((state) => state?.report?.searchResult || {});
  const summaryTrackerSearchResultList = useSelector((state) => state?.report?.searchResultList || []);
  const authToken = useSelector((state) => state?.auth?.loginDetails?.data?.token);
  const summaryTrackerData = useSelector((state) => state?.report?.reports?.summary?.data);
  const loggedUser = authToken ? decode(authToken) : '';
  const isSuperAdmin = loggedUser.authorities && (loggedUser.authorities[0] === 'role.suadmin') ? true : false;

  const AuthTokenData = decode(authToken);
  const userCountry = AuthTokenData?.location ?.country;

  useEffect(() => {
    setDataLoading(dataloading);
  }, [dataloading]);

  useEffect(() => {
    const formattedData = summaryTrackerObj(summaryTrackerData);
    formattedData?.length && setDataSource(formattedData);
  }, [summaryTrackerData]);
  useEffect(() => {
    if (summaryTrackerSearchResultList.length) {
      const formattedDataLists = summaryTrackerObjLists(summaryTrackerSearchResultList);
      formattedDataLists?.length && setDataSourcesLists(formattedDataLists);
    }
  }, [summaryTrackerSearchResultList]);

  const handleSearch = (setSelectedKeys, confirm, searchText, type, mainType, searchTextKey) => {
    const startDate = searchText[0];
    const endDate = searchText[searchText.length - 1];
    setSearchingKey(searchTextKey);
    dispatch(getSearchResults(searchText, type, mainType, startDate, endDate));
    setSearchingStatus(true);
  };

  const handleReset = (setSelectedKeys, clearFilters) => {
    setSearchingStatus(false);
    setSelectedKeys([]);
    clearFilters();
  };

  const handleSearchList = (setSelectedKeys, confirm, searchText, type, mainType) => {
    let startDate = '';
    let endDate = '';
    if (type === 'date') {
      startDate = searchText[0];
      endDate = searchText[searchText.length - 1];
    }
    setSearchingKey(type);
    dispatch(getSearchResultsAsLists(searchText, type, mainType, startDate, endDate));
    setSearchStatusList(true);
  };

  const handleResetList = (setSelectedKeys, clearFilters) => {
    setSearchStatusList(false);
    setSelectedKeys([]);
    clearFilters();
  };

  const handleFilterSearch = (setSelectedKeys, confirm, searchText) => {
    setSelectedKeys([searchText]);
    confirm();
  };

  const handleFilterReset = (setSelectedKeys, clearFilters) => {
    setSelectedKeys([]);
    clearFilters();
  };

  const checkObj = (searchingKey) => {
    let searchText1 = searchingKey;

    let res = [];
    if (summaryTrackerSearchResult.hasOwnProperty(searchText1) && summaryTrackerSearchResult[searchText1] === null) {
      res = []; 
    } else {
      res = [summaryTrackerResultsObj(summaryTrackerSearchResult)];
    }

    return res;
  };

  const summeryTrackerColumns = [
    {
      key: 'spaceCountry',
      title: 'Space Country',
      dataIndex: 'spaceCountry',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return text === 'LK' ? 'SL' : handleNullValues(text);
      },
      filterDropdown: isSuperAdmin ?  ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="country"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ): null,
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '61px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'spaceCountry');
      },
    },
    {
      key: 'bookingRef',
      title: 'Booking Id',
      dataIndex: 'bookingId',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, type, mainType, searchTextKey }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) =>
            handleSearch(setSelectedKeys, confirm, searchText, (type = 'booking'), (mainType = 'summary-tracker'), (searchTextKey = 'bookingId'))
          }
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '62px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'bookingId');
      },
    },
    {
      key: 'bookingStatus',
      title: 'Booking Status',
      dataIndex: 'bookingStatus',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="bookingStatus"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '62px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchFilterForEmptyFields(value, record, 'bookingStatus');
      },
    },
    {
      key: 'bookingType',
      title: 'Booking Type',
      dataIndex: 'bookingType',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'guestCount',
      title: 'Guest(s)',
      dataIndex: 'guestCount',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'paymentStatus',
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="paymentStatus"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '66px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchFilterForEmptyFields(value, record, 'paymentStatus');
      },
    },
    {
      key: 'bookingDate',
      title: 'Booking Date',
      dataIndex: 'bookingDate',
      width: 113,
    },
    {
      key: 'eventStartDate',
      title: 'Event Start Date',
      dataIndex: 'eventStartDate',
      width: 113,
    },
    {
      key: 'paymentAmount',
      title: 'Payment Amount',
      dataIndex: 'paymentAmount',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'cardType',
      title: 'Card Type',
      dataIndex: 'cardType',
      className: 'custom-width',
      width: 150,
      render: (text) => {
        let cardName;
        switch (text) {
          case 'visa':
            cardName = 'VISA';
            return <div>{cardName}</div>;
          case 'mastercard':
            cardName = 'MASTERCARD';
            return <div>{cardName}</div>;
          case 'amex':
            cardName = 'AMEX';
            return <div>{cardName}</div>;
          default:
            return handleNullValues(text);
        }
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="cardType"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '80px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchFilterForEmptyFields(value, record, 'cardType');
      },
    },
    {
      key: 'guestName',
      title: 'Guest Name',
      dataIndex: 'guestName',
      className: 'custom-width',
      width: 150,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, type, mainType, startDate, endDate }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) =>
            handleSearchList(
              setSelectedKeys,
              confirm,
              searchText,
              (type = 'guestName'),
              (mainType = 'summary-tracker'),
              (startDate = ''),
              (endDate = '')
            )
          }
          onReset={() => handleResetList(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '48px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'guestName');
      },
    },
    {
      key: 'guestEmail',
      title: 'Guest Email',
      dataIndex: 'guestEmail',
      width: 150,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'activity',
      title: 'Activity',
      dataIndex: 'activity',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="activity"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '59px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'activity');
      },
    },
    {
      key: 'spaceName',
      title: 'Space Name',
      dataIndex: 'spaceName',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, type, mainType, startDate, endDate }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) =>
            handleSearchList(
              setSelectedKeys,
              confirm,
              searchText,
              (type = 'spaceName'),
              (mainType = 'summary-tracker'),
              (startDate = ''),
              (endDate = '')
            )
          }
          onReset={() => handleResetList(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '48px' }}></Icon>,
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'spaceName');
      },
    },
  ];

  const downloadCSV = () => {
    handleDownload({ ...tableData, reportType: 'summary-tracker', totalDataLength: dataSource?.length, token: authToken, spaceCountry: userCountry });
  };

  return (
    <div>
      <HeaderButton handleDownload={downloadCSV} status={status} />

      <InfiniteTable
        columns={summeryTrackerColumns}
        dataSource={searchingStatus ? checkObj(searchingKey) : searchStatusList ? dataSourcesLists : dataSource}
        tableDispatch={getSummaryTrackerData}
        props={{ increaseValue: 20, token: authToken }}
        tableData={setTableData}
        scroll={{ y: 330, x: 500 }}
        searchStatus={searchingStatus}
        searchStatusList={searchStatusList}
        loading={dataLoading}
      />
    </div>
  );
};

export default withTracker('SUMMARY_TRACKER')(SummaryTracker);
