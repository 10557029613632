import * as actions from './HostDashboardActionTypes';
import { space } from '../../api/resources';

export const fetchSpacesStatus = () => {
  return (dispatch, getState, { axiosInstance }) => {
    dispatch({
      type: actions.FETCH_SPACES_STATUS,
    });

    axiosInstance
      .get(`space/status`)
      .then((res) => {
        dispatch({
          type: actions.FETCH_SPACES_STATUS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.FETCH_SPACES_STATUS_FAILS,
          payload: error,
        });
      });
  };
};

export const fetchAllSpaces = (params, headers) => {
  return (dispatch, getState, { axiosInstance }) => {
    dispatch({
      type: actions.FETCH_ALL_SPACES,
    });

    axiosInstance
      .get(`space/name?page=${params.page}&size=${params.size}&text=${params.text}`, { headers })
      .then((res) => {
        dispatch({
          type: actions.FETCH_ALL_SPACES_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.FETCH_ALL_SPACES_FAILS,
          payload: error,
        });
      });
  };
};

const getHostDetails = (param) => {
  return {
    page: param.page,
    size: param.size,
    dataView: 'HOST_LIST_VIEW',
    ...(param.categoryId ? { categoryId: param.categoryId } : {}),
    ...(param.activityId ? { activityId: param.activityId } : {}),
    ...(param.name ? { name: param.name } : {}),
    ...(param.status ? { status: param.status } : {}),
  };
};

export const getFilteredSpaces = (params, headers) => {
  return (dispatch, getState, { axiosInstance }) => {
    dispatch({
      type: actions.FETCH_FILTERED_SPACE_DETAILS,
    });
    axiosInstance
      .get(`space`, {
        params: getHostDetails(params),
        headers,
      })
      .then((res) => {
        dispatch({
          type: actions.FETCH_FILTERED_SPACE_DETAILS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.FETCH_FILTERED_SPACE_DETAILS_FAILS,
          payload: error,
        });
      });
  };
};

export const getMoreSpaces = (params, headers) => {
  return (dispatch, getState, { axiosInstance }) => {
    dispatch({
      type: actions.FETCH_FILTERED_SPACE_DETAILS,
    });
    axiosInstance
      .get(`space`, {
        params: getHostDetails(params),
        headers,
      })
      .then((res) => {
        dispatch({
          type: actions.FETCH_MORE_SPACE_DETAILS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.FETCH_FILTERED_SPACE_DETAILS_FAILS,
          payload: error,
        });
      });
  };
};

export const fetchSpaceDetails = (spaceId) => {
  return (dispatch, getState, { axiosInstance }) => {
    dispatch({
      type: actions.FETCH_SPACES_DETAILS,
    });
    axiosInstance
      .get(`space/${spaceId}`)
      .then((res) => {
        dispatch({
          type: actions.FETCH_SPACES_DETAILS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.FETCH_SPACES_DETAILS_FAILS,
          payload: error,
        });
      });
  };
};

export const getCalenderData = (spaceId, params, headers) => {
  return (dispatch, getState, { axiosInstance }) => {
    dispatch({
      type: actions.FETCH_CALENDER_BOOKING_DATA,
    });
    axiosInstance
      .get(`booking/calendar/${spaceId}?startDate=${params.startDate}&endDate=${params.endDate}`, { headers })
      .then((res) => {
        dispatch({
          type: actions.FETCH_CALENDER_BOOKING_DATA_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.FETCH_CALENDER_BOOKING_DATA_FAILS,
          payload: error,
        });
      });
  };
};

export const getCalenderVisitsData = (spaceId, params) => {
  return (dispatch, getState, { axiosInstance }) => {
    dispatch({
      type: actions.FETCH_CALENDER_VISITS_DATA,
    });
    axiosInstance
      .get(`booking/schedule-a-visit/space/${spaceId}?startDate=${params.startDate}&endDate=${params.endDate}`)
      .then((res) => {
        dispatch({
          type: actions.FETCH_CALENDER_VISITS_DATA_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.FETCH_CALENDER_VISITS_DATA_FAILS,
          payload: error,
        });
      });
  };
};

export const createReservation = (payload, headers) => {
  return (dispatch, getState, { axiosInstance }) => {
    dispatch({
      type: actions.CREATE_RESERVATION,
    });
    axiosInstance
      .post(`booking/reservation`, payload, { headers })
      .then((res) => {
        dispatch({
          type: actions.CREATE_RESERVATION_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.CREATE_RESERVATION_FAILED,
          payload: error,
        });
      });
  };
};

export const updateReservation = (payload, headers) => {
  return (dispatch, getState, { axiosInstance }) => {
    dispatch({
      type: actions.UPDATE_RESERVATION,
    });
    axiosInstance
      .put(`booking/reservation`, payload, { headers })
      .then((res) => {
        dispatch({
          type: actions.UPDATE_RESERVATION_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.UPDATE_RESERVATION_FAILED,
          payload: error,
        });
      });
  };
};

export const getSpaceAvailability = (params, chargingType) => {
  return (dispatch, getState, { axiosInstance }) => {
    dispatch({
      type: actions.FETCH_SPACE_AVAILABILITY,
    });
    let url = `booking/availability?space=${params.spaceId}&eventTypeId=${params.eventTypeId}&startDate=${params.startDate}&endDate=${params.endDate}&chargingType=${chargingType}`;
    axiosInstance
      .get(url)
      .then((res) => {
        dispatch({
          type: actions.FETCH_SPACE_AVAILABILITY_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.FETCH_SPACE_AVAILABILITY_FAILS,
          payload: error,
        });
      });
  };
};

export const sendBookingCancellation = (data, headers) => {
  return (dispatch, getState, { axiosInstance }) => {
    dispatch({
      type: actions.CANCEL_SELECTED_BOOKING,
    });

    axiosInstance
      .delete(`booking/reservation`, { data, headers })
      .then((res) => {
        dispatch({
          type: actions.CANCEL_SELECTED_BOOKING_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.CANCEL_SELECTED_BOOKING_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchSelectedBooking = (id, headers) => {
  return (dispatch, getState, { axiosInstance }) => {
    dispatch({
      type: actions.FETCH_SELECTED_SPACE,
    });

    axiosInstance
      .get(`booking/${id}`, { headers })
      .then((res) => {
        dispatch({
          type: actions.FETCH_SELECTED_SPACE_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.FETCH_SELECTED_SPACE_FAIL,
          payload: error,
        });
      });
  };
};
export const updateVisitStatus = (payload, callBack, visitUpdateNotification) => {
  return (dispatch, getState, { axiosInstance }) => {
    dispatch({
      type: actions.UPDATE_VISIT_STATUS,
    });

    axiosInstance
      .put(`booking/schedule-a-visit`, payload)
      .then((res) => {
        callBack();
        dispatch({
          type: actions.UPDATE_VISIT_STATUS_SUCCESS,
          payload: res.data,
        });
        visitUpdateNotification(true);
      })
      .catch((error) => {
        dispatch({
          type: actions.UPDATE_VISIT_STATUS_FAIL,
          payload: error,
        });
        visitUpdateNotification(false);
      });
  };
};
