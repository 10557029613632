import React from 'react';
import { AVAILABILITY, BLOCK_OPTION, PACKAGES } from '../../../SpaceListingConstants';
import BlockPricingAndAvailabilityDetails from './BlockPricingAndAvailabilityDetails';
import { Redirect, Route, Switch } from 'react-router';
import PackageComponent from '../../../Common/Packages';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const BlockPricing = () => {
  const country = useSelector((state) => state?.locationMeta?.data?.country_longCode?.replace(" ", "-") || null);
  const baseRouteURL = `/${country}/dashboard/list-a-space/pricing_options/`+BLOCK_OPTION+'/';

  return (
    <>
      <Switch>
        <Route
          path={baseRouteURL + AVAILABILITY}
          component={() => {
            return <BlockPricingAndAvailabilityDetails pricingOption={BLOCK_OPTION}/>;
          }}
        />
        <Route
          path={baseRouteURL + PACKAGES}
          component={() => {
            return <PackageComponent pricingOption={BLOCK_OPTION}/>;
          }}
        />
        <Route
          path={'*'}
          component={() => <Redirect to={baseRouteURL + AVAILABILITY}/>}
        />
      </Switch>
    </>
  );
}

export default BlockPricing;
