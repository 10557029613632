import React, { useCallback, useEffect, useState } from 'react';
import { TextField } from 'react-md';
import FlowHandlingButtons from '../Common/HandleNavigationAndSubmit';
import { getProfileData, setProfileData } from '../Common/DataController';
import { spaceListingData } from '../Common/ProfileListingDataModal';
import {
  DEFAULT_DATA_OBJ,
  ERROR_CHAR_LIMIT,
  ERROR_NO_NUMBERS_ALLOWED,
  ERROR_ONLY_NUMBERS,
  ERROR_SPECIAL_CHAR,
  ERROR_WHITE_SPACE,
} from '../SpaceListingConstants';
import informationIcon from '../../../assets/images/space-listing/informationIcon.svg';
import { useSelector } from 'react-redux';
import decode from 'jwt-decode';
import { showErrorMessage } from '../Common/Common';
import { noSpecialCharIncluded } from '../Common/Validator';
import { useParams } from 'react-router-dom';

const Commission = ({ currentBlock }) => {
  const { country } = useParams();
  const [commissionDetails, setCommissionDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));
  const [isNextBtnActive, setIsNextBtnActive] = useState(false);
  const authDetails = useSelector((state) => state?.auth?.loginDetails?.data?.token || null);
  const [editDisabled, setEditDisabled] = useState(false);

  useEffect(() => {
    const usersDetails = authDetails ? decode(authDetails) : '';
    setEditDisabled(usersDetails?.['authorities']?.[0] !== 'role.admin');
  }, authDetails);

  useEffect(() => {
    setIsNextBtnActive(getFieldCurrentValue('percentage') > 0 && getFieldCurrentValue('percentage') < 100);
  }, [commissionDetails]);

  const handleCommissionDetails = useCallback(
    (name, newValue, max = 5000, noSpecialChar = false, noWhiteSpaces = false, onlyNumbers = false, noNumbersAllowed = false) => {
      if (newValue?.length > max) {
        showErrorMessage(ERROR_CHAR_LIMIT);
      } else if (noSpecialChar && !noSpecialCharIncluded(newValue.toString())) {
        showErrorMessage(ERROR_SPECIAL_CHAR);
      } else if (noWhiteSpaces && /\s/g.test(newValue)) {
        showErrorMessage(ERROR_WHITE_SPACE);
      } else if (onlyNumbers && !/^\d+$/.test(newValue) && newValue !== '') {
        showErrorMessage(ERROR_ONLY_NUMBERS);
      } else if (noNumbersAllowed && /[^a-zA-Z ]/g.test(newValue) && newValue !== '') {
        showErrorMessage(ERROR_NO_NUMBERS_ALLOWED);
      } else {
        const tempObj = Object.assign({}, commissionDetails, {
          ...commissionDetails,
          processStarted: true,
          [currentBlock]: commissionDetails?.[currentBlock].map((value, index) => {
            value[name] = newValue;
            return value;
          }),
        });
        setCommissionDetails(tempObj);
        setProfileData(tempObj);
      }
    },
    []
  );

  const getFieldCurrentValue = (type) => {
    return commissionDetails?.[currentBlock]?.[0]?.[type];
  };

  return (
    <>
      <div className="left-side-inner-wrapper commission-details">
        <h1 className="listing-page-title">Commission percentage details</h1>
        <div className="icon-sentense-wrapper">
          <div className="icon-sentense-inner-wrapper flex-start">
            <img className="icon" src={informationIcon} />
            <p className="green-text">
              MillionSpaces takes a 15% commission on the tax excluded amounts. This commission ensures you pay absolutely nothing to us unless we
              generate bookings. We use this revenue to cover all bank and credit card charges as well as all marketing expenses used to promote your
              property.
            </p>
          </div>
        </div>
        <div>
          <div className="common-max-width-sm">
            <TextField
              className="input-margin-wrapper"
              id="percentage"
              label="Commission Percentage (%)"
              lineDirection="center"
              required
              disabled={editDisabled}
              errorText="This field is required."
              onChange={(value) => handleCommissionDetails('percentage', value, 5000, true, true, true)}
              value={getFieldCurrentValue('percentage')}
            />
          </div>
        </div>
      </div>
      <FlowHandlingButtons isActive={isNextBtnActive} />
    </>
  );
};

export default Commission;
