import React, { useState, memo, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Pagination } from 'antd';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import SpaceLoader from '../Common/Loaders/SpaceLoader';
import personWhite from '../../assets/images/common/person-white.svg';
import locationWhite from '../../assets/images/common/location-white.svg';
import heart from '../../assets/images/common/heart.svg';
import building from '../../assets/images/common/building.svg';
import heartActive from '../../assets/images/common/heartActive.svg';
import {
  getAllSpaces,
  getSearchResultFilters,
  getSpacesData, refreshFeaturedSpaces,
  refreshSpacesData,
  setClearAllFilters
} from './ResultAction';
import { putToMyFavourites } from '../Common/Utils/UtilAction';
import { useSelector } from 'react-redux';
import { Grid, Cell } from 'react-md';
import { getvaluesfromURL } from '../../helper/Filter';
import { SearchURLGenerator } from '../../helper/Result';
import {setImageQuality, IMAGES_BASE_URL} from '../../settings';
import {commafy} from '../../helper/Commafy';
import ResultNearestSpaces from '../Result/Filter/Filter/SpacesFilter/ResultNearestSpaces';
import useWindowDimensions from '../../hooks/useWindowDimension';
import SpaceCard from './SpaceCard';
import { useCookies } from 'react-cookie';
import {UPDATE_MOUSE_OVER} from "../../redux/actionTypes";
import { ACTIONS, CATEGORIES, HOT_DESK_CAPITALIZED, WORK_HOTD } from '../Common/constants';
import { getChargingTypeForURL } from '../../helper/bookingHelper';
import { SRI_LANKA_SHORT_CODE } from '../SpaceListing/SpaceListingConstants';
import { FEATURED_DECK } from '../Home/Constants';
import ReactGA from "react-ga";

const FeaturedSpaces = memo(({ visibleMap=false }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['authDetails']);
  const dispatch = useDispatch();
  const shortCountryCode = sessionStorage.getItem("countryShortCode");
  const isFetchingSpaces = useSelector((state) => state?.searchResult?.fetchingSpaces || false);
  const paginationCount = useSelector(state => state?.searchResult?.spaces?.data?.totalPages || 0);
  const spacesData = useSelector(state => state?.searchResult?.spaces?.data || 0);
  const searchURLstring = useSelector((state) => state?.router?.location?.search || '');
  const activities = useSelector((state) => state?.activity?.data || []);
  const countryShortCode = useSelector((state) => state?.locationMeta?.data?.country_shortCode || shortCountryCode);
  const coordinates = useSelector((state) => state?.locationMeta?.coordinates || {});
  const pageCountInSession = sessionStorage.getItem("page");
  const [pageCount, setPageCount] = useState(pageCountInSession ? Number(pageCountInSession):1);
  const [isInitialLoad, setInitialLoad] = useState(true);
  const dimensions = useWindowDimensions();
  const { startDate, endDate, timePeriod, code, country } = getvaluesfromURL(searchURLstring);
  const featuredSpacesData = useSelector(state => state?.searchResult?.spaces?.data?.spaces || []);
  const configDetailsRaw = useSelector(state => state?.home?.homepage?.data?.config || []);
  const [sectionData, setSectionData] = useState([])

  useEffect(() => {
    if (isFetchingSpaces && isInitialLoad) {
      setInitialLoad(false);
    }
  }, [isFetchingSpaces]);

  useEffect(() => {
    dispatch(refreshFeaturedSpaces(country, code));
    window.scrollTo(0,0);
  }, []);

  useEffect(() => {
    sessionStorage.setItem("page",'1');
    setPageCount(pageCountInSession ? Number(pageCountInSession):1);
  }, [searchURLstring]);

  useEffect(()=>{
    if(spacesData?.totalPages < spacesData?.currentPage){
      fetchMoreListItems(1)
    }
  },[spacesData])


  const fetchMoreListItems = (page) => {
    const newPageCount = page;
    setCookie('page', newPageCount);
    sessionStorage.setItem('page', newPageCount);
    if ( coordinates?.lat && coordinates?.lon) {
      dispatch(getSpacesData(newPageCount, 12, searchURLstring, coordinates?.lat, coordinates?.lon));
    }
    setPageCount(newPageCount);
  };

  const clearAllOnClick = () => {
    dispatch(setClearAllFilters());
  };

  const getActivityType = (eventType) => {
    for(let x=0; x<activities?.length; x++){
      const activity =  activities[x]?.venueType?.filter(venueType => venueType?.code === eventType);
      if(activity.length > 0 ){
        return activity;
      }
    }
  }

  const truncate = string => typeof string == 'string' && string.length > 30 ? `${string.substring(0, 30)}...` : string;

  const handleSpaceClick = (id, rateUnit, eventTypeCode,space) => {
    const activityType = getActivityType(eventTypeCode)?.[0];
    let getStringValues = {
      spaceId: id,
      eventTypeId: activityType?.id
    };

    getStringValues = {
      spaceId: id,
      eventTypeId: activityType?.id,
      urlStartDate: startDate,
      urlEndDate: endDate ? endDate : startDate
    };

    getStringValues = timePeriod ?  Object.assign(getStringValues, { unit: getChargingTypeForURL(timePeriod) }) : getStringValues;
    
    ReactGA.event({
      category: CATEGORIES.FEATURED_SPACES,
      action: ACTIONS.VIEW_SPACE_FROM_FEATURED,
      label: `Featured spaces (${countryShortCode})`
    });
    window.open(`/${country === SRI_LANKA_SHORT_CODE ? "Sri-Lanka":"Singapore"}/space/bookings?${SearchURLGenerator(getStringValues)}&unit=${rateUnit}`);
  };

  const updateDateFlowAndColor = (dataFlow, colorChange) => {
    if (visibleMap) {
      dispatch({
        type: UPDATE_MOUSE_OVER,
        payload: {
          infoWondow: dataFlow,
          colorChange: colorChange,
        },
      });
    }
  };

  useEffect(()=>{
    if(configDetailsRaw?.sections) {
      setSectionData(configDetailsRaw?.sections?.filter(value => value?.type === FEATURED_DECK)?.[0]?.customConfig);
    }
  },[configDetailsRaw?.sections])

  return (
    <section name="comp" className={`search-result-section ${visibleMap ? 'map-active' : ''}`}>
      <div className="search-result-wrapper featured-space-section">
        <div className="container custom-container space-result-container">
          <h1 className="title">{spacesData?.title}</h1>
          <p className="sub-title">{spacesData?.description}</p>
          {/* displaying no result  */}
          {featuredSpacesData.length == 0 && !isFetchingSpaces && !isInitialLoad ? (
            <div className="no-more-wrap">
              <img alt="img" className="img" src={building} />
              <p className="title">No Results Found</p>
              <p className="sub-title">
                Sorry! We can't seem to find any spaces that match your search. <br />
                Please try reducing your search criteria.
              </p>
              <button className="btn btn-outline" onClick={() => clearAllOnClick()}>
                Clear all filters
              </button>
              <button className="btn btn-outline" onClick={() => dispatch(push('/help'))}>
                Contact us
              </button>
            </div>
          ) : (
            <div className="space-wrap">
              {(dimensions.width > 992 || !visibleMap) &&
              <div className="columns space-result-wrap">
                {isFetchingSpaces ? (<SpaceLoader />):(
                  <>
                    <Grid className="result-grid">
                      {featuredSpacesData &&
                      featuredSpacesData.map((space, index) => (
                        <Cell size={visibleMap?4:3} phoneSize={12} tabletSize={4} className="result-cell">
                          <div key={index} className="search-result-column">
                            <div className="wrap"
                                 onClick={() => handleSpaceClick(space?.id, space?.rateUnit, space?.eventTypeCode,space)}
                            >
                              <SpaceCard
                                visibleMap={visibleMap}
                                setImageQuality={setImageQuality}
                                space={space}
                                IMAGES_BASE_URL={IMAGES_BASE_URL}
                                personWhite={personWhite}
                                locationWhite={locationWhite}
                              />
                              <div className="thumbnail-content">
                               <span className="content-title">
                                 {truncate(space?.tags
                                   ?.map((tag, index) => `${tag?.toString()}${space?.tags.length - 1 != index ? ' • ' : ''}`)
                                   .join(''))}
                                 {space?.category} {space?.address}
                                </span>

                                <span className="content-sub-title content-title">{space?.name}</span>
                                <div className="price-wrap">
                                  <span className="price">
                                    {`${space?.rateCurrency}
                                 ${commafy(Math.round(space?.rate * 100)/100)}`}
                                  </span>

                                  <span className="price-description">{`/${space?.eventType === HOT_DESK_CAPITALIZED ? 'seat/' : ''}${space?.rateUnit}`}</span>
                                  {space?.dealRate ? (
                                    <span className="deal">
                                     {space?.dealRateCurrency}
                                      {space?.dealRate}/{space?.dealPer}
                                    </span>                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                        </Cell>
                      ))}
                    </Grid>
                    {paginationCount === 0 ?  (<> </>) : (<Pagination current={pageCount} total={paginationCount * 10} onChange={fetchMoreListItems}/>)}
                  </>
                )
                }
              </div>
              }
              {visibleMap && <ResultNearestSpaces pageCount={pageCount}/> }
            </div>
          )}
        </div>
      </div>
    </section>
  );
});

export default FeaturedSpaces;
