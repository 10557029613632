import React, { useCallback, useState } from 'react';
import FlowHandlingButtons from '../Common/HandleNavigationAndSubmit';
import downloadIcon from "../../../assets/images/space-listing/download.svg";
import { Checkbox } from 'antd';
import { getProfileData, setProfileData } from '../Common/DataController';
import { DEFAULT_DATA_OBJ } from '../SpaceListingConstants';
import { spaceListingData } from '../Common/ProfileListingDataModal';
import { useParams } from 'react-router-dom';

const Agreement = ({currentBlock}) => {
  const { country } = useParams();
  const [agreementDetails, setAgreementDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));

  const handleAgreementConfirmation = useCallback((name, newValue) => {
    const tempObj = Object.assign({}, agreementDetails, {
      ...agreementDetails,
      processStarted: true,
      [currentBlock]: agreementDetails?.[currentBlock].map(value => {
        value[name] = newValue;
        return value;
      }),
    });
    setAgreementDetails(tempObj);
    setProfileData(tempObj);
  }, []);

  const getFieldCurrentValue = (type) => {
    return agreementDetails?.[currentBlock]?.[0]?.[type];
  };

  return (
    <>
      <div className="left-side-inner-wrapper host-agreement">
        <div className="title-btn-wrapper">
          <h1 className="listing-page-title">Host agreement</h1>
          <div className="download-btn">
            <img className="icon" src={downloadIcon} alt={"Download Icon"}/>
            <div className="text">Download</div>
          </div>
        </div>

        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec purus tristique, ultricies nunc in, commodo velit. Sed rutrum sapien quis risus laoreet, id congue orci bibendum. Nullam vel tortor ut leo rhoncus ultricies. Nullam faucibus urna et lectus pellentesque facilisis. Aenean aliquam, nisl ut suscipit porta, odio ligula auctor libero, at congue velit sapien non urna. Nunc sagittis porta commodo. Praesent pulvinar malesuada elit, vitae ultricies ex scelerisque eget. Aliquam sit amet justo ipsum. Proin in mauris risus. Fusce condimentum neque nec purus consectetur, vitae molestie neque pulvinar. Vivamus iaculis odio eu turpis sagittis semper. Phasellus et ultrices quam. Nullam condimentum nisl vel dolor eleifend, eget bibendum purus vehicula. Duis iaculis sagittis convallis. Sed a blandit risus. Sed ornare consectetur venenatis. Pellentesque ac luctus magna. Sed in ex congue diam placerat volutpat. Duis efficitur sapien commodo enim porttitor eleifend. Sed suscipit commodo maximus.</p>
        <p>Sed consectetur nec orci eu pretium. Maecenas libero lectus, euismod condimentum ligula non, consequat commodo velit. Morbi imperdiet sem in magna placerat, quis feugiat metus euismod. Fusce eleifend, metus sit amet elementum bibendum, neque sem efficitur massa, non rhoncus nulla nunc eget turpis. Etiam porttitor tincidunt massa, quis laoreet massa facilisis ac. </p>
        <p>Nullam elementum, dolor sed venenatis gravida, lorem sapien aliquam mauris, quis mattis sapien neque vel diam. In in posuere neque, ut venenatis elit. Pellentesque eget sodales velit. Aliquam erat volutpat. Quisque nisl nulla, egestas sit amet hendrerit quis, accumsan ut urna. Nullam sed euismod nunc. Donec tristique nunc mi, eget tempor massa hendrerit ut. Nulla porta, ligula in gravida fermentum, velit eros posuere felis, ac dapibus mauris augue vitae mauris. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nam arcu tellus, pellentesque vel risus sit amet, laoreet finibus arcu. Vivamus eget dui ullamcorper, pretium turpis at, placerat purus.</p>

        <Checkbox className="check-box" value={getFieldCurrentValue('status')} defaultChecked={getFieldCurrentValue('status')} onChange={(value)=> handleAgreementConfirmation('status', value?.target?.checked)}>I have read and accept the host agreement</Checkbox>
      </div>
      <FlowHandlingButtons  nextButtonText={"Submit"} isSubmit={true} isActive={getFieldCurrentValue('status')}/>
    </>
  );
};

export default Agreement;
