import axiosInstance from './api/axiosInstance';
import { client_id, client_secret, REDIRECT_URL } from './settings';

export const get_tokens =(code) =>
    axiosInstance
        .post("https://oauth2.googleapis.com/token",{
            
            client_id:client_id,
            client_secret:client_secret,
            code,
            grant_type:"authorization_code",
            redirect_uri:REDIRECT_URL
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            throw error;
});

export const getAuthorizedEmail=async (accessToken)=>{
   try {
        const res = await axiosInstance
            .get(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${accessToken}`);
        return res;
    } catch (e) {
        throw e;
    }
        
}


