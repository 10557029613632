import React, { useCallback, useEffect, useState } from "react";
import FlowHandlingButtons from "../Common/HandleNavigationAndSubmit";
import { BACK_TO_HOME, LETS_START } from '../HostOnboardingConstants';
import welcomeImg from '../../../assets/images/space-listing/payment-img.svg';

const WelcomePage = () => {

  return (
    <>
    <div className="left-side-inner-wrapper welcome-screen">
      <h1 className="listing-page-title " >Thank you for joining MillionSpaces and let’s change the World of Work together!</h1>
      <div className="paragraph-wrapper">
        <p>MillionSpaces will automatically transfer the payment for your bookings onto your bank account.</p>
        <p>Kindly take a few minutes to fill in the required information and do not hesitate to contact us should you have any questions.</p>
        <p>Thank you,<br/>The MillionSpaces team.</p>
      </div>
      <div className="img-wrapper">
          <img className="img" src={welcomeImg} alt="welcome" />
      </div>
    </div>
    <FlowHandlingButtons backButtonText={BACK_TO_HOME} nextButtonText={LETS_START}/>
    </>
  );
};

export default WelcomePage;
