import React, { PureComponent, useState } from 'react';
import mobileBlueoutline from '../../assets/images/coming-soon/mobileBlueoutline.svg'
import comingSoonImg from '../../assets/images/coming-soon/comingSoonImg.svg'
import webBlueOutlineSmall from '../../assets/images/coming-soon/webBlueOutline-sm.svg'
import webBlueOutline from '../../assets/images/coming-soon/webBlueOutline.svg'
import { Modal} from 'antd';
import {IMAGES_BASE_URL} from "../../settings";
import useWindowDimensions from "../../hooks/useWindowDimension";

const MobileComingSoon = ({handleComingSoonModalClose}) => (
    <>
        <div className="wrapper mobile">
            <img src={mobileBlueoutline} alt="img" className="blueoutline-mobile mobile" />
            <img src={comingSoonImg} alt="img" className="coming-soon-img mobile" />
            <div><p className="coming-soon-text">Coming soon!</p></div>
            <div className="content">
                <p className="text">Thank you for taking the time to come to this page but it's not quite ready yet and will be live very soon.</p>
                <p className="text">We're working on bringing you the best platform to manage all you may need when it comes to remote work. If you'd like reach out to us with ideas,
                    comments or ways to be featured, don't hesitate to contact us on <a className="link">hello@millionspaces.com</a></p>
                <p className="text">Thank you,<br />The MillionSpaces team</p>
            </div>
            <div className="btn-close-wrap">
                <button className="btn btn-yellow" onClick={handleComingSoonModalClose}>CLOSE</button>
            </div>
        </div>
    </>
);


const DesktopComingSoon = ({handleComingSoonModalClose}) => (
    <>
        <div className="wrapper web">
            <img src={webBlueOutlineSmall} alt="img" className="sm-blue-outline web" />
            <img src={webBlueOutline} alt="img" className="blue-outline-web web" />
            <img src={comingSoonImg} alt="img" className="coming-soon-img web" />
            <div><p className="coming-soon-text">Coming soon!</p></div>
            <div className="content">
                <p className="text">Thank you for taking the time to come to this page but it's not quite ready yet and will be live very soon.</p>
                <p className="text">We're working on bringing you the best platform to manage all you may need when it comes to remote work. If you'd like reach out to us with ideas,
                    comments or ways to be featured, don't hesitate to contact us on <a href={"mailto:hello@millionspaces.com"} className="link">hello@millionspaces.com</a></p>
                <p className="text">Thank you,<br />The MillionSpaces team</p>
            </div>
            <div className="btn-close-wrap">
                <button className="btn btn-yellow" onClick={handleComingSoonModalClose}>CLOSE</button>
            </div>
        </div>
    </>
);


const ProgressMessage = ({ handleComingSoonModalClose }) => {

    return (
        <>
            <Modal title="Basic Modal" visible={true} className="coming-soon-dialog" footer={false} >
                {useWindowDimensions()?.width < 992 ? <MobileComingSoon handleComingSoonModalClose={handleComingSoonModalClose}/> : <DesktopComingSoon handleComingSoonModalClose={handleComingSoonModalClose}/>}
            </Modal>
        </>
    )
}

export default ProgressMessage;
