import axios from 'axios';
import PropTypes from 'prop-types';
import { BASE_URL, client_id, client_secret, GOOGEL_PLACE_ID_API, MS_URL, } from '../settings';
import axiosInstance from './axiosInstance';

export const get = (path,config) =>
  axiosInstance
    .get(`${BASE_URL}${path}`,{headers:config})
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });

export const get_params = (path,param,config) =>
  axiosInstance
    .get(`${BASE_URL}${path}`,{
      params : {
        page : param.page,
        size : param.size,
        dataView : 'HOST_LIST_VIEW',
        ...(param.categoryId ? { categoryId : param.categoryId } : {}),
        ...(param.activityId ? { activityId : param.activityId } : {}),
        ...(param.name ? { name: param.name } : {}),
        ...(param.status ? { status: param.status } : {})
      },
      headers:config
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });

export const put = (path, payload,config) =>
  axiosInstance
    .put(`${BASE_URL}${path}`, payload,{headers:config})
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });

put.prototype = {
  path: PropTypes.string,
  payload: PropTypes.any
};

const putListingBaseUrl = BASE_URL.replace("api/","")
export const put_listing = (path, payload,config) => {
  return axiosInstance
    .put(`${putListingBaseUrl}${path}`, payload, { headers: config })
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });
}

export const post = (path, payload,headers) =>
  axiosInstance
    .post(`${BASE_URL}${path}`, payload,{headers:headers})
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });


export  const deleteRequest = (path, payload,config) =>
  axiosInstance.delete(`${BASE_URL}${path}`, { data: payload , headers: config })
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });

export const getLatLong = (path) =>
  axiosInstance
    .get(`${GOOGEL_PLACE_ID_API}${path}`)
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });

