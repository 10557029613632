import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table, Icon } from 'antd';
import { getPaymentRefundReportData } from '../ReportAction';
import {
  paymentRefundObj,
  searchOnFilter,
  searchTextOnFilter,
  handleNullValues,
  searchFilterForEmptyFields,
  setToolTip
} from '../../../helper/ReportingHelper';
import YearMonthFilter from '../common/YearMonthFilter';
import SearchText from '../common/SearchText';
import SearchFilters from '../common/SearchFilters';
import DateFilter from '../common/DateFilter';
import InfiniteTable from '../../Common/Table/InfiniteTable';
import { IMAGES_BASE_URL } from '../../../settings';
import withTracker from '../HOCs/withTracker';
import HeaderButton from '../common/HeaderButton';

const PaymentRefundReport = ({ handleDownload, status }) => {
  const [dataSource, setDataSource] = useState([]);
  const [tableData, setTableData] = useState({});

  const paymentRefundData = useSelector((state) => state?.report?.reports?.refund?.data);
  const authToken  = useSelector((state) => state?.auth?.loginDetails?.data?.token);

  useEffect(() => {
    const formattedData = paymentRefundObj(paymentRefundData);
    formattedData?.length && setDataSource(formattedData);
  }, [paymentRefundData]);

  const handleSearch = (setSelectedKeys, confirm, searchText) => {
    setSelectedKeys([searchText]);
    confirm();
  };

  const handleReset = (setSelectedKeys, clearFilters) => {
    setSelectedKeys([]);
    clearFilters();
  };

  const PaymentRefundHeader = [
    {
      key: 'bookingRef',
      title: 'Booking Ref.#',
      dataIndex: 'bookingRef',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '62px'}}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'bookingRef');
      },
    },
    {
      key: 'paymentRef',
      title: 'Payment Ref.#',
      dataIndex: 'paymentRef',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '66px'}}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'paymentRef');
      },
    },
    {
      key: 'refundRef',
      title: 'Refund Ref.#',
      dataIndex: 'refundRef',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '56px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'refundRef');
      },
    },
    {
      key: 'category',
      title: 'Category',
      dataIndex: 'category',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="category"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '67px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'category');
      },
    },
    {
      key: 'activity',
      title: 'Activity',
      dataIndex: 'activity',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="activity"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '59px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'activity');
      },
    },
    {
      key: 'guestName',
      title: 'Guest Name',
      dataIndex: 'guestName',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '47px'  }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'guestName');
      },
    },
    {
      key: 'guestEmail',
      title: 'Guest Email',
      dataIndex: 'guestEmail',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },

    {
      key: 'hostName',
      title: 'Host Name',
      dataIndex: 'hostName',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'hostEmail',
      title: 'Host Email',
      dataIndex: 'hostEmail',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'spaceId',
      title: 'Space ID',
      dataIndex: 'spaceId',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '65px'  }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'spaceId');
      },
    },
    {
      key: 'spaceName',
      title: 'Space Name',
      dataIndex: 'spaceName',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '48px'}}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'spaceName');
      },
    },
    {
      key: 'spaceCountry',
      title: 'Country',
      dataIndex: 'spaceCountry',
      className: 'custom-width',
      width: 113,
      render: text => {
        return text === 'LK'? 'SL' : handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="country"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '61px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'spaceCountry');
      },
    },
    {
      key: 'bookingDate',
      title: 'Booking Date',
      dataIndex: 'bookingDate',
      width: 113,
    },
    {
      key: 'paymentDate',
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      className: 'custom-width',
      width: 113,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <DateFilter
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
          onSearch={searchText =>
            handleSearch(setSelectedKeys, confirm, searchText)
          }
        />
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined, left: '65px' }} />
      ),
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'paymentDate');
      },
    },
    {
      key: 'eventStartDate',
      title: 'Event Start Date',
      dataIndex: 'eventStartDate',
      width: 113,
    },
    {
      key: 'eventEndDate',
      title: 'Event End Date',
      dataIndex: 'eventEndDate',
      width: 113,
    },
    {
      key: 'month',
      title: 'Month',
      dataIndex: 'month',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <YearMonthFilter
          filterType="month"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '50px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'month');
      },
    },
    {
      key: 'year',
      title: 'Year',
      dataIndex: 'year',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <YearMonthFilter
          filterType="year"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '38px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'year');
      },
    },
    {
      key: 'paymentAmount',
      title: 'Payment Amount',
      dataIndex: 'paymentAmount',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'transactionFee',
      title: 'Transaction Fee',
      dataIndex: 'transactionFee',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'receivedAmount',
      title: 'Received Amount',
      dataIndex: 'receivedAmount',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'refundDate',
      title: 'Refund Date',
      dataIndex: 'refundDate',
      className: 'custom-width',
      width: 113,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <DateFilter
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
          onSearch={searchText =>
            handleSearch(setSelectedKeys, confirm, searchText)
          }
        />
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined, left: '55px' }} />
      ),
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'refundDate');
      },
    },
    {
      key: 'refundAmount',
      title: 'Refund Amount',
      dataIndex: 'refundAmount',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'refundTransactionFee',
      title: 'Refund Transaction Fee',
      dataIndex: 'refundTransactionFee',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'totalRefundValue',
      title: 'Total Refund Value',
      dataIndex: 'totalRefundValue',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'refundReason',
      title: 'Refund Reason',
      dataIndex: 'refundReason',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'cardType',
      title: 'Card Type',
      dataIndex: 'cardType',
      className: 'custom-width',
      width: 150,
      render: text => {
        let cardName;
        switch (text) {
          case 'visa':
            cardName = 'VISA';
            return (
              <div>
                <img className="img" src={`${IMAGES_BASE_URL}image/upload/v1594788153/ms-singapore/Icons/visaCard.svg`} /> {cardName}
              </div>
            );
          case 'mastercard':
            cardName = 'MASTERCARD';
            return (
              <div>
                <img className="img" src={`${IMAGES_BASE_URL}image/upload/v1594788153/ms-singapore/Icons/masterCard.svg`} /> {cardName}
              </div>
            );
          case 'amex':
            cardName = 'AMEX';
            return (
              <div>
                <img className="img" src={`${IMAGES_BASE_URL}image/upload/v1594788153/ms-singapore/Icons/amexCard.svg`} /> {cardName}
              </div>
            );
          default:
            return handleNullValues(text);
        }
        
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="cardType"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '80px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchFilterForEmptyFields(value, record, 'cardType');
      },
    },
    {
      key: 'cardNumber',
      title: 'Card Number',
      dataIndex: 'cardNumber',
      width: 150,
      render: text => {
        return text ? `•••• •••• •••• ${text}` : handleNullValues(text);
      },
    },
    {
      key: 'cardExpDate',
      title: 'Card Exp Date',
      dataIndex: 'cardExpDate',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
  ];

  const downloadCSV = () => {
    handleDownload({ ...tableData, reportType: 'payment-refund', totalDataLength: dataSource?.length, token: authToken })
  }

  return (
    <div>
     <HeaderButton handleDownload={downloadCSV} status={status}/>

      <InfiniteTable
        columns={PaymentRefundHeader}
        dataSource={dataSource}
        tableDispatch={getPaymentRefundReportData}
        props={{increaseValue: 10, token: authToken}}
        tableData={setTableData}
        scroll={{ y: 330, x: 500 }}
      />
    </div>
  );
};

export default withTracker('PAYMENT_REFUND_TRACKER') (PaymentRefundReport);
