import React, { useCallback, useEffect, useState } from 'react';
import { TextField } from 'react-md';
import Select from 'antd/es/select';
import FlowHandlingButtons from '../../../Common/HandleNavigationAndSubmit';
import {
  DAILY_OPTION,
  DEFAULT_DATA_OBJ, ERROR_ONLY_NUMBERS,
  MONTHLY_OPTION,
  PRICING_OPTIONS, TAX_INFORMATION,
  WEEKLY_OPTION
} from '../../../SpaceListingConstants';
import { getProfileData, setProfileData } from '../../../Common/DataController';
import { spaceListingData } from '../../../Common/ProfileListingDataModal';
import { useParams } from 'react-router-dom';
import { showErrorMessage } from '../../../Common/Common';
import { contentIsNotNull } from '../../../Common/Validator';
import { commafy } from '../../../../../helper/Commafy';
import monthlyPricingIcon from '../../../../../assets/images/space-listing/monthlyPricing.svg';

const { Option } = Select;
const MonthlyPricingDetails = ({chargingType}) => {
  const { country } = useParams();
  const [monthlyDetails, setMonthlyDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));


  const [isNextBtnActive, setIsNextBtnActive] = useState(false);

  useEffect(() => {
    setIsNextBtnActive(
      (Number(getBlockValue()?.price) > 0 && (getBlockValue()?.refundableDepositAvailable ? getBlockValue()?.refundableDepositDuration > 0 : true))
    );
  }, [monthlyDetails]);

  const handleDataInput = (type, newValue, onlyNumbers = false) => {
    const maximumMonths = 12;
    if(type === 'refundableDepositDuration' && !(newValue <= maximumMonths)) {
      return;
    }
    if(onlyNumbers){
      newValue = newValue.replace(/^0+/, '');
    }
    if (onlyNumbers && !(/^[0-9.]{0,60}$/i).test(newValue) && newValue !== '') {
      showErrorMessage(ERROR_ONLY_NUMBERS);
    } else {
      const tempObj = Object.assign({}, monthlyDetails, {
        ...monthlyDetails,
        pricing_options: monthlyDetails?.['pricing_options'].map((value) => {
          value[MONTHLY_OPTION]['pricingDetails'][type] = newValue;
          return value;
        }),
      });
      setProfileData(tempObj)
      setMonthlyDetails(tempObj);
    }
  };


  const getBlockValue = () => {
    return monthlyDetails?.[PRICING_OPTIONS]?.[0]?.[MONTHLY_OPTION]?.['pricingDetails'];
  };

  return (
    <>
      <div className="left-side-inner-wrapper">
        <div className="price-type"><img className="img" src={monthlyPricingIcon} alt="icon" />Monthly pricing</div>
        <h1 className="listing-page-title">Monthly pricing details</h1>
        <div>
          <TextField
            value={getBlockValue()?.price}
            type={"number"}
            className="input-margin-wrapper"
            id="price"
            label={chargingType === "WORK_PRIV" ? `Price (${getProfileData()?.tax_information?.[0]?.currency})` : `Price per seat (${getProfileData()?.tax_information?.[0]?.currency})` }
            lineDirection="center"
            // required
            errorText="This field is required."
            onChange={(price) => handleDataInput('price', price, true)}
          />
          <hr className="divider"/>
          <p className="monthly-text">MillionSpaces will not handle any refundable security deposit.<br/> The guest will be required to pay any security deposits directly to you.</p>

          <Select 
           className={`input-margin-wrapper pricing-options ${(getBlockValue()?.refundableDepositAvailable)?.toString()?.length>0 ? 'placeHolderTop' : ''}`}
          // dropdownClassName="space-listing-drop-down" 
          placeholder={"Refundable security deposit"} 
          onSelect={(value) => handleDataInput('refundableDepositAvailable', value)} 
          value={getBlockValue()?.refundableDepositAvailable}>
            <Option disabled={true} value={true} className="list-text">Do your require a refundable security deposit for the use of this space ?</Option>
            <Option value={true}>Yes</Option>
            <Option value={false}>No</Option>
          </Select>

          {getBlockValue()?.refundableDepositAvailable && (<div>
            <TextField
              min={1}
              max={12}
              value={getBlockValue()?.refundableDepositDuration}
              type={"number"}
              
              className="input-margin-wrapper"
              id="refundableDepositDuration"
              label="Applicable Term (Months)"
              lineDirection="center"
              // required
              errorText="This field is required."
              onChange={(price) => handleDataInput('refundableDepositDuration', price, true)}
            />
            <p className="monthly-text-wrap">Refundable security deposit <span className="deposit-amount">{monthlyDetails?.[TAX_INFORMATION]?.[0]?.currency} {commafy(getBlockValue()?.price * (getBlockValue()?.refundableDepositDuration || 0))}</span></p>
          </div>)}


        </div>
      </div>

      <FlowHandlingButtons isActive={isNextBtnActive}/>
    </>
  );
};

export default MonthlyPricingDetails;
