import React, { useCallback, useEffect, useState } from 'react';
import { TextField } from 'react-md';
import FlowHandlingButtons from '../Common/HandleNavigationAndSubmit';
import Select from 'antd/es/select';
import { getTaxDetails } from '../ListingAction';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileData, setProfileData } from '../Common/DataController';
import { spaceListingData } from '../Common/ProfileListingDataModal';
import {
  DEFAULT_DATA_OBJ,
  ERROR_CHAR_LIMIT,
  ERROR_SPECIAL_CHAR, ERROR_WHITE_SPACE,
  SINGAPORE_CURRENCY,
  SRI_LANKA_CURRENCY
} from '../SpaceListingConstants';
import { contentIsNotNull, noSpecialCharIncluded } from '../Common/Validator';
import { showErrorMessage } from '../Common/Common';
import { useParams } from 'react-router-dom';

const TaxInformation = ({ currentBlock }) => {
  const { country } = useParams();
  const dispatch = useDispatch();
  const { Option } = Select;
  const [taxDetails, setTaxDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));
  const slTax = useSelector(state => state?.spaceListing?.slTax || []);
  const sgTax = useSelector(state => state?.spaceListing?.sgTax || []);
  const [isNextBtnActive, setIsNextBtnActive] = useState(false);
  const countryShortCode = useSelector(state => state?.locationMeta?.data?.country_shortCode || "SG");

  useEffect(() => {
    setIsNextBtnActive(
      !getFieldCurrentValue('status') ||
      ((contentIsNotNull(getFieldCurrentValue("currency"), 0)) &&
      (contentIsNotNull(getFieldCurrentValue("registrationNumber").toString(), 0)) &&
      (contentIsNotNull(getFieldCurrentValue("applicableTaxes"), 0)))
    );
  }, [taxDetails]);

  useEffect(()=>{
    if(slTax.length > 0){
      handleTaxDetails(SRI_LANKA_CURRENCY, slTax);
    }
    if(sgTax.length > 0){
      handleTaxDetails(SINGAPORE_CURRENCY, sgTax);
    }
  },[slTax,sgTax])

  const handleTaxDetails = useCallback((name, newValue, max=5000, noSpecialChar=false, noWhiteSpaces=false) => {
    if (newValue?.length > max) {
      showErrorMessage(ERROR_CHAR_LIMIT)
    } else if (noSpecialChar && !/^[a-zA-Z0-9- ]{0,50}$/i.test(newValue.toString())) {
      showErrorMessage(ERROR_SPECIAL_CHAR)
    } else if (noWhiteSpaces && /\s/g.test(newValue)) {
      showErrorMessage(ERROR_WHITE_SPACE)
    } else {
      const tempObj = Object.assign({}, taxDetails, {
        ...taxDetails,
        processStarted: true,
        [currentBlock]: taxDetails?.[currentBlock].map((value) => {
          value[name] = newValue;
          return value;
        }),
      });
      setTaxDetails(tempObj);
      setProfileData(tempObj);
    }
  }, []);

  const getFieldCurrentValue = (type) => {
    return taxDetails?.[currentBlock]?.[0]?.[type];
  };

  return (
    <>
      <div className="left-side-inner-wrapper tax-details">
        <h1 className="listing-page-title">Tax information and details</h1>
        <p>Is your space required to pay taxes?</p>
        <div className="btn-wrapper">
          {countryShortCode === 'AUS' ? 
            <>
              <button className={`btn border-btn`} disabled>Yes</button>
              <button className={`btn border-btn ${!getFieldCurrentValue('status') ? 'active':''}`} onClick={() => handleTaxDetails('status', false)}>No</button>
            </>
            :
            <>
              <button className={`btn border-btn ${getFieldCurrentValue('status') ? 'active':''}`} onClick={() => handleTaxDetails('status', true)}>Yes</button>
              <button className={`btn border-btn ${!getFieldCurrentValue('status') ? 'active':''}`} onClick={() => handleTaxDetails('status', false)}>No</button> 
            </>
          }

        </div>

        {getFieldCurrentValue('status') && (<>
          <hr className="divider"/>
          <div className="common-max-width-sm">
            <Select
              className={`input-margin-wrapper ${getFieldCurrentValue('currency')?.length > 0 ? 'placeHolderTop' : ''}`}
              value={getFieldCurrentValue('currency')}
              dropdownClassName="space-listing-drop-down "
              placeholder= "Currency"
              disabled={true}
              onChange={(value) => {
                handleTaxDetails("currency", value);
                handleTaxDetails('applicableTaxes',[]);
                dispatch(getTaxDetails(value));
              }}>
              <Option value={SRI_LANKA_CURRENCY}>Sri Lanka (LKR)</Option>
              <Option value={SINGAPORE_CURRENCY}>Singapore (SGD)</Option>
            </Select>
            <TextField
              className={`input-margin-wrapper`}
              id="registrationNumber"
              label="Registration Number"
              lineDirection="center"
              errorText="This field is required."
              onChange={(value) => handleTaxDetails("registrationNumber", value, 100, true)}
              value={getFieldCurrentValue('registrationNumber')}
            />

            <Select
              className={`input-margin-wrapper ${getFieldCurrentValue('applicableTaxes')?.length > 0 ? 'placeHolderTop' : ''}`}
              dropdownClassName="space-listing-drop-down "
              mode="multiple"
              allowClear
              showArrow="true"
              placeholder="Applicable Taxes"
              onChange={(value) => handleTaxDetails('applicableTaxes', value)}
              value={getFieldCurrentValue('applicableTaxes')}
            >
              {getFieldCurrentValue(getFieldCurrentValue("currency")).map((value) => <Option
                key={value?.["taxName"] || 'Error Occurred'}>{value?.["taxName"] || 'Error Occurred'} ({value?.["taxAmount"] + '%' || 'Error Occurred'})</Option>)}
            </Select>
          </div>

        </>)}
      </div>
      <FlowHandlingButtons isActive={isNextBtnActive}/>
    </>
  );
};

export default TaxInformation;
