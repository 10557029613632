import PropTypes from 'prop-types';
import { googleApi, countryRestrictions, googleMapAPIKey } from '../settings';

const axios = require('axios');

export const googleAutoCompleteApi = (location) => {
    const API_KEY = googleMapAPIKey;
    return axios.get(`${googleApi}?input=${location}&key=${API_KEY}&components=${countryRestrictions}`)
        .then((res) => {
            return res;
        }).catch(error =>
            error);
};

googleAutoCompleteApi.prototype = {
    location: PropTypes.string,
}