import React, { useEffect, useState } from "react";
import { getFieldCurrentValue, showErrorMessage } from './Common';
import { push } from "connected-react-router";
import { useHistory } from "react-router-dom";
import { getProfileData } from "./DataController";
import { DialogContainer, TextField } from "react-md";
import { useDispatch, useSelector } from "react-redux";
import { submitHostOnboard } from "../HostOnboardingAction";
import { CLEAR_DATA_SUBMIT_HOST } from "../HostOnboardingActionTypes";
import arrowIcon from "../../../assets/images/common/black-arrow.svg";
import { AGREEMENT, BACK_BTN, BACK_TO_HOME, COMMISSION, ORGANIZATION_DETAILS, PAYOUT_DETAILS } from "../HostOnboardingConstants";

const FlowHandlingButtons = ({ backBtnClass = "", nextBtnClass = "", isActive = true, frontButton = true, backButton = true, backButtonText = BACK_BTN, nextButtonText = "NEXT", isSubmit = false, errorMessage = "" }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSubmitted, setIsSubmitted] = useState(true);
  const [showConfirmationPopup, setConfirmationPopupState] = useState(false);
  const submitResponse = useSelector((state) => state?.hostOnBoarding?.submitResponse || null);
  const countryLongName = useSelector((state) => state?.locationMeta?.data?.country_longCode?.replace(" ", "-") || null);

  useEffect(() => {
    if (submitResponse) {
      if (isSubmitted && submitResponse?.status === 1) {
        dispatch({ type: CLEAR_DATA_SUBMIT_HOST, payload: "error" });
        dispatch(push(getProfileData()?.routeList?.[getProfileData()?.routeList?.length - 1]));
        setIsSubmitted(false);
      } else if (submitResponse?.response?.data?.errorCount) {
        showErrorMessage("Error occurred");
        dispatch({ type: CLEAR_DATA_SUBMIT_HOST, payload: "error" });
      }
    }
  }, [submitResponse]);

  const goToNext = () => {
    if (isSubmit) {
      setConfirmationPopupState(true);
    } else {
      const nextBlock = getProfileData()?.routeList.indexOf(window.location.pathname) + 1;
      if (nextBlock < 5) {
        dispatch({ type: CLEAR_DATA_SUBMIT_HOST, payload: "error" });
      }
      dispatch(push(getProfileData()?.routeList?.[nextBlock]));
    }
  };

  const submitData = () => {
    const payLoad = {
      company: {
        orgName: getFieldCurrentValue(ORGANIZATION_DETAILS, "orgName"),
        orgWebsite: getFieldCurrentValue(ORGANIZATION_DETAILS, "orgWebsite"),
        businessRegNo: getFieldCurrentValue(ORGANIZATION_DETAILS, "businessRegNo"),
        addressLine1: getFieldCurrentValue(ORGANIZATION_DETAILS, "addressLine1"),
        addressLine2: getFieldCurrentValue(ORGANIZATION_DETAILS, "addressLine2"),
        postalCode: getFieldCurrentValue(ORGANIZATION_DETAILS, "postalCode"),
        city: getFieldCurrentValue(ORGANIZATION_DETAILS, "city"),
        country: getFieldCurrentValue(ORGANIZATION_DETAILS, "country"),
        postalAddress: "",
      },
      contact: {
        firstName: getFieldCurrentValue(ORGANIZATION_DETAILS, "fistName"),
        lastName: getFieldCurrentValue(ORGANIZATION_DETAILS, "lastName"),
        designation: getFieldCurrentValue(ORGANIZATION_DETAILS, "designation"),
        email: getFieldCurrentValue(ORGANIZATION_DETAILS, "email"),
        countryCode: getFieldCurrentValue(ORGANIZATION_DETAILS, "dialCode"),
        phone: getFieldCurrentValue(ORGANIZATION_DETAILS, "phone"),
      },
      commission: getFieldCurrentValue(COMMISSION, "percentage"),
      bank: {
        accountName: getFieldCurrentValue(PAYOUT_DETAILS, "accountName"),
        bank: getFieldCurrentValue(PAYOUT_DETAILS, "bank"),
        branch: getFieldCurrentValue(PAYOUT_DETAILS, "branch"),
        accountNumber: getFieldCurrentValue(PAYOUT_DETAILS, "accountNumber"),
        branchCode: getFieldCurrentValue(PAYOUT_DETAILS, "branchCode"),
        swiftCode: getFieldCurrentValue(PAYOUT_DETAILS, "swiftCode"),
      },
      hostAgreed: getFieldCurrentValue(AGREEMENT, "status"),
    };
    dispatch(submitHostOnboard(payLoad));
  };

  const goToPreviousPath = () => {
    if (backButtonText === BACK_BTN) {
      const nextBlock = getProfileData()?.routeList.indexOf(window.location.pathname) - 1;
      dispatch(push(getProfileData()?.routeList?.[nextBlock]));
    } else if (backButtonText === BACK_TO_HOME) {
      history.push("/");
    }
  };

  return (
    <>
      <div className="floating-btn-wrapper">
        <div className="floating-btn-inner-wrapper">
          {backButton && (
            <button className={`btn back-btn ${backBtnClass}`} onClick={goToPreviousPath} id={"nextButton"}>
              <img className="back-icon" src={arrowIcon} alt="back" />
              {backButtonText}
            </button>
          )}
          {frontButton && (
            <button className={`btn next-btn ${nextBtnClass}`} style={isActive ? {} : { backgroundColor: "grey", color: "white" }} disabled={!isActive} id={"nextButton"} onClick={() => goToNext()}>
              {nextButtonText}
            </button>
          )}
        </div>
      </div>

      <DialogContainer
        className="common-popup add-on-popup"
        visible={showConfirmationPopup}
        id={"AddonId"}
        onHide={() => {
          setConfirmationPopupState(false);
        }}
      >
        <h2 className="main-title">Are you sure you want to submit?</h2>
        <div className="btn-wrapper">
          <button className="border-btn" onClick={() => { setConfirmationPopupState(false); }} >
            No
          </button>
          <button className="gray-btn active" onClick={submitData}>
            Yes
          </button>
        </div>
      </DialogContainer>
    </>
  );
};

export default FlowHandlingButtons;
