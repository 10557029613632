import React, { useEffect, useRef, useState } from 'react';
import { clearReportTrackerStatus, exportReport, fetchReportStatus } from '../ReportAction';
import { useDispatch, useSelector } from 'react-redux';

const withTracker = (trackerName) => (Component) => (props) => {
    const dispatch = useDispatch();
    const [isFetchReportStatus, setReportStatus] = useState(false)
    const increment = useRef(null);

    useEffect(() => {
        return () => {
            clearInterval(increment.current);
        }
    },[])

    const checkStatus = (id) => {
        increment.current = setInterval(() => {
            dispatch(fetchReportStatus(id));
        }, 5000);
    }
    
    const {status} = useSelector(state => state?.report?.reportStatus?.error);
    useEffect(()=> {
        if(status){
            clearInterval(increment.current);
            setReportStatus(false)
        }
    },[status])

    const reportsStatus = useSelector(state => state?.report?.reportStatus.data)

    const getCurrentReportStatus = () => {
        return reportsStatus.find(report => report.type === trackerName);
    };

    useEffect(() => {
        const report = getCurrentReportStatus();
        if(Boolean(reportsStatus.length)){
            if(report && report.taskStatus === 'COMPLETED'){
                clearInterval(increment.current);
                setReportStatus(false);
                dispatch(clearReportTrackerStatus(trackerName))
            };
        }
        if (Boolean(reportsStatus.length) && !status && !isFetchReportStatus) { 
            if (report && report.taskStatus === 'PENDING') {
                checkStatus(report.id);
                setReportStatus(true)
                
            } else {
                clearInterval(increment.current);
                setReportStatus(false)
            }
        }
    }, [reportsStatus]);

    const handleDownload = (params) => {
        dispatch(exportReport(params))
    };

    const propsLocal = {
        handleDownload: handleDownload,
        status: getCurrentReportStatus()?.taskStatus
    };

    return (
        <Component {...props} {...propsLocal} />
    )
};

export default withTracker