import React, { useCallback, useEffect, useState } from 'react';
import FlowHandlingButtons from '../Common/HandleNavigationAndSubmit';
import { TextField } from 'react-md';
import { getProfileData } from '../Common/DataController';
import { DEFAULT_DATA_OBJ } from '../HostOnboardingConstants';
import { contentIsNotNull } from '../Common/Validator';
import informationIcon from "../../../assets/images/host-onboarding/information-green-icon.svg";
import { getFieldCurrentValue, handleDataObject } from '../Common/Common';
import { hostOnBoardingData } from '../Common/HostOnBoardingDataModal';
import { useParams } from 'react-router-dom';

const PayoutDetails = ({ currentBlock }) => {
  const { country } = useParams();
  const [payoutDetails, setPayoutDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : hostOnBoardingData(country));
  const [isNextBtnActive, setIsNextBtnActive] = useState(true);

  useEffect(() => {
    setIsNextBtnActive(
      contentIsNotNull(getFieldCurrentValue(currentBlock,'accountName'), 2, 100) &&
      contentIsNotNull(getFieldCurrentValue(currentBlock,'bank'), 2, 100) &&
      contentIsNotNull(getFieldCurrentValue(currentBlock,'branch'), 2, 100) &&
      contentIsNotNull(getFieldCurrentValue(currentBlock,'accountNumber'), 2, 100) &&
      contentIsNotNull(getFieldCurrentValue(currentBlock,'branchCode'), 2, 100) &&
      contentIsNotNull(getFieldCurrentValue(currentBlock,'swiftCode'), 2, 100)
    );
  }, [payoutDetails]);

  return (
    <>
      <div className="left-side-inner-wrapper payout-details">
        <h1 className="listing-page-title">Payout details</h1>
        <div className="icon-sentense-wrapper">
          <div className="icon-sentense-inner-wrapper">
            <img className="icon" src={informationIcon} alt={"Icon"}/><p className="green-text">Your information will be safe on our secure servers.</p>
          </div>
        </div>
        <TextField
          className="input-margin-wrapper"
          id="accountName"
          label="Account name"
          lineDirection="center"
          errorText="Field is required."
          value={getFieldCurrentValue(currentBlock,'accountName')}
          onChange={value => handleDataObject('accountName', value, currentBlock, payoutDetails, setPayoutDetails, 50, true, false, false,true)}
        />
        <TextField
          className="input-margin-wrapper"
          id="bank"
          label="Bank"
          lineDirection="center"
          errorText="Field is required."
          value={getFieldCurrentValue(currentBlock,'bank')}
          onChange={value => handleDataObject('bank', value, currentBlock, payoutDetails, setPayoutDetails)}
        />
        <TextField
          className="input-margin-wrapper"
          id="branch"
          label="Branch"
          lineDirection="center"
          errorText="Field is required."
          value={getFieldCurrentValue(currentBlock,'branch')}
          onChange={value => handleDataObject('branch', value, currentBlock, payoutDetails, setPayoutDetails)}
        />
        <TextField
          className="input-margin-wrapper"
          id="accountNumber"
          label="Account Number"
          lineDirection="center"
          errorText="Field is required."
          value={getFieldCurrentValue(currentBlock,'accountNumber')}
          onChange={value => handleDataObject('accountNumber', value, currentBlock, payoutDetails, setPayoutDetails)}
        />
        <TextField
          className="input-margin-wrapper"
          id="branchCode"
          label="Branch Code"
          lineDirection="center"
          errorText="Field is required."
          value={getFieldCurrentValue(currentBlock,'branchCode')}
          onChange={value => handleDataObject('branchCode', value, currentBlock, payoutDetails, setPayoutDetails)}
        />
        <TextField
          className="input-margin-wrapper"
          id="swiftCode"
          label="Swift Code"
          lineDirection="center"
          errorText="Field is required."
          value={getFieldCurrentValue(currentBlock,'swiftCode')}
          onChange={value => handleDataObject('swiftCode', value, currentBlock, payoutDetails, setPayoutDetails)}
        />

      </div>
      <FlowHandlingButtons isActive={isNextBtnActive}/>
    </>
  );
};

export default PayoutDetails;
