import React from 'react';

export const sriLankanAgreement = () =><>
  <ol className="host-agreement-wrapper" type="1">
    <li><span className="main-title">DEFINITIONS AND INTERPRETATIONS</span>
      <ol>
        <li>The following terms used in this Agreement shall carry the definitions shown below:
          <ol type="a">
            <li className="sub-item"><b>“Commission”</b> shall have the meaning ascribed to it in clause 3.1;</li>
            <li className="sub-item"><b>“Confidential Information”</b> shall have the meaning ascribed to it in clause 16.1;</li>
            <li className="sub-item"><b>“Guest”</b> shall mean a person who has booked the Space of the Host via the Sites;</li>
            <li className="sub-item"><b>“Host”</b> shall mean a person (natural or legal) who offers a Space for booking on the Sites;</li>
            <li className="sub-item"><b>“Host Bank”</b> shall mean any bank which has been specified by the Host to receive its share from the payment made by a Guest for a Space;</li>
            <li className="sub-item"><b>“Host Data Capture Form”</b> shall mean the form by which the Host can provide information in order to list its Spaces with MillionSpaces;</li>
            <li className="sub-item"><b>“MillionSpaces”</b> shall mean the application service provider;</li>
            <li className="sub-item"><b>“Parties”</b> or <b>“Party”</b> shall mean MillionSpaces and the Host collectively or in their
              individual capacity as applicable;</li>
            <li className="sub-item"><b>"Representatives"</b> means collectively, in relation to a Party, (a) its directors, officers,
              employees and agent and (b) its affiliates and their respective directors, officers,
              employees and agents.</li>
            <li className="sub-item"><b>“Service(s)”</b> shall mean the listing, marketing and booking of Spaces displayed to the
              Guests via the Sites;</li>
            <li className="sub-item"><b>“Sites”</b> or <b>“Site”</b> shall mean the websites and/or mobile applications controlled or
              operated by MillionSpaces and its affiliates;</li>
            <li className="sub-item"><b>“Space”</b> shall mean any space owned or possessed by the Host and offered for
              booking by the Host to the Guests (whether packaged as a product, activity, service or
              other experience) via the Sites in accordance with the terms of this Agreement such
              as, without limitation, office spaces, meeting spaces, working seats and other similar
              facilities; and</li>
            <li className="sub-item"><b>“Space Content”</b> shall have the meaning ascribed to it in clause 6.1(a).</li>
          </ol>
        </li>
        <li>Words importing the singular shall also include the plural and vice-versa where the context requires.</li>
        <li>Words importing the masculine gender shall include the feminine and neuter genders and
          vice-versa where the context so requires.</li>
        <li>The titles used in this Agreement are for convenience of reference only and shall not be
          deemed to be part of the Agreement or in any way alter the interpretation or construction
          thereof.</li>
        <li>All references to clauses are clauses of this Agreement and each reference to a sub-clause
          is to the relevant sub-clause of the clause in which the reference appears.</li>
        <li>Reference to the provisions of any legislation includes a reference to any statutory
          modification and re-enactment thereof.</li>
      </ol>
    </li>
    <li><span className="main-title">SCOPE OF THE AGREEMENT</span>
      <ol>
        <li>This Agreement covers the agreed terms and conditions applicable to the Services.</li>
        <li>This Agreement shall be valid for a period of twelve (12) months commencing from the
          Effective Date and shall thereafter automatically be renewed for successive periods of one
          (1) year unless early terminated in accordance with clause 14.</li>
      </ol>
    </li>
    <li><span className="main-title">COMMISSION</span>
      <ol>
        <li>
          As consideration for the Services, MillionSpaces shall be entitled to charge the Host a
          commission of 20% of the total charge of each confirmed booking for a Space that is
          booked by a Guest through the Sites (the <b>“Commission”</b>).
          <br/>
          The Commission is calculated on the price displayed on the Sites for the consumed
          booking of a Space, excluding any and all applicable taxes (including, without limitation,
          excise, sales, use, value added, gross receipts, admission or similar equivalent taxes),
          incidentals, gratuities, food & beverage and other entertainment services not booked
          through the Sites, cancellation fees, no show fees, or other charges.
          <br/>
          When a booking occurs on a recurring basis(e.g. monthly payments), the Host shall pay
          MillionSpaces the Commission on each monthly charge.
        </li>
        <li>
          If, during a consumed booking of a Space, a Guest books one or more Spaces directly with
          the Host (<b>“Additional Spaces”</b>) or purchases an upgrade (<b>“Upgrade”</b>) then such Additional
          Spaces or Upgrades shall not be deemed part of the consumed booking of a Space and
          MillionSpaces shall not be entitled to any Commission or compensation in respect of such
          Additional Bookings or Upgrade.
        </li>
        <li>
          Notwithstanding the foregoing, MillionSpaces shall be entitled to the Commission in
          respect of the booking of Additional Spaces or Upgrades by a Guest, even if not performed
          through the Sites, in the form of a weekly/monthly/quarterly/annual subscription for a
          Space of any type with the Host or any member of the Host’s group. The Host undertakes to promptly inform MillionSpaces of such bookings and pay the Commission in connection
          thereto.
        </li>
        <li>
          In the event of a dispute between MillionSpaces and the Host and/or the Guest relating
          to a Commission payment, MillionSpaces reserves the right to contact the Host and/or
          the Guest for verification of final bills and request for copies of the final bills to verify the
          Commission chargeable by MillionSpaces. MillionSpaces agrees to utilize the Host’s then
          current commission inquiry processes in an effort to resolve such dispute. If, after utilizing
          the commission inquiry processes, MillionSpaces and the Host are still unable to resolve
          the dispute, the Host agrees to use commercially reasonable efforts to assist
          MillionSpaces in resolving the dispute.
        </li>
      </ol>
    </li>
    <li><span className="main-title">PAYMENT COLLECTION</span>
      <ol>
        <li>
          Bookings made via the Sites:
          <ol type={'a'}>
            <li className="sub-item">MillionSpaces will be responsible for collecting all payments (one time or recurring)
              from the Guest.</li>
            <li className="sub-item">MillionSpaces will retain the payment for as long as the Guest retains the ability to

              cancel the booking pursuant to the Sites’ policies. Once a booking becomes non-
              cancellable, the full amount of the price paid by the Guest for the booking of the Space

              less the Commission will be paid within 14 working days directly to the Host Bank. A
              statement would be sent to the Host with details of due settlement.</li>
            <li className="sub-item">In the event of a cancellation by a Guest prior to the non-cancellable period pursuant
              to the Sites’ policies:
              <ol type={"i"}>
                <li className="sub-sub-item">If there are any cancellation charges associated with a booking, MillionSpaces
                  will not retain any Commission or fees on the cancellation charge and will
                  transfer any and all cancellation charges in full to the Host within 14 working
                  days of such cancellation directly to the Host Bank.</li>
                <li className="sub-sub-item">
                  If the cancellation fee is less than the booking fee, MillionSpaces will be
                  responsible for refunding the Guest the booking fee minus all cancellation fees
                  paid to the Host.
                </li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
    </li>
    <li><span className="main-title">OBLIGATIONS OF MILLIONSPACES</span>
      <ol>
        <li>
          MillionSpaces shall:
          <ol type={'a'}>
            <li className="sub-item">upload the details of any Space to be offered for booking in accordance with the
              information submitted directly to the Sites by the Host, including display of the prices
              inclusive of taxes and fees consistent with practice in the country in which the Host is
              located;</li>
            <li className="sub-item">not take any responsibility for any errors in the information submitted by the Host,
              including bookings made on the prices displayed incorrectly by the Host, whether due
              to error, currency conversion, data entry error, miscalculation, content mapping, etc.;</li>
            <li className="sub-item">make the Spaces available for booking by Guests through the Sites. For the avoidance
              of doubt, the Sites shall include any website or mobile application through which a
              booking could be made for a Space if MillionSpaces would expect to receive a
              Commission for such booking;</li>
            <li className="sub-item">provide the Host with the following information for each booking: first and last name
              of Guest, Guest’s email address, the arrival and departure dates and times, the type
              of Space booked; and</li>
            <li className="sub-item">at all times use its reasonable endeavours to maintain the Sites in a fully operating
              and secure condition (free of computer viruses or other harmful mechanisms) and
              error free, except only for unavailability and errors which may result from (i)
              insufficient or inadequate customer bandwidth or technology, (ii) general Internet
              brown-outs, black-outs and slowdowns, (iii) bring-downs in the ordinary course that
              are necessary to maintain, update or refresh the servers, mobile applications or
              websites, (iv) any “hacking” or “denial of service” activity by a third party (save where
              such activity arises out of or in connection with MillionSpaces’ gross negligence in
              maintaining its operating systems and software), (v) acts or omissions of the Host and
              (vi) any other reason beyond MillionSpaces’ reasonable control.</li>
            <li className="sub-item">at all times use its reasonable endeavours to maintain the Sites in a fully operating
              and secure condition (free of computer viruses or other harmful mechanisms) and
              error free, except only for unavailability and errors which may result from (i)
              insufficient or inadequate customer bandwidth or technology, (ii) general Internet
              brown-outs, black-outs and slowdowns, (iii) bring-downs in the ordinary course that
              are necessary to maintain, update or refresh the servers, mobile applications or
              websites, (iv) any “hacking” or “denial of service” activity by a third party (save where
              such activity arises out of or in connection with MillionSpaces’ gross negligence in
              maintaining its operating systems and software), (v) acts or omissions of the Host and
              (vi) any other reason beyond MillionSpaces’ reasonable control.</li>
          </ol>
        </li>
        <li>MillionSpaces represents, warrants, covenants and agrees that it and its affiliates and the
          Sites will comply with all applicable laws, regulations and governmental or transnational
          requirements (e.g. EU and national competition and antitrust requirements, the US
          Foreign Corrupt Practices Act of 1977 (as amended) and the US International Money
          Laundering Abatement and Anti-Terrorist Financing Act), including those relating to
          MillionSpaces access to enable Guests to book Spaces under the terms of this Agreement.
          MillionSpaces represents that neither it, nor any beneficial owner of MillionSpaces, nor
          any of its affiliates/Sites, nor any beneficial owner of such affiliates/Sites, are incorporated
          in or resident in a country subject to economic or trade sanctions by the Office of Foreign
          Assets Control (“OFAC”), or are listed as a “Specially Designated National,” a “Specially
          Designated Global Terrorist,” a “Blocked Person,” or similar designation under the OFAC
          sanctions regime.</li>
      </ol>
    </li>
    <li><span className="main-title">OBLIGATIONS OF THE HOST</span>
      <ol>
        <li>The Host shall:
          <ol type={"a"}>
            <li className="sub-item">provide information and list its Spaces with MillionSpaces on the Sites by completing
              the online or offline information sheet in the format given in the Host Data Capture
              Form such that the content can be uploaded directly to the Sites (“Space Content”);</li>
            <li className="sub-item">be responsible for the accuracy and the updating of the Space Content and the prices
              for the Spaces;</li>
            <li className="sub-item">be responsible for maintaining the listed Spaces at a given standard as indicated in the
              photographs showing the Spaces which have been provided by the Host to
              MillionSpaces;</li>
            <li className="sub-item">ensure that it has valid title and/or access rights to the Spaces offered for booking on
              the Sites and shall be at all times responsible for the Spaces and accuracy of any
              information, including photographs, received or sent by the Host via utilization of the
              Sites;</li>
            <li className="sub-item">grant MillionSpaces for the duration of this Agreement, a limited, non-exclusive,
              royalty-free, non-transferable, worldwide license to use, reproduce and display all
              trademarks, service marks, logos and other brands of the Host that are used in or in
              respect of the Spaces on the Sites in accordance with this Agreement and in any
              promotional or other advertising materials designed or intended to drive traffic to the
              Sites or for any other purpose, whether in print media, radio, television, Internet,
              mobile, social media or otherwise, which shall be in accordance with the Host’s
              reasonable guidelines as may be notified to MillionSpaces;</li>
            <li className="sub-item">use the MillionSpaces’ calendar for the provision of correct availability for the booking
              of the Spaces to be shown on the Sites, including black-out dates, close-out dates and
              other inventory restrictions, and be responsible for updating the MillionSpaces’
              calendar with all offline bookings of Spaces received. The Host needs to honour a
              MillionSpaces booking in the event of overbooking;</li>
            <li className="sub-item">warrant, represent and ensure throughout the duration of this Agreement that the
              Spaces displayed on the Sites shall comply with all applicable laws, permits, licensing
              and regulations;</li>
            <li className="sub-item">prepare the Spaces booked by the Guests in a timely manner and provide the Spaces
              to the Guests in good condition and ensure that for each booking all amenities relating
              to a Space are in good working order;</li>
            <li className="sub-item">specify a responsible contact person for MillionSpaces to coordinate with, when and
              where necessary;</li>
            <li className="sub-item">shall not offer a Space to a Guest at a price and/or terms below what is offered in
              respect of the price offered on the Sites;</li>
            <li className="sub-item">provide all extras, amenities and special arrangements paid for by a Guest in
              accordance with the Guest’s booking; and</li>
            <li className="sub-item">not offer, facilitate or permit, and shall take all reasonable measures to prevent, illegal
              activities taking place in the Space such as, without limitation, sex trafficking in which
              a commercial sex act is induced by force, fraud or coercion, or in which the person
              induced to perform such act has not attained 18 years of age or the procurement of
              commercial sex acts.</li>
          </ol>
        </li>
        <li>The Host is not responsible for communication failures, errors, difficulties, or other
          malfunctions or lost, stolen, or misdirected transactions, transmissions, messages or
          entries on or in connection with the Sites not within the Host’s control. Except for
          information directly provided by the Host to MillionSpaces, the Host is not responsible for
          any incorrect information associated with any transaction or transmission on or to the
          Sites regardless of whether such incident is the result of user error, system error, or
          human error.</li>
        <li>The Host must follow these rules and must not help or induce others to break or
          circumvent these rules. The Host shall:
          <ol type={'a'}>
            <li className="sub-item">not scrape, hack, reverse engineer, compromise or impair the Sites;</li>
            <li className="sub-item">not use bots, crawlers, scrapers, or other automated means to access or collect data
              or other content from or otherwise interact with the Sites;</li>
            <li className="sub-item">not hack, avoid, remove, impair, or otherwise attempt to circumvent any security or
              technological measure used to protect the Sites or Space Content;</li>
            <li className="sub-item">not decipher, decompile, disassemble, or reverse engineer any of the software or
              hardware used to provide the Sites;</li>
            <li className="sub-item">not take any action that could damage or adversely affect the performance or proper
              functioning of the Sites;</li>
            <li className="sub-item">only use the Sites as authorized by this Agreement or another agreement or policies
              with MillionSpaces;</li>
            <li className="sub-item">only use a Guest’s personal information as necessary to facilitate a transaction using
              the Sites as authorized by this Agreement;</li>
            <li className="sub-item">not use the Sites, MillionSpaces’ messaging tools, or the Guests’ personal information
              to send commercial messages without the recipient’s express consent;</li>
            <li className="sub-item">not request, make, or accept a booking or any payment outside of the Sites to avoid
              paying fees, taxes or for any other reason;</li>
            <li className="sub-item">not require or encourage Guests to open an account, leave a review, complete a
              survey, or otherwise interact, with a third party website, application or service before,
              during or after a reservation, unless authorized by MillionSpaces;</li>
            <li className="sub-item">not engage in any practices that are intended to manipulate MillionSpaces’ search
              algorithm;</li>
            <li className="sub-item">not use, copy, display, mirror or frame the Sites, any Space Content, any
              MillionSpaces’ branding, or any page layout or design without MillionSpaces’ consent;</li>
            <li className="sub-item">not use the name, logo, branding, or trademarks of MillionSpaces or others without
              permission; and</li>
            <li className="sub-item">not use or register any domain name, social media handle, trade name, trademark,
              branding, logo, or other source identifier that may be confused with MillionSpaces’
              branding.</li>
          </ol>
        </li>
      </ol>
    </li>
    <li><span className="main-title">MUTUAL COVENANTS</span>
      <ol>
        <li>With the exception of material supplied by the Host in digital format, all other artwork,
          images, logos, animation, software and other material created by MillionSpaces for
          advertising and marketing purposes remain the property of MillionSpaces. Reproduction
          or use of the same on the Internet or any other media, without the express written
          permission of MillionSpaces, is strictly prohibited.</li>
        <li>The Parties hereby agree that at any time MillionSpaces may deny access to or refrain
          from displaying any material which in its opinion seeks to create mischief, has the
          tendency to mislead the public and/or contravenes any provisions of prevalent rules and
          regulations and at its discretion request the Host to remove the Space on the Sites for any
          reason whatsoever.</li>
        <li>Each Party shall be responsible for the discharge of their respective tax liabilities arising
          hereunder and the Host shall be entirely responsible for all and any tax liabilities arising
          from any sale via the Sites provided further that each Party shall hold the other free and
          clear of their respective liabilities.</li>
        <li>Bookings made through MillionSpaces are not eligible for points in any Host-sponsored
          guest or travel agent loyalty program or in any MillionSpaces or third party loyalty
          program, including but not limited to airline frequent flier programs. </li>
        <li>Except for the information provided pursuant to clause 5.1(d), the Parties acknowledge
          and agree that that neither Party wishes to receive personally identifiable information
          belonging to the other Party, its customers, or agents. To the extent the Parties wish to
          exchange personally identifiable information in the future, the Parties agree to execute a
          separate data processing addendum.</li>
      </ol>
    </li>
    <li><span className="main-title">MILLIONSPACES’ ROLE</span>
      <ol>
        <li>MillionSpaces offers a platform that enables Hosts and Guests to publish, offer, search
          for, and book Spaces and Host’s services. While MillionSpaces works hard to ensure Hosts
          and Guests have great experiences using the Sites, MillionSpaces does not and cannot
          control the conduct of the Guests and Hosts. </li>
        <li>The Host acknowledges that MillionSpaces has the right, but does not have any obligation,
          to monitor the use of the Sites and verify information provided by the Host and Guests.
          For example, MillionSpaces may review, disable access to, remove, or edit Space Content to: (i) operate, secure and improve the Sites (including for fraud prevention, risk
          assessment, investigation and customer support purposes); (ii) ensure the Host’s
          compliance with this Agreement; (iii) comply with applicable law or the order or
          requirement of a court, law enforcement or other administrative agency or governmental
          body; (iv) address Space Content that MillionSpaces determines is harmful or
          objectionable; (v) take actions set out in this Agreement; and (vi) maintain and enforce
          any quality or eligibility criteria, including by removing listings of Spaces that don’t meet
          quality and eligibility criteria. </li>
        <li>The Host acknowledges and agrees that MillionSpaces administers its Terms of Service
          and Privacy Policy and other policies at its sole discretion. The Host agrees to cooperate
          with and assist MillionSpaces in good faith, and to provide MillionSpaces with such
          information and take such actions as may be reasonably requested by MillionSpaces with
          respect to any investigation undertaken by MillionSpaces regarding the use or abuse of
          the Sites. MillionSpaces is not acting as an agent for the Host except for where
          MillionSpaces acts as a collection agent as provided in the Agreement.</li>
      </ol>
    </li>
    <li><span className="main-title">ASSUMPTION OF RISKS BY HOST</span>
      <ol>
        The Host acknowledges that hosting carries inherent risks and agreesthat it assumes the
        entire risk arising out of its access to and use of the Sites, offering the Spaces for booking
        on the Sites, or any interaction it has with the Guests whether in person or online. The
        Host agreesthat it has had the opportunity to investigate the Sites and any laws, rules,
        regulations, or obligations that may be applicable to itslistings of the Spaces and that it is
        not relying upon any statement of law made by MillionSpaces.
      </ol>
    </li>
    <li><span className="main-title">DISCLAIMER</span>
      <ol>
        <li>MillionSpaces does not endorse or warrant the existence, conduct, performance,
          safety, quality, legality or suitability of any Guest, Hosts, the Hosts’ service, Space, listing
          of a Space or third party and MillionSpaces does not warrant that verification, identity or
          background checks conducted on listings of Spaces, Spaces, Guests or Hosts (if any) will
          identify past misconduct or prevent future misconduct. Any references to a listing of a
          Space, Space, Guest or Host "verified" (or similar language) indicate only that the listing
          of a Space, Space, Guest, Host or MillionSpaces has completed a relevant verification or
          identification process and nothing else. </li>
        <li>MillionSpaces is not responsible for outages or disruptions of the Internet and
          telecommunications infrastructure which are beyond MillionSpaces’ control and can lead
          to interruptions in the availability of the Sites. MillionSpaces may, temporarily and under
          consideration of the Hosts’ and Guests’ legitimate interests (e.g. by providing prior
          notice), restrict the availability of the Sites or certain features thereof, if this is necessary
          in view of capacity limits, the security or integrity of MillionSpaces’ servers, or to carry out
          maintenance measures that ensure the proper or improved functioning of the Sites.</li>
      </ol>
    </li>
    <li><span className="main-title">LIMITATION OF LIABILITY</span>
      <ol>
        <li>The termination of this Agreement is without liability by either Party to the other Party
          except for any claims a Party has against any other Party for any matters occurring up to
          and through the date of termination, and claims based on rights and obligations that
          survive the termination, whether resulting from non-payment of fees or any other default
          under this Agreement or otherwise. </li>
        <li>Notwithstanding anything to the contrary, under no circumstances will either Party or
          any of their parents, subsidiaries or affiliated companies be liable for any indirect, special,
          punitive, exemplary, incidental or consequential damages, whether speculative or
          otherwise, or any loss of business opportunities, loss of production, loss of profit, loss of
          revenue, loss of contract, loss or damage to goodwill or reputation, loss of data, service
          interruption, computer damage or system failure or the cost of substitute products or
          services, or for any damages for personal or bodily injury or emotional distress, whether
          such damages are alleged as a result of a breach of contract, warranty, tort or otherwise.
          Excluding the indemnification obligations set forth herein, neither Party nor any of their
          parents, subsidiaries or affiliated companies will be liable to pay direct damages of more
          than US$10,000.</li>
      </ol>
    </li>
    <li><span className="main-title">INDEMNIFICATION</span>
      <ol>
        <li>MillionSpaces shall indemnify the Host from and against all claims, charges, losses,
          liabilities, damages, fines, penalties, settlement expenses and costs (including reasonable
          legal fees and costs) incurred or suffered by the Host in connection with any third party
          claims or investigations brought against the Host directly or indirectly arising out of or
          relating to (i) the activities, operations or services provided by MillionSpaces, including
          but not limited to, the Sites, (ii) the use of the content provided by the Host regarding the
          Space, or the logo, service marks, or trademarks of the Host in violation of this Agreement,
          or (iii) MillionSpaces’ actual or alleged breach of this Agreement SAVE AND EXCEPT where
          such claims, charges, losses, liabilities, damages, fines, penalties, settlement expenses and
          costs are caused by the Host’s gross negligence, willful misconduct, fraud or failure to
          comply with the provisions of this Agreement.</li>
        <li>The Host shall indemnify MillionSpaces from and against all claims, charges, losses,
          liabilities, damages, fines, penalties, settlement expenses and costs (including reasonable
          legal fees and costs) incurred or suffered by MillionSpaces in connection with any third
          party claims or investigations brought against MillionSpaces directly or indirectly arising
          out of or relating to (i) the Space(s) provided by the Host as sold on the Sites, (ii) the use
          of the content provided by the Host regarding the Space, or the logo, service marks, or
          trademarks of the Host on the Sites or (iii) the Host’s actual or alleged breach of this
          Agreement, SAVE AND EXCEPT where such claims, charges, losses, liabilities, damages,
          fines, penalties, settlement expenses and costs are caused by MillionSpaces’ gross
          negligence, willful misconduct, fraud or failure to comply with the provisions of this
          Agreement.</li>
      </ol>
    </li>
    <li><span className="main-title">FORCE MAJEURE</span>
      <ol>
        <li>If either Party is temporarily rendered unable, wholly or in part, by Force Majeure, to
          perform its duties or accept performance by the other Party under this Agreement, it is
          agreed that the affected Party shall give notice to the other Party with immediate effect,
          giving full particulars of such Force Majeure.</li>
        <li>The duties of such Party affected by such Force Majeure shall be suspended during the
          period of disability so caused, but for no longer period, such cause shall be removed with
          all reasonable dispatch to the extent practicable.</li>
        <li>The term “Force Majeure” as employed herein shall mean acts of God, acts of
          government or regulatory body, war, blockades, insurrections, riots, epidemics, civil
          disturbances, explosions, fire, floods, earthquakes, storms, lightning and any other causes
          similar to the kind herein enumerated which are beyond the control of any Party and
          which by the exercise of due care and diligence any Party is unable to overcome.</li>
      </ol>
    </li>

    <li><span className="main-title">TERMINATION AND DEACTIVATION</span>
      <ol>
        <li>Termination
          <ol>
            <li>Either Party by giving thirty (30) days' notice in writing to the other Party, without
              prejudice to any other rights herein, may terminate this Agreement at the occurrence of any
              of the following circumstances:
              <ol type={'a'}>
                <li className="sub-item">If any Party commits a breach of any of the provisions of this Agreement and such
                  breach is not remedied within the aforesaid thirty (30) days’ notification period to the
                  satisfaction of the other Party; or</li>
                <li className="sub-item">If the other Party becomes bankrupt, insolvent or goes into voluntary or compulsory
                  liquidation or passes an effective resolution for winding-up or makes an arrangement
                  or composition with its creditors, or if any receiver has been appointed on behalf of
                  debenture holders or otherwise.</li>
              </ol>
            </li>
            <li>Without prejudice to any other rights herein stipulated, either Party may terminate
              the Agreement at any time by giving thirty (30) days’ written notice without giving any
              reason whatsoever.</li>
            <li>Upon the expiry or termination of this Agreement for any reason: (i) any rights granted
              hereunder will immediately cease, (ii) each Party will immediately discontinue use of the
              other Party’s trademarks, other intellectual property, Space Content and Confidential
              Information, including, without limitation rate information; (iii) MillionSpaces will cancel,
              delete or remove from its Sites all the Spaces and render a declaration to that effect to
              the Host along with such evidence in support thereof as may be required by the Host, (iv)
              all unpaid undisputed Commissions through the effective date of termination will be paid
              in accordance with this Agreement and (v) each Party will return to the other Party their
              respective Confidential Information or destroy the same, at the disclosing Party’s option.</li>
          </ol>
        </li>
        <li>Deactivation
          <ol>
            <li>It is acknowledged that the Host will have the ability to delete a Space that it has listed
              only until it has been activated by MillionSpaces on the Sites. Once a Space has been
              activated on the Sites, the Host will only have the ability to deactivate the Space (make it
              unavailable for new bookings) and only MillionSpaces will have the technical ability to
              delete it</li>
            <li>It is agreed that even when a Space is deactivated, the Host shall honour
              any ongoing or confirmed booking/s for the Space. </li>
          </ol>
        </li>
      </ol>
    </li>
    <li><span className="main-title">GOVERNING LAW AND DISPUTE RESOLUTION</span>
      <ol>
        <li>This Agreement shall be governed by and construed in all respects in accordance with
          the laws of Singapore.</li>
        <li>The Parties shall first attempt to resolve any dispute arising out of or in connection with
          this Agreement amicably. If the Parties are not able to resolve the dispute amicably within
          a period of thirty (30) days, the Party seeking resolution to the dispute shall give notice
          thereof to the other Party before referring the dispute to arbitration. Any dispute arising
          out of or in connection with this Agreement, including any question regarding its
          existence, validity or termination, shall be referred to and finally resolved by arbitration
          in Singapore in accordance with the Arbitration Rules of the Singapore International
          Arbitration Centre (<b>“SIAC Rules”</b>) for the time being in force, which rules are deemed to
          be incorporated by reference in this clause. The Tribunal shall consist of 1 arbitrator to be
          appointed in accordance with the SIAC Rules. The language of the arbitration shall be
          English. Notwithstanding anything to the contrary contained in this Agreement, either
          Party may commence a civil action or other legal proceedings, including without limitation
          the entry of temporary and permanent injunctions and orders of specific performance, at
          any time seeking to enforce its rights against the other Party for the other Party's
          unauthorized use of either Party’s marks.</li>
      </ol>
    </li>
    <li><span className="main-title">CONFIDENTIALITY</span>
      <ol>
        <li>1 Subject to clauses 16.2 and 16.3, each Party to this Agreement shall, and shall procure
          that its Representatives shall, use its best efforts to keep in strict confidence all
          commercial and technical information and materials in whatever form acquired by it
          (whether directly or indirectly) from or concerning any other Party under this Agreement
          or in connection with the performance of the Agreement (hereinafter called <b>"Confidential
            Information"</b>). No Party and its Representatives shall utilize such Confidential Information
          for any purposes other than those contemplated in this Agreement. </li>
        <li>Confidential Information shall not include information that: (i) is or becomes generally
          known or available to the public at large other than as a result of a breach by a Party of
          its obligations hereunder; (ii) was known to a Party free of any obligation of confidence
          prior to disclosure by the disclosing Party; (iii) is disclosed to a Party on a non-confidential
          basis by a third party who did not owe an obligation of confidence to the disclosing Party; or (iv) is developed by a Party independently of and without reference to any part of the
          Confidential Information.  </li>
        <li>A Party and its Representatives shall be permitted to disclose Confidential Information
          to the extent required by any applicable law, rule, order, legal process and/or regulatory
          requirements including, but not limited to, compliance with a court order or subpoena,
          pursuant to the request of a governmental authority or applicable stock exchange having
          jurisdiction over the Parties hereto or their Representatives, or to the extent required to
          be disclosed by any applicable regulations by a Party as a publicly traded company
          (provided always that a Party shall, to the extent not prohibited by law, provide prior
          written notice of any such disclosure to the other Party together with details of the
          proposed disclosure so that such other Party may, at its sole cost and expense, seek
          injunctive relief or such protections as it may deem appropriate).</li>
        <li>Further, no Party and none of its Representatives shall at any time disclose any
          Confidential Information to any third party for any purposes other than those
          contemplated in this Agreement without the prior written consent of the other Party. </li>
      </ol>
    </li>
    <li><span className="main-title">NOTICE</span>
      <ol>
        <li>Any notice, request or information required to be given by any of the Parties hereto to
          the other Party for the purpose of this Agreement must be made in writing, in the English
          language and shall be deemed adequately delivered to the other Party to its address set
          out in the front page of this Agreement or as a Party otherwise specifies in writing to the
          other Party. </li>
        <li>Any notice required or permitted must be delivered by fax, by electronic mail, by
          personal delivery or by reliable expedited delivery companies including, without
          limitation, Federal Express and DHL. Notices by fax or electronic mail are deemed
          delivered and received upon completion of transmission by the sender and upon
          production by the fax machine of evidence that the transmission was sent in full or a read
          receipt acknowledgement from the messaging service. Notices delivered personally are
          deemed delivered and received upon delivery or reasonable proof that delivery was
          attempted and refused. Notices by expedited delivery are deemed delivered and received
          on the second (2nd) business day immediately succeeding the date on which the notice
          was given to the expedited delivery company.</li>
      </ol>
    </li>
    <li><span className="main-title">GENERAL</span>
      <ol>
        <li>Each Party hereby represents and warrants that (i) it has full power and authority to
          enter into this Agreement, to carry out its obligations hereunder, and to grant the rights
          herein granted, (ii) its respective authorized signatory is entitled to sign on its behalf and
          its rights and obligations hereto shall be legally valid and binding and enforceable on it
          and (iii) the execution and delivery of this Agreement does not and will not result in a
          breach, violation or default of any agreement to which a Party may be bound.</li>
        <li>If any provision of this Agreement is determined by a court of competent jurisdiction to
          be invalid, illegal or unenforceable in any respect, such determination shall not impair or
          affect the validity, legality or enforceability of the remaining provisions hereof, and each
          provision is hereby declared to be separate, severable and distinct.
        </li>
        <li>Each Party hereby confirms that this Agreement sets out the entire agreement and
          understanding between the Parties in relation to the transactions hereby contemplated,
          that this Agreement supersedes all previous agreements, arrangements and
          understandings between them with regard to such transactions. Oral changes to this
          Agreement are not valid or enforceable. Changes may be made only by a written
          agreement signed by a duly authorized officer of each Party.</li>
        <li>This Agreement will inure to the benefit of and be binding upon the Parties hereto and
          their respective successors and permitted assigns, as applicable.</li>
        <li>This Agreement is not transferable or assignable, in whole or in part, by either Party
          without the prior written consent of the other, except that the rights and obligations of
          either Party may be assigned without consent to another entity in connection with a
          reorganization, merger, consolidation, acquisition, or other restructuring involving all or
          substantially all of the voting securities and/or assets of such Party. Any assignment in
          violation of this Agreement shall be void.</li>
        <li>No exercise or failure by either Party to exercise or enforce, or delay in exercising or
          enforcing any right, power or remedy conferred by this Agreement shall be deemed to be
          or constitute a waiver of any such right, power or remedy or operate so as to bar the
          exercise or enforcement thereof at any time. Further, no waiver of any term or condition
          in this Agreement by either Party shall be deemed a waiver of any other term or condition
          or constitute a waiver by either Party of any other right power or remedy</li>
        <li>No engagement or transaction of MillionSpaces hereunder shall at any point of time be
          construed to mean as MillionSpaces renting Spaces to any Guest.</li>
        <li>The Host’s relationship with MillionSpaces is that of an independent individual or entity
          and not an employee, agent, joint venturer, or partner of MillionSpaces, except that
          MillionSpaces acts as a payment collection agent as described herein. MillionSpaces does
          not direct or control the Host, the listing of a Space, the Space or the Host’s services, and
          the Host agrees that it has complete discretion whether and when to provide Host’s
          services, a Space, list a Space, and at what price and on what terms to offer them. The
          Parties have no authority or power to bind each other or to contract in the other’s name
          or create a liability against each other in any way.</li>
        <li>Notwithstanding anything to the contrary in this Agreement, provisions that by their
          nature and intent should survive the expiration or termination of this Agreement,
          including but not limited to the domain names of the Sites, <b>"Confidential Information"</b>,
          indemnification, and limitation of liability provisions, shall so survive.</li>
        <li>Unless stated otherwise in this Agreement, this Agreement is for the sole benefit of the
          Parties. Neither Party’s obligations are for the benefit of any third party, and no third party
          acquires any enforceable rights with respect to this Agreement, except as otherwise
          expressly provided in this Agreement, and the application of the Contracts (Rights of Third
          Parties) Act (Cap. 53B) to this Agreement is expressly excluded.
        </li>
        <li>This Agreement may be executed in one or more counterparts and shall become
          effective when one or more counterparts have been signed by all parties. Each
          counterpart shall be deemed an original but all counterparts shall constitute a single
          instrument. Any facsimile copy, other copy or reproduction of a single counterpart
          original of this Agreement shall be as fully effective and binding as the original signed
          counterpart of this Agreement.</li>
      </ol>
    </li>
  </ol>
</>;
