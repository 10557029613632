import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import FlowHandlingButtons from '../Common/HandleNavigationAndSubmit';
import CongratsImg from '../../../assets/images/space-listing/congratulations.svg';
import { getProfileData, setProfileData } from '../Common/DataController';
import { spaceListingData } from '../Common/ProfileListingDataModal';
import { store } from '../../../index';
import { syncWithCalendars, getEmail, calendarAuthData } from '../ListingAction';

const Congratulations = () => {
  const { country } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const response = useSelector((state) => state?.spaceListing?.submitResponse || []);
  const spaceId = useSelector((state) => state?.spaceListing?.submitResponse?.id || null);
  const googleAccessToken = useSelector((state) => state?.spaceListing?.tokenObj?.access_token || null);
  const email = useSelector((state) => state?.spaceListing?.authEmail?.email || null);
  const googleRefreshToken = useSelector((state) => state?.spaceListing?.tokenObj?.refresh_token || null);
  const googleStatus = useSelector((state) => state?.spaceListing?.tokenObj?.googleStatus || 0);
  const countryLongName = useSelector((state) => state?.locationMeta?.data?.country_longCode?.replace(' ', '-') || null);
  const outlookInfo = useSelector((state) => state?.spaceListing?.data || {});
  const outlookStatus = useSelector((state) => state?.spaceListing?.outlookStatus || 0);

  const googleInfo = {
    refreshToken: googleRefreshToken,
    accessToken: googleAccessToken,
    email: email,
  };

  const handleNext = () => {};
  const [unit, setUnit] = useState('');

  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    let storage = {};
    let refreshToken = null;
    let accessToken = null;

    Object.keys(sessionStorage).forEach((key) => {
      storage[key] = sessionStorage.getItem(key);

      if (storage && storage[key] && storage[key] && isJson(storage[key])) {
        if (JSON.parse(storage[key]).credentialType == 'AccessToken') {
          accessToken = JSON.parse(storage[key]).secret;
        } else if (JSON.parse(storage[key]).credentialType == 'RefreshToken') {
          refreshToken = JSON.parse(storage[key]).secret;
        }

        const outlookInfo = {
          refreshToken,
          accessToken,
          email,
        };

        store.dispatch(calendarAuthData(outlookInfo));
      }
    });
  }, [sessionStorage]);

  useEffect(() => {
    setUnit(getProfileData()?.seating_and_common?.[0]?.pricingOptions?.[0]);
  }, []);

  useEffect(() => {
    if (googleAccessToken) {
      dispatch(getEmail(googleAccessToken));
    }
  }, [googleAccessToken]);

  useEffect(() => {
    const data = {
      spaceId: spaceId,
      googleStatus: googleStatus,
      outlookStatus: outlookStatus,
      googleInfo,
      outlookInfo,
    };

    if (spaceId) {
      dispatch(syncWithCalendars(data));
    }
  }, [spaceId]);

  useEffect(() => {
    if (unit) {
      setProfileData(spaceListingData(country));
    }
  }, [unit]);

  const getEventTypeId = () => {
    if (response?.eventTypes?.[0]?.id) return response?.eventTypes?.[0]?.id;
    if (response?.events?.[0]?.eventType?.id) return response?.events?.[0]?.eventType?.id;
  };

  return (
    <>
      <div className="left-side-inner-wrapper congrats-screen">
        <h1 className="listing-page-title">Congratulations!</h1>
        <div className="content-wrapper">
          <div className="paragraph-wrapper">
            <p>You have successfully listed your space on MillionSpaces!</p>
            <p>Our team will review your listing and notify you when your space is available to be booked on the MillionSpaces website.</p>
            <p>
              {' '}
              A confirmation has been sent to your email address. <span className="text">(it may sometimes land in your SPAM folder)</span>
            </p>
          </div>
          <img className="congrats-img" src={CongratsImg} alt="congratulations" />
          <div className="btn-wrapper">
            <div
              className="btn border-btn"
              onClick={() =>
                history.push('/' + countryLongName + '/space/bookings?eventTypeId=' + getEventTypeId() + '&spaceId=' + response?.id + '&unit=' + unit)
              }
            >
              view space
            </div>
            <div className="btn yellow-btn" onClick={() => history.push(`/${countryLongName}`)}>
              go to home
            </div>
          </div>
        </div>
      </div>
      <div className="no-floating">
        <FlowHandlingButtons backButton={false} handleNext={handleNext} nextButtonText={'Go To Home'} />
      </div>
    </>
  );
};

export default Congratulations;
