import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { TextField } from "react-md";
import { getFieldCurrentValue, handleDataObject } from '../Common/Common';
import { validateEmail } from '../../SpaceListing/Common/Validator';
import FlowHandlingButtons from "../Common/HandleNavigationAndSubmit";
import { hostOnBoardingData } from '../Common/HostOnBoardingDataModal';
import { COMMISSION, DEFAULT_DATA_OBJ } from '../HostOnboardingConstants';
import { getProfileData } from "../Common/DataController";
import { contentIsNotNull } from '../Common/Validator';
import {
  ERROR_TEXT_FIELD,
  SRI_LANKA_SHORT_CODE
} from '../../SpaceListing/SpaceListingConstants';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const { Option } = Select;
const OrganizationDetails = ({ currentBlock }) => {
  const { country } = useParams();
  const [isNextBtnActive, setIsNextBtnActive] = useState(true);
  const countryList = useSelector((state) => state?.auth?.countryListDetails?.countryList || []);
  const [organizationBlock, setOrganizationBlock] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : hostOnBoardingData(country));

  useEffect(() => {
    setIsNextBtnActive(
      contentIsNotNull(getFieldCurrentValue(currentBlock,"orgName"), 0) &&
      contentIsNotNull(getFieldCurrentValue(currentBlock,"orgWebsite"), 0) &&
      contentIsNotNull(getFieldCurrentValue(currentBlock,"businessRegNo"), 0,500) &&
      contentIsNotNull(getFieldCurrentValue(currentBlock,"addressLine1"), 0) &&
      contentIsNotNull(getFieldCurrentValue(currentBlock,"postalCode"), 0) &&
      contentIsNotNull(getFieldCurrentValue(currentBlock,"city"), 0) &&
      contentIsNotNull(getFieldCurrentValue(currentBlock,"country"), 0) &&
      contentIsNotNull(getFieldCurrentValue(currentBlock,"fistName"), 0) &&
      contentIsNotNull(getFieldCurrentValue(currentBlock,"lastName"), 0) &&
      contentIsNotNull(getFieldCurrentValue(currentBlock,"designation"), 0) &&
      contentIsNotNull(getFieldCurrentValue(currentBlock,"email"), 0) &&
      validateEmail(getFieldCurrentValue(currentBlock,"email")) &&
      contentIsNotNull(getFieldCurrentValue(currentBlock,"dialCode"), 0) &&
      contentIsNotNull(getFieldCurrentValue(currentBlock,"phone"), 0) &&
      /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g.test(getFieldCurrentValue(currentBlock,"orgWebsite"))
    );
  }, [organizationBlock]);

  return (
    <>
      <div className="left-side-inner-wrapper space-location-wrapper host-details-wrapper">
        <h1 className="listing-page-title">Company details</h1>
        <div className="company-details-wrapper">
          <TextField
            className="input-margin-wrapper"
            id="orgName"
            label="Company name"
            lineDirection="center"
            placeholder="Eg: MIllionSpaces"
            errorText="This field is required."
            onChange={(value) => handleDataObject("orgName", value, currentBlock, organizationBlock, setOrganizationBlock)}
            value={getFieldCurrentValue(currentBlock,"orgName")}
          />
          <TextField
            className="input-margin-wrapper"
            id="orgWebsite"
            label="Company website"
            lineDirection="center"
            placeholder="Eg: www.millionspaces.com"
            errorText="This field is required."
            onChange={(value) => handleDataObject("orgWebsite", value, currentBlock, organizationBlock, setOrganizationBlock)}
            value={getFieldCurrentValue(currentBlock,"orgWebsite")}
          />
          <TextField
            className="input-margin-wrapper"
            id="businessRegNo"
            label={<div>Business registration number</div>}
            placeholder="Eg: BR321627"
            lineDirection="center"
            errorText="This field is required."
            onChange={(value) => handleDataObject("businessRegNo", value, currentBlock, organizationBlock, setOrganizationBlock)}
            value={getFieldCurrentValue(currentBlock,"businessRegNo")}
          />

        </div>
          <h1 className="listing-page-title">Company address</h1>
        <div className="company-details-wrapper">
          <TextField
            className="input-margin-wrapper"
            id="addressLine1"
            label="Address Line 1"
            lineDirection="center"
            errorText="This field is required."
            onChange={(value) => handleDataObject("addressLine1", value, currentBlock, organizationBlock, setOrganizationBlock)}
            value={getFieldCurrentValue(currentBlock,"addressLine1")}
          />
          <TextField
            className="input-margin-wrapper"
            id="addressLine2"
            label="Address Line 2 (Optional)"
            lineDirection="center"
            errorText="This field is required."
            onChange={(value) => handleDataObject("addressLine2", value, currentBlock, organizationBlock, setOrganizationBlock)}
            value={getFieldCurrentValue(currentBlock,"addressLine2")}
          />
          <TextField
            className="input-margin-wrapper"
            id="postalCode"
            label="Postal Code"
            lineDirection="center"
            errorText="This field is required."
            onChange={(value) => handleDataObject("postalCode", value, currentBlock, organizationBlock, setOrganizationBlock)}
            value={getFieldCurrentValue(currentBlock,"postalCode")}
          />
          <TextField
            className="input-margin-wrapper"
            id="city"
            label="City"
            lineDirection="center"
            errorText="This field is required."
            onChange={(value) => handleDataObject("city", value, currentBlock, organizationBlock, setOrganizationBlock)}
            value={getFieldCurrentValue(currentBlock,"city")}
          />

          <Select
            className={`country-select input-margin-wrapper ${getFieldCurrentValue(currentBlock,'country')?.length > 0 ? 'placeHolderTop' : ''}`}
            value={getFieldCurrentValue(currentBlock,"country")}
            placeholder={"Country"}
            dropdownClassName="space-listing-drop-down "
            onSelect={(value) => {
              value === SRI_LANKA_SHORT_CODE?handleDataObject('percentage', 20, COMMISSION, organizationBlock, setOrganizationBlock):handleDataObject('percentage', 10, COMMISSION, organizationBlock, setOrganizationBlock)
              handleDataObject('country', value, currentBlock, organizationBlock, setOrganizationBlock);
              handleDataObject('dialCode', countryList?.find(indexElement=> indexElement.code === value)?.dialCode, currentBlock, organizationBlock, setOrganizationBlock);
            }}
          >
            {countryList?.map(value=><Option value={value?.code}>{value?.name}</Option>)}
          </Select>
        </div>

        <h1 className="listing-page-title">Contact details</h1>
        <div><div className="name-wrapper">
          <TextField
            className="input-margin-wrapper"
            id={"fistName"}
            label="First Name"
            lineDirection="center"
            errorText={ERROR_TEXT_FIELD}
            onChange={(value) => handleDataObject("fistName", value, currentBlock, organizationBlock, setOrganizationBlock)}
            value={getFieldCurrentValue(currentBlock,"fistName")}
          />
          <TextField
            className="input-margin-wrapper"
            id={"lastName"}
            label="Last Name"
            lineDirection="center"
            errorText={ERROR_TEXT_FIELD}
            onChange={(value) => handleDataObject("lastName", value, currentBlock, organizationBlock, setOrganizationBlock)}
            value={getFieldCurrentValue(currentBlock,"lastName")}
          />
        </div>

          <TextField
            className="input-margin-wrapper"
            id="designation"
            label="Designation"
            lineDirection="center"
            errorText="This field is required."
            onChange={(value) => handleDataObject("designation", value, currentBlock, organizationBlock, setOrganizationBlock)}
            value={getFieldCurrentValue(currentBlock,"designation")}
          />
          <TextField
            className="input-margin-wrapper"
            id={"email"}
            label="Email address"
            lineDirection="center"
            errorText={ERROR_TEXT_FIELD}
            onChange={(value) => handleDataObject("email", value, currentBlock, organizationBlock, setOrganizationBlock)}
            value={getFieldCurrentValue(currentBlock,"email")}
          />
          <hr className="divider" />
          <Select
            className={`input-margin-wrapper ${getFieldCurrentValue('dialCode')?.length > 0 ? 'placeHolderTop' : ''}`}
            value={getFieldCurrentValue(currentBlock,"dialCode")}
            placeholder={"Country"}
            dropdownClassName="space-listing-drop-down "
            onSelect={(value) => handleDataObject('dialCode', value, currentBlock, organizationBlock, setOrganizationBlock)}
          >
            {countryList?.map(value=><Option value={value?.dialCode}>{value?.name}</Option>)}
          </Select>

          <div className="prefix-tp-wrapper">
            <div className="prefix">
              <div className="prefix-text">{getFieldCurrentValue(currentBlock,"dialCode")}</div>
            </div>
            <TextField
              id={"phone"}
              className="input-margin-wrapper"
              lineDirection="center"
              errorText={ERROR_TEXT_FIELD}
              onChange={(value) => handleDataObject("phone", value, currentBlock, organizationBlock, setOrganizationBlock, 50, false,true,true)}
              value={getFieldCurrentValue(currentBlock,"phone")}
            />
          </div>
        </div>
      </div>
      <FlowHandlingButtons isActive={isNextBtnActive}/>
    </>
  );
};

export default OrganizationDetails;
