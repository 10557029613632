import React, { useCallback, useEffect, useState } from 'react';
import FlowHandlingButtons from '../Common/HandleNavigationAndSubmit';
import Switch from 'antd/es/switch';
import { getProfileData, setProfileData } from '../Common/DataController';
import { spaceListingData } from '../Common/ProfileListingDataModal';
import { AGREEMENT, CONGRATULATIONS, DEFAULT_DATA_OBJ, RULES } from '../SpaceListingConstants';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const Rules = ({ currentBlock }) => {
  const { country } = useParams();
  const [rulesDetails, setRulesDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));

  const handleRulesDetails = useCallback((name, newValue) => {
    const tempObj = Object.assign({}, rulesDetails, {
      ...rulesDetails,
      processStarted: true,
      [currentBlock]: rulesDetails?.[currentBlock].map((value) => {
        value[name] = newValue;
        return value;
      }),
    });
    setRulesDetails(tempObj);
    setProfileData(tempObj);
  }, []);

  const getFieldCurrentValue = (type) => {
    return rulesDetails?.[currentBlock]?.[0]?.[type];
  };
  const handleRuleClick = (id, switchValue) => {
    const tempRuleArray = getFieldCurrentValue('data') || [];

    tempRuleArray.forEach((value) => {
      if (value.id === id) {
        value.selectedStatus = switchValue;
      }
    });
    handleRulesDetails('data', tempRuleArray);
  };


  const getCurrentSelectValue = (id) => {
    const tempRuleData = getFieldCurrentValue('data') || [];
    return tempRuleData.find((value) => value?.id === id);
  };

  return (
    <>
      <div className="left-side-inner-wrapper">
        <h1 className="listing-page-title page-title-no-margin">Rules</h1>
        <p className="gray-text sm-text">Please select the space usage rules that your guests must abide by.</p>
        <div className="page-switch-btn-wrapper">
          {getFieldCurrentValue('data') && getFieldCurrentValue('data')?.map((rulesElements) =>
          <div className="switch-name-wrapper">
            <div className="name">
              {rulesElements?.name || 'No Name'}
            </div>
            <Switch
              checked={getCurrentSelectValue(rulesElements?.id)?.selectedStatus} onChange={(value) => {
              handleRuleClick(rulesElements?.id, value);
            }}/>
          </div>)}
        </div>


      </div>
      <FlowHandlingButtons />
    </>
  );
};

export default Rules;
