// Page List Content
export const DEFAULT_DATA_OBJ = 'login_details';
export const WELCOME = 'welcome';
export const LOGIN_DETAILS = 'login_details';
export const ORGANIZATION_DETAILS = 'organization_details';
export const SPACE_DETAILS = 'space_details';
export const SPACE_LOCATION = 'space_location';
export const EXTERNAL_SYSTEM = 'external_systems';
export const UPLOADING_IMAGES = 'uploading_images';
export const SPACE_AND_NOTICE = 'space_and_notice';
export const TAX_INFORMATION = 'tax_information';
export const SEATING_AND_COMMON = 'seating_and_common';
export const SEATING_ARRANGEMENT  = 'seating_arrangement';
export const SAVINGS = 'savings';
export const PRICING_OPTIONS = 'pricing_options';
export const HOUR_OPTION = 'hour';
export const HOURLY_OPTION = 'hourly';
export const BLOCK_OPTION = 'block';
export const DAILY_OPTION = 'daily';
export const MONTHLY_OPTION = 'monthly';
export const WEEKLY_OPTION = 'weekly';
export const MONTHLY_PRICING_DETAILS = 'monthly_pricing_details';
export const AVAILABILITY = 'availability';
export const DISCOUNT_DETAILS = 'discount_details';
export const PACKAGES = 'packages';
export const AMENITIES = 'amenities';
export const ADDONS = 'addons';
export const RULES = 'rules';
export const CALENDARSYNC = 'calendar_sync';
export const PAYOUT_DETAILS = 'payout_details';
export const COMMISSION = 'commission';
export const AGREEMENT = 'agreement';
export const CONGRATULATIONS = 'congratulations';

// Country Details.
export const SRI_LANKA = 'SRI_LANKA';
export const SINGAPORE = 'SINGAPORE';
export const AUSTRALIA = "AUSTRALIA"
export const SRI_LANKA_SHORT_CODE = 'LK';
export const SINGAPORE_SHORT_CODE = 'SG';
export const AUSTRALIA_SHORT_CODE = 'AUS';
export const SRI_LANKA_CURRENCY = 'LKR';
export const SINGAPORE_CURRENCY = 'SGD';
export const AUSTRALIA_CURRENCY = "AUD"
export const SRI_LANKA_DIAL_CODE = '+94';

// Day list.
export const DAYS_ARRAY = ['Daily','Weekdays','Weekends','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'];

// Pricing Options
export const BLOCK_LOWER = 'block';
export const DAY_LOWER = 'day';
export const WEEKLY_LOWER = 'week';
export const MONTHLY_LOWER = 'monthly';
export const MONTH_LOWER = 'month';
export const HOUR_LOWER = 'hour';
export const MEETING_SPACE_CODE = 'MEET_MEET';
export const WORK_SPACE_CODE = 'WORK_HOTD';

// URL DETAILS
export const seatingArrangementUrls = (country) => `/${country}/dashboard/list-a-space/seating_arrangement`;
export const BLOCK_URLS = (country) => [`/${country}/dashboard/list-a-space/pricing_options/block/availability`, `/${country}/dashboard/list-a-space/pricing_options/block/packages`];
export const HOURLY_URLS = (country) => [`/${country}/dashboard/list-a-space/pricing_options/hourly/availability`, `/${country}/dashboard/list-a-space/pricing_options/hourly/discount_details`,  `/${country}/dashboard/list-a-space/pricing_options/hourly/packages`];
export const DAILY_URLS = (country) => [`/${country}/dashboard/list-a-space/pricing_options/daily/availability`,  `/${country}/dashboard/list-a-space/pricing_options/daily/packages`];
export const WEEKLY_URLS  = (country) => [`/${country}/dashboard/list-a-space/pricing_options/weekly/availability`,  `/${country}/dashboard/list-a-space/pricing_options/weekly/packages`];
export const MONTHLY_URLS  = (country) => [`/${country}/dashboard/list-a-space/pricing_options/monthly/pricing_options`,  `/${country}/dashboard/list-a-space/pricing_options/monthly/availability`,  `/${country}/dashboard/list-a-space/pricing_options/monthly/packages`];

export const BLOCK_MEET_URLS = (country) => [`/${country}/dashboard/list-a-space/pricing_options/block/availability`];
export const HOURLY_MEET_URLS = (country) => [`/${country}/dashboard/list-a-space/pricing_options/hourly/availability`, `/${country}/dashboard/list-a-space/pricing_options/hourly/discount_details`];
export const DAILY_MEET_URLS = (country) => [`/${country}/dashboard/list-a-space/pricing_options/daily/availability`];
export const WEEKLY_MEET_URLS = (country) => [`/${country}/dashboard/list-a-space/pricing_options/weekly/availability`];

// Error Messages.
export const ERROR_CHAR_LIMIT = 'Character limit is exceeded!';
export const ERROR_SPECIAL_CHAR = 'No special characters are allowed!';
export const ERROR_WHITE_SPACE = 'White spaces are not allowed!';
export const ERROR_ONLY_NUMBERS = 'Only numbers allowed!';
export const ERROR_NO_NUMBERS_ALLOWED = 'Numbers are not allowed!';
export const ERROR_TEXT_FIELD = 'This field is required**';
export const ERROR_NUMBER_EXCEEDED = "Number count is exceeded!";
export const ERROR_LOCATION_ACCESS="Please enable location sharing on your device so we can recommend spaces for you"
export const ERROR_VIEW_BOOKING="Please logged in to the site , in order to view the booking "

// Variable constants.
export const AUTHORITIES = 'authorities';
export const ADMIN_ROLE = 'role.admin';
export const USER_ROLE = 'role.user';
export const FIRST_NAME = "firstName";
export const LAST_NAME = "lastName";
export const EMAIL = "email";
export const PHONE_NUMBERS = 'phoneNumbers';
export const COUNTRY = 'country';


// Common constants.
export const BACK_BTN = 'BACK';
export const BACK_TO_HOME = 'BACK TO HOME';
export const LETS_START = "LET'S START";
