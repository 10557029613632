import React, { useCallback, useEffect, useState } from 'react';
import decode from 'jwt-decode';
import Select from 'antd/es/select';
import { TextField } from 'react-md';
import { useSelector } from 'react-redux';
import { showErrorMessage } from '../Common/Common';
import { spaceListingData } from '../Common/ProfileListingDataModal';
import FlowHandlingButtons from '../Common/HandleNavigationAndSubmit';
import {
  ADMIN_ROLE,
  AUTHORITIES,
  DEFAULT_DATA_OBJ,
  EMAIL,
  ERROR_CHAR_LIMIT,
  ERROR_TEXT_FIELD,
  ERROR_SPECIAL_CHAR,
  ERROR_WHITE_SPACE,
  FIRST_NAME,
  LAST_NAME,
  PHONE_NUMBERS,
  COUNTRY,
  ERROR_ONLY_NUMBERS,
  ERROR_NO_NUMBERS_ALLOWED
} from '../SpaceListingConstants';
import { getProfileData, setProfileData } from '../Common/DataController';
import informationIcon from '../../../assets/images/space-listing/informationIcon.svg';
import { contentIsNotNull, noSpecialCharIncluded, validateEmail } from '../Common/Validator';
import { useParams } from 'react-router-dom';

const { Option } = Select;
const LoginDetails = ({ currentBlock }) => {
  const { country } = useParams();
  const [hostDetails, setHostDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));
  const [isNextBtnActive, setIsNextBtnActive] = useState(false);
  const authDetails = useSelector((state) => state?.auth?.loginDetails?.data?.token || null);
  const usersDetails = authDetails ? decode(authDetails) : '';
  const countryShortCode = useSelector((state) => state?.locationMeta?.data?.country_shortCode || 'SG');
  const [prevCountryCode, setPrevCountryCode] = useState('');
  const [handlePhoneNumber, setHandlePhoneNumber] = useState(false);

  const profileListingData = JSON.parse(sessionStorage.getItem('profileListingData'));

  const isUserNonAdmin = () => {
    return usersDetails?.[AUTHORITIES]?.[0] !== ADMIN_ROLE;
  };

  useEffect(() => {
    if (isUserNonAdmin()) {
      if (!contentIsNotNull(getFieldCurrentValue(FIRST_NAME), 0, 50)) {
        handleHostDetails(FIRST_NAME, usersDetails?.[FIRST_NAME]);
      }
      if (!contentIsNotNull(getFieldCurrentValue(LAST_NAME), 0, 50)) {
        handleHostDetails(LAST_NAME, usersDetails?.[LAST_NAME]);
      }
      if (!contentIsNotNull(getFieldCurrentValue(EMAIL), 0, 50)) {
        handleHostDetails(EMAIL, usersDetails?.sub);
      }
    }
  }, [authDetails]);

  useEffect(() => {
    profileListingData?.space_location?.[0]?.spaceCountry == "Singapore"
    ? setIsNextBtnActive(
      contentIsNotNull(getFieldCurrentValue(FIRST_NAME), 0, 50) &&
      // noSpecialCharIncluded(getFieldCurrentValue(FIRST_NAME)) &&
      contentIsNotNull(getFieldCurrentValue(LAST_NAME), 0, 50) &&
      // noSpecialCharIncluded(getFieldCurrentValue(LAST_NAME)) &&
      contentIsNotNull(getFieldCurrentValue(EMAIL), 0, 320) &&
      validateEmail(getFieldCurrentValue(EMAIL)) &&
      contentIsNotNull(getFieldCurrentValue(PHONE_NUMBERS), 7, 20)
    )
    : setIsNextBtnActive(
      contentIsNotNull(getFieldCurrentValue(FIRST_NAME), 0, 50) &&
      // noSpecialCharIncluded(getFieldCurrentValue(FIRST_NAME)) &&
      contentIsNotNull(getFieldCurrentValue(LAST_NAME), 0, 50) &&
      // noSpecialCharIncluded(getFieldCurrentValue(LAST_NAME)) &&
      contentIsNotNull(getFieldCurrentValue(EMAIL), 0, 320) &&
      validateEmail(getFieldCurrentValue(EMAIL)) &&
      contentIsNotNull(getFieldCurrentValue(PHONE_NUMBERS), 8, 20)
    );
  }, [hostDetails]);

  const getFieldCurrentValue = (type) => {
    return hostDetails?.[currentBlock]?.[0]?.[type];
  };

  useEffect(() => {
    if (handlePhoneNumber) {
      handleHostDetails(PHONE_NUMBERS, '');
    }
  }, [handlePhoneNumber]);

  useEffect(() => {
    const result = countryShortCode === 'LK' ? '+94' : countryShortCode === 'AUS' ? '+61' : '+94';
    if (getFieldCurrentValue(COUNTRY) != prevCountryCode) {
      handleHostDetails(COUNTRY, getFieldCurrentValue(COUNTRY));
    } else {
      handleHostDetails(COUNTRY, result);
    }
  }, []);

  const handleHostDetails = useCallback(
    (name, newValue, max = 5000, noSpecialChar = false, noWhiteSpaces = false, onlyNumbers = false, noNumbersAllowed = false) => {
      if (newValue?.length > max) {
        showErrorMessage(ERROR_CHAR_LIMIT);
      } else if (noSpecialChar && !noSpecialCharIncluded(newValue.toString())) {
        showErrorMessage(ERROR_SPECIAL_CHAR);
      } else if (noWhiteSpaces && /\s/g.test(newValue)) {
        showErrorMessage(ERROR_WHITE_SPACE);
      } else if (onlyNumbers && !/^\d+$/.test(newValue) && newValue !== '') {
        showErrorMessage(ERROR_ONLY_NUMBERS);
      } else if (noNumbersAllowed && /[^a-zA-Z ]/g.test(newValue) && newValue !== '') {
        showErrorMessage(ERROR_NO_NUMBERS_ALLOWED);
      } else {
        const tempObj = Object.assign({}, hostDetails, {
          ...hostDetails,
          processStarted: true,
          [currentBlock]: hostDetails?.[currentBlock].map((value) => {
            value[name] = newValue;
            return value;
          }),
        });
        setHostDetails(tempObj);
        setProfileData(tempObj);
      }
    },
    []
  );

  const onChangeCountryCode = (value) => {
    setPrevCountryCode(getFieldCurrentValue(COUNTRY));
    handleHostDetails(COUNTRY, value);
    setHandlePhoneNumber(true);
  };

  return (
    <>
      <div className="left-side-inner-wrapper host-details-wrapper">
        <h1 className="listing-page-title">Login details</h1>
        <div className="icon-sentense-wrapper">
          <div className="icon-sentense-inner-wrapper">
            <img className="icon" src={informationIcon} alt={'i Icon'} />
            <p className="green-text">The details below will be the ones you use to login to MillionSpaces.</p>
          </div>
        </div>
        <div className="name-wrapper">
          <TextField
            className="input-margin-wrapper"
            id={FIRST_NAME}
            label="First Name"
            lineDirection="center"
            errorText={ERROR_TEXT_FIELD}
            disabled={isUserNonAdmin()}
            onChange={(value) => handleHostDetails(FIRST_NAME, value, 50, false, true, false, true)}
            value={getFieldCurrentValue(FIRST_NAME)}
          />
          <TextField
            className="input-margin-wrapper"
            id={LAST_NAME}
            label="Last Name"
            lineDirection="center"
            errorText={ERROR_TEXT_FIELD}
            disabled={isUserNonAdmin()}
            onChange={(value) => handleHostDetails(LAST_NAME, value, 50, false, true, false, true)}
            value={getFieldCurrentValue(LAST_NAME)}
          />
        </div>
        <TextField
          className="input-margin-wrapper"
          id={EMAIL}
          label="Email"
          lineDirection="center"
          errorText={ERROR_TEXT_FIELD}
          disabled={isUserNonAdmin()}
          onChange={(value) => handleHostDetails(EMAIL, value)}
          value={getFieldCurrentValue(EMAIL)}
        />
        <hr className="divider" />
        {/* <Select
          placeholder="Country code"
          className={`${getFieldCurrentValue(COUNTRY)?.length > 0 ? 'placeHolderTop' : ''}`}
          id={COUNTRY}
          defaultValue={getFieldCurrentValue(COUNTRY)}
          value={getFieldCurrentValue(COUNTRY)}
          dropdownClassName="space-listing-drop-down"
          onChange={(value) => onChangeCountryCode(value)}
        >
          <Option value={'+94'}>Sri Lanka (+94)</Option>
        </Select> */}
        <TextField
          className="input-margin-wrapper"
          id={COUNTRY}
          label="CountryCode"
          lineDirection="center"
          errorText={ERROR_TEXT_FIELD}
          disabled={true}
          onChange={(value) => handleHostDetails(EMAIL, value)}
          value="Sri Lanka (+94)"
        />
        <div className="prefix-tp-wrapper">
          <div className="prefix">
            <div className="prefix-text">{getFieldCurrentValue(COUNTRY)}</div>
          </div>
          <TextField
            id={PHONE_NUMBERS}
            className="input-margin-wrapper"
            lineDirection="center"
            errorText={ERROR_TEXT_FIELD}
            onChange={(value) => handleHostDetails(PHONE_NUMBERS, value, 20, true, true, true)}
            value={getFieldCurrentValue(PHONE_NUMBERS)}
          />
        </div>
      </div>
      <FlowHandlingButtons isActive={isNextBtnActive} />
    </>
  );
};

export default LoginDetails;
