import React, { useState, useEffect } from 'react';
import calendar from '../../assets/images/search-result/calendar.svg';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';


const HostFooter = () => {
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState('');
    const searchURLstring = useSelector(state => state?.router?.location?.pathname || '');

    useEffect(() => {
        if (searchURLstring){
            setActiveTab(searchURLstring)
        }
    }, [searchURLstring])

    const handleMySpacesOnClick = () => {
        const URLtoPush = `/myspaces`
        dispatch(push(URLtoPush));
    }

    const handleReservationOnClick = () => {
        const URLtoPush = `/comingsoon`
        dispatch(push(URLtoPush));
    }

    return (
        <>
            {/* This code will require in 2nd phase */}

            {/*<div className="navbar-footer">*/}
            {/*    {activeTab === '/myspaces' ? <div className="footer-list active" onClick={() => handleMySpacesOnClick()}>*/}
            {/*        <img src={calendar} alt="img" className="icon" />*/}
            {/*        <span className="footer-text">*/}
            {/*            MY SPACES*/}
            {/*        </span>*/}
            {/*    </div> : <div className="footer-list" onClick={() => handleMySpacesOnClick()}>*/}
            {/*        <img src={calendar} alt="img" className="icon" />*/}
            {/*        <span className="footer-text">*/}
            {/*            MY SPACES*/}
            {/*        </span>*/}
            {/*    </div>}*/}
            {/*    <div className="footer-list" onClick={() => handleReservationOnClick()}>*/}
            {/*        <img src={calendar} alt="img" className="icon" />*/}
            {/*        <span className="footer-text">*/}
            {/*            RESERVATIONS*/}
            {/*        </span>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    );
}

export default HostFooter;
