import React, { useCallback, useState } from 'react';
import { DialogContainer, TextField } from 'react-md';
import FlowHandlingButtons from '../Common/HandleNavigationAndSubmit';
import Switch from 'antd/es/switch';
import { getProfileData, setProfileData } from '../Common/DataController';
import { useDispatch } from 'react-redux';
import { spaceListingData } from '../Common/ProfileListingDataModal';
import {
  DEFAULT_DATA_OBJ,
  ERROR_CHAR_LIMIT,
  ERROR_ONLY_NUMBERS,
  ERROR_SPECIAL_CHAR,
  ERROR_WHITE_SPACE
} from '../SpaceListingConstants';
import { showErrorMessage } from '../Common/Common';
import { noSpecialCharIncluded } from '../Common/Validator';
import { getAddOns } from '../ListingAction';
import { useParams } from 'react-router-dom';

const AddOns = ({ currentBlock }) => {
  const { country } = useParams();
  const [addOnDetails, setAddonDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));
  const [isAddOnPopupVisible, setIsAddOnPopupVisible] = useState(false);
  const [currentAddonId, setCurrentAddonId] = useState(false);

  const handleAddons = useCallback((name, newValue) => {
    const tempObj = Object.assign({}, addOnDetails, {
      ...addOnDetails,
      processStarted: true,
      [currentBlock]: addOnDetails?.[currentBlock].map((value, index) => {
        value[name] = newValue;
        return value;
      }),
    });
    setAddonDetails(tempObj);
    setProfileData(tempObj);
  }, []);

  const getFieldCurrentValue = (type) => {
    return addOnDetails?.[currentBlock]?.[0]?.[type];
  };

  const handleDataUpdating = (id, type, changedValue, max=5000, noSpecialChar=false, noWhiteSpaces=false, onlyNumbers=false) => {
    if(onlyNumbers){
      changedValue = changedValue.replace(/^0+/, '');
    }

    if (onlyNumbers && !(/^[0-9.]{0,60}$/i).test(changedValue) && changedValue !== '') {
      showErrorMessage(ERROR_ONLY_NUMBERS)
    }else {
      if (type === 'selectedStatus' && changedValue === true) {
        setIsAddOnPopupVisible(true);
        setCurrentAddonId(id);
      } else if (type === 'selectedStatus' && changedValue === false) {
        setIsAddOnPopupVisible(false);
        setCurrentAddonId(id);
      }
      const tempAddonDetails = getFieldCurrentValue('data') || [];

      tempAddonDetails.forEach((value) => {
        if (value?.id === id) {
          value[type] = changedValue;
        }
      });
      handleAddons('data', tempAddonDetails);
    }
  };

  const getCurrentSelectValue = (id) => {
    const tempAmenityDetails = getFieldCurrentValue('data') || [];
    return tempAmenityDetails.find((value) => value?.id === id);
  };

  const currentAddonDetails = () => getFieldCurrentValue('data')?.filter(value=>value?.id===currentAddonId)[0];

  const handleAddAddons = () => {
    if(currentAddonDetails()?.price > 0){
      setIsAddOnPopupVisible(false);
    }
  }

  const getCurrencyType = () =>{
    return getProfileData()?.tax_information?.[0]?.currency;
  }

  return (
    <>
      <div className="left-side-inner-wrapper">
        <h1 className="listing-page-title page-title-no-margin">Add Ons</h1>
        <p className="gray-text sm-text">Please select the additional services that your guest can purchase.</p>
        <div className="page-switch-btn-wrapper">
          {getFieldCurrentValue('data') && getFieldCurrentValue('data').map((addonElement) =>
          <div className="switch-name-wrapper">
            <div className="block-wrapper">
              <div className="name-price-wrapper">
                <div className="name">{addonElement?.name || 'No Name'}</div>
                <div className="price">({getCurrencyType()} {getCurrentSelectValue(addonElement?.id)?.price})</div>
              </div>
              <div className="description">{getCurrentSelectValue(addonElement?.id)?.description}</div>
            </div>
            <Switch checked={getCurrentSelectValue(addonElement?.id)?.selectedStatus} onChange={(value) => {
              handleDataUpdating(addonElement?.id, 'selectedStatus', value);
            }}/>

          </div>)}
        </div>
      </div>

      <FlowHandlingButtons/>

      <DialogContainer
        className="common-popup add-on-popup"
        visible={isAddOnPopupVisible}
        id={"AddonId"}
        onHide={() => {
          handleDataUpdating(currentAddonId, 'selectedStatus', false);
          handleDataUpdating(currentAddonId, 'price', 0);
          handleDataUpdating(currentAddonId, 'description', '');
        }}
      >
          <h2 className="main-title">{currentAddonDetails()?.name}</h2>
          <TextField
            className="input-margin-wrapper"
            id="price"
            label={`Price (${getCurrencyType()})`}
            lineDirection="center"
            errorText="Price is required."
            value={currentAddonDetails()?.price}
            onChange={(value) => handleDataUpdating(currentAddonId, 'price', value, 5000, true, true, true)}
          />

          <TextField
            className="text-area"
            rows={2}
            id="description"
            label="Description (Optional)"
            lineDirection="center"
            errorText="Description is required."
            value={currentAddonDetails()?.description}
            onChange={(value) => handleDataUpdating(currentAddonId, 'description', value, 250)}
          />
          <div className="btn-wrapper">
            <button className="border-btn" onClick={() => {
              handleDataUpdating(currentAddonId, 'selectedStatus', false);
              handleDataUpdating(currentAddonId, 'price', 0);
              handleDataUpdating(currentAddonId, 'description', '');
             }}>Cancel
            </button>
            <button className={`gray-btn ${currentAddonDetails()?.price > 0? 'active':''}`} onClick={() => handleAddAddons()}>Add</button>
          </div>
      </DialogContainer>
    </>
  );
};

export default AddOns;
