import React, { useCallback, useState } from 'react';
import FlowHandlingButtons from '../Common/HandleNavigationAndSubmit';
import Switch from 'antd/es/switch';
import { useDispatch } from 'react-redux';
import { getProfileData, setProfileData } from '../Common/DataController';
import { spaceListingData } from '../Common/ProfileListingDataModal';
import { DEFAULT_DATA_OBJ } from '../SpaceListingConstants';
import { useParams } from 'react-router-dom';

const Amenities = ({ currentBlock }) => {
  const { country } = useParams();
  const [amenitiesDetails, setAmenitiesDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));

  const handleAmenitiesDetails = useCallback((name, newValue) => {
    const tempObj = Object.assign({}, amenitiesDetails, {
      ...amenitiesDetails,
      processStarted: true,
      [currentBlock]: amenitiesDetails?.[currentBlock].map((value, index) => {
        value[name] = newValue;
        return value;
      }),
    });
    setAmenitiesDetails(tempObj);
    setProfileData(tempObj);
  }, []);

  const getFieldCurrentValue = (type) => {
    return amenitiesDetails?.[currentBlock]?.[0]?.[type];
  };

  const handleAmenitiesClick = (id, switchValue) => {
    const tempAmenityDetails = getFieldCurrentValue('data') || [];
    tempAmenityDetails.forEach((value) => {
      if (value.id === id) {
        value.selectedStatus = switchValue;
      }
    });
    handleAmenitiesDetails('data', tempAmenityDetails);
  };

  const getCurrentSelectValue = (id) => {
    const tempAmenityDetails = getFieldCurrentValue('data') || [];
    return tempAmenityDetails.find((value) => value?.id === id);
  };

  const filteredIT = getFieldCurrentValue('data').filter(amenitiesElement => {
    return amenitiesElement?.subCategory?.name === 'IT';
  });

  const filteredFoodAndBeverages = getFieldCurrentValue('data').filter(amenitiesElement => {
    return amenitiesElement?.subCategory?.name === 'Food and Beverages';
  });

  const filteredFacilities = getFieldCurrentValue('data').filter(amenitiesElement => {
    return amenitiesElement?.subCategory?.name === 'Facilities';
  });


  return (
    <>
      <div className="left-side-inner-wrapper ">
        <h1 className="listing-page-title page-title-no-margin">Amenities</h1>
        <p className="gray-text sm-text">Please select the complimentary amenities that you can provide your guests.</p>
        <div className="page-switch-btn-wrapper">

          <p className="gray-text sm-text">IT</p>
          {filteredIT && filteredIT.map((amenities) =>
            <div className="switch-name-wrapper">
              <div className="name"> 

                {amenities?.name || 'No Name'} 
              
              </div>
              <Switch
                checked={getCurrentSelectValue(amenities?.id)?.selectedStatus} onChange={(value) => {
                handleAmenitiesClick(amenities?.id, value);
                
              }}/>
            </div>
          )}

          <br/>
          <p className="gray-text sm-text">FOOD AND BEVERAGES</p>
          {filteredFoodAndBeverages && filteredFoodAndBeverages.map((amenities) =>
            <div className="switch-name-wrapper">
              <div className="name"> 

                {amenities?.name || 'No Name'} 
              
              </div>
              <Switch
                checked={getCurrentSelectValue(amenities?.id)?.selectedStatus} onChange={(value) => {
                handleAmenitiesClick(amenities?.id, value);
                
              }}/>
            </div>
          )}

          <br/>
          <p className="gray-text sm-text">FACILITIES</p>
          {filteredFacilities && filteredFacilities.map((amenities) =>
            <div className="switch-name-wrapper">
              <div className="name"> 

                {amenities?.name || 'No Name'}  
                
              </div>
              <Switch
                checked={getCurrentSelectValue(amenities?.id)?.selectedStatus} onChange={(value) => {
                handleAmenitiesClick(amenities?.id, value);
                
              }}/>
            </div>
          )}

            {/* {getFieldCurrentValue('data') && getFieldCurrentValue('data').map((amenitiesElement) =>
            <div className="switch-name-wrapper">
              <div className="name"> 
                
                {amenitiesElement?.name || 'No Name'}
                
              </div>
              <Switch
                checked={getCurrentSelectValue(amenitiesElement?.id)?.selectedStatus} onChange={(value) => {
                handleAmenitiesClick(amenitiesElement?.id, value);
              
              }}/>
            </div>
            )} */}

        </div>

      </div>
      <FlowHandlingButtons/>
    </>
  );
};

export default Amenities;
