import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Popover } from 'antd';
import {
    useRouteMatch
} from "react-router-dom";
import { getValidColoreForSpaceStatus } from '../../helper/StatusColor'
import { getMoreSpaces } from './HostDashboardActions';
import { IMAGES_BASE_URL } from '../../settings';
import { ACTIVE } from "../Common/constants";

import ellipsis from '../../assets/images/host/ellipsis.svg';
import edit from '../../assets/images/host/edit-teal.svg';
import duplicateIcon from '../../assets/images/host/duplicate.svg';

const content = (
    <div className="action-popover">
        <span className="list-items">
            <img src={edit} alt="img" className="icon" />Edit
        </span>
        <span className="list-items">
            <img src={duplicateIcon} alt="img" className="icon" />Duplicate
        </span>
    </div>
);

export const SpaceList = ({ pageCount, setPageCount, searchText, bookingStatus, spaceCategoryId, eventTypeId }) => {

    const filteredSpaces = useSelector(state => state?.hostDashboard?.data?.filteredSpaceDetails || []);

    const dispatch = useDispatch();
    const status = ['ALL', 'ACTIVE', 'PENDING', 'INACTIVE', 'DECLINED'];
    const { url } = useRouteMatch();

    const handleSpaceCardOnClick = (space) => {
        const URLtoPush = `${url}/${space.id}`
        dispatch(push(URLtoPush));
    };

    const capitalize = (status) => {
        if (typeof status !== 'string') return ''
        return status.charAt(0) + status.slice(1).toLowerCase()
    };

    const fetchMoreListItems = () => {
        const payload = {
            page: pageCount + 1,
            size: 10,
            name: searchText,
            status: bookingStatus,
            categoryId: spaceCategoryId,
            activityId: eventTypeId
        }
        dispatch(getMoreSpaces(payload));
        setPageCount(pageCount + 1);
    };

    return (
        <div className="card-list">
            {filteredSpaces.length ?
                <InfiniteScroll
                    dataLength={filteredSpaces.length}
                    next={fetchMoreListItems}
                    hasMore={true}>
                    {filteredSpaces.map(space =>
                        <div className="card" onClick={() => handleSpaceCardOnClick(space)}>
                            <span className="img" style={{ backgroundImage: `url(${IMAGES_BASE_URL + space.image})` }}></span>
                            <div className="content">
                                <span className="title">{space?.tags.length > 1 ? 'MULTIPLE' : space.tags[0]} • {space.city}</span>
                                <span className="sub-title">{space.spaceName}</span>
                                {space.spaceStatus && <span className={`status ${getValidColoreForSpaceStatus(space.spaceStatus)}`}>{capitalize(space.spaceStatus)}</span>}
                                <div className="flex-wrap">
                                    <div className="favourites">
                                        {/* <img src={star} alt="img" className="icon" />
                                                                <span className="number">{space.rating}</span>
                                                                <span className="gray-text">({space.ratingCount})</span> */}
                                    </div>
                                    {(space.spaceStatus === ACTIVE) && (<Popover placement="bottomRight" content={content}
                                        trigger="click" overlayClassName="space-popover">
                                        <img src={ellipsis} alt="img" className="ellipsis-img" />
                                    </Popover>)}
                                </div>
                            </div>
                        </div>
                    )}
                </InfiniteScroll> :
                <div className="no-more-wrap">
                    <p className="sub-title">
                        There are no spaces to be displayed for the user.
                    </p>
                </div>}
        </div>
    )
};

SpaceList.propTypes = {
    country: PropTypes.string,
    pageCount: PropTypes.number,
    setPageCount: PropTypes.func
};

export default SpaceList;