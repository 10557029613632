import {
    FETCH_MY_PROFILE_DATA, FETCH_MY_PROFILE_SUCCESS, FETCH_MY_PROFILE_FAILS,
    FETCH_MY_BOOKINGS_DATA, FETCH_MY_BOOKINGS_SUCCESS, FETCH_MY_BOOKINGS_FAILS,
    FETCH_MY_FAVOURITES_DATA, FETCH_MY_FAVOURITES_SUCCESS, FETCH_MY_FAVOURITES_FAILS,
    FETCH_COUNTRY_DIALING_CODES, FETCH_COUNTRY_DIALING_CODES_SUCCESS, FETCH_COUNTRY_DIALING_CODES_FAILS, REMOVE_COUNTRY_DIALING_CODES
} from '../../redux/actionTypes';

export const dashboardReducer = (
    state = {
        fetching: false,
        error: { status: false, message: "" }
    }, action) => {
    switch (action.type) {

        case FETCH_MY_PROFILE_DATA:
            return Object.assign({}, state, { fetching: true });

        case FETCH_MY_PROFILE_SUCCESS:
            return {
                ...state,
                fetching: false,
                myprofile: action.payload

            };
        case FETCH_MY_PROFILE_FAILS:
            return Object.assign({}, { data: [] }, { fetching: false }, { error: { status: true, message: "" } });


        case FETCH_MY_BOOKINGS_DATA:
            return Object.assign({}, state, { fetching: true });

        case FETCH_MY_BOOKINGS_SUCCESS:
            return {
                ...state,
                fetching: false,
                mybookings: action.payload

            };
        case FETCH_MY_BOOKINGS_FAILS:
            return Object.assign({}, { data: [] }, { fetching: false }, { error: { status: true, message: "" } });


        case FETCH_MY_FAVOURITES_DATA:
            return Object.assign({}, state, { fetching: true });

        case FETCH_MY_FAVOURITES_SUCCESS:
            return {
                ...state,
                fetching: false,
                myfavourites: action.payload

            };
        case FETCH_MY_FAVOURITES_FAILS:
            return Object.assign({}, { data: [] }, { fetching: false }, { error: { status: true, message: "" } });


        case FETCH_COUNTRY_DIALING_CODES:
            return Object.assign({}, state, { fetching: true });

        case FETCH_COUNTRY_DIALING_CODES_SUCCESS:
            return {
                ...state,
                fetching: false,
                allcountrycodes: action.payload

            };
        case FETCH_COUNTRY_DIALING_CODES_FAILS:
            return Object.assign({}, { data: [] }, { fetching: false }, { error: { status: true, message: "" } });

        case REMOVE_COUNTRY_DIALING_CODES:
            return Object.assign({}, state, { allcountrycodes: [] });

        default:
            return state;

    }
}