
export const setAuthToken = (token, config, axiosInstance) => {
  if (token && checkUrl(config.url)) {
    //applying token
      config.headers = {
        ...config.headers,
        Authorization : token,
        'Content-Type': 'application/json;charset=UTF-8'
      };
    axiosInstance.defaults.headers.common['Authorization'] = token;
  } else {
    //deleting the token from header
    delete config.headers?.common?.Authorization;
    delete axiosInstance.defaults?.headers?.common?.Authorization;
  }
  return config;
};


//Check the url for authentication token
const checkUrl = (url) => {
  let urlValid = true;
  unAuthenticatedUrls.map((unAuthenticatedUrl) => {
    if (url.includes(unAuthenticatedUrl)) {
        urlValid = false;
    }
  });
  return urlValid;
};

//whitelisted URL list
const unAuthenticatedUrls = ['https://maps.googleapis.com', 'https://www.googleapis.com'];
