export const FETCH_BOOKING_DETAILS = "FETCH_BOOKING_DETAILS";
export const FETCH_BOOKING_DETAILS_SUCCESS = "FETCH_BOOKING_DETAILS_SUCCESS";
export const FETCH_BOOKING_DETAILS_FAILS = "FETCH_BOOKING_DETAILS_FAILS";


export const POST_BOOKING_DATA = "POST_BOOKING_DATA";
export const POST_BOOKING_DATA_SUCCESS = "POST_BOOKING_DATA_SUCCESS";
export const POST_BOOKING_DATA_FAIL = "POST_BOOKING_DATA_FAIL";

export const POST_BOOKING_REQUEST_BOOKING_DATA = "POST_BOOKING_REQUEST_BOOKING_DATA";
export const POST_BOOKING_DATA_REQUEST_BOOKING_SUCCESS = "POST_BOOKING_DATA_REQUEST_BOOKING_SUCCESS";
export const POST_BOOKING_DATA_REQUEST_BOOKING_FAIL = "POST_BOOKING_DATA_REQUEST_BOOKING_FAIL";
export const POST_BOOKING_DATA_REQUEST_BOOKING_CLEAR = "POST_BOOKING_DATA_REQUEST_BOOKING_CLEAR";
export const CLEAR_BOOKING_DATA_FAIL = "CLEAR_BOOKING_DATA_FAIL";

export const FETCH_SAVINGS = "FETCH_SAVINGS";
export const FETCH_SAVINGS_SUCCESS = "FETCH_SAVINGS_SUCCESS";
export const FETCH_SAVINGS_FAILS = "FETCH_SAVINGS_FAILS";

export const FETCH_TAX = "FETCH_TAX";
export const FETCH_TAX_SUCCESS = "FETCH_TAX_SUCCESS";
export const FETCH_TAX_FAILS = "FETCH_TAX_FAIL";

export const FETCH_SPACE_AVAILABILITY = "FETCH_SPACE_AVAILABILITY";
export const FETCH_SPACE_AVAILABILITY_SUCCESS = "FETCH_SPACE_AVAILABILITY_SUCCESS";
export const FETCH_SPACE_AVAILABILITY_FAILS = "FETCH_SPACE_AVAILABILITY_FAILS";

export const TAX_SAVINGS = "TAX_SAVINGS";
export const BOOKING_PAYLOAD = "BOOKING_PAYLOAD";

export const SET_GUEST_COUNT = 'SET_GUEST_COUNT';

export const PACKAGE_SUBMIT = 'PACKAGE_SUBMIT';
export const PACKAGE_DELETE = 'PACKAGE_DELETE';
export const PACKAGES_DELETE = 'PACKAGES_DELETE';

export const BOOKING_FLOW = 'BOOKING_FLOW';

export const TENTATIVE_BOOKING_SUCCESS = 'TENTATIVE_BOOKING_SUCCESS';
export const TENTATIVE_BOOKING_FAILED = 'TENTATIVE_BOOKING_FAILED';
export const TENTATIVE_BOOKING_CONFIRM_RESET = 'TENTATIVE_BOOKING_CONFIRM_RESET;'

export const TENTATIVE_BOOKING_CONFIRM_SUCCESS = 'TENTATIVE_BOOKING_CONFIRM_SUCCESS';
export const TENTATIVE_BOOKING_CONFIRM_FAILED = 'TENTATIVE_BOOKING_CONFIRM_FAILED';

export const FETCH_PROMO_CODE = 'FETCH_PROMO_CODE';
export const FETCH_PROMO_CODE_SUCCESS = 'FETCH_PROMO_CODE_SUCCESS';
export const FETCH_PROMO_CODE_FAILS = 'FETCH_PROMO_CODE_FAILS';
export const CLEAR_PROMO_CODE = 'CLEAR_PROMO_CODE';


