import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'antd';

const SearchText = ({ placeholder, onReset, onSearch, type }) => {
  const filterRef = useRef(null);
  const [searchText, setSearchText] = useState('');

  const handleSearch = () => {
    onSearch(searchText);
    filterRef.current.select();
  };

  const handleReset = () => {
    setSearchText('');
    onReset();
  };

  return (
    <div className="report-dropdown-input padding-wrap">
      <Input
        className="report-input"
        ref={filterRef}
        type={type}
        placeholder={placeholder}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onPressEnter={handleSearch}
      />
        <Button onClick={handleReset} className="btn-reset">
          Reset
       </Button>
        <Button className="btn-search" onClick={handleSearch}>
          Search
       </Button>
    </div>
  );
};

SearchText.propTypes = {
  placeholder: PropTypes.string,
  onReset: PropTypes.func,
  onSearch: PropTypes.func,
  type: PropTypes.string
};

export default SearchText;
