import React from 'react';
import moment from 'moment';
import { SelectField } from 'react-md';
import PropTypes from 'prop-types';
import { TIME_FORMAT } from '../constants';


const TimePicker = (
	{
		beginLimit = "12:00 am",
		endLimit,
		step = 30,
		defaultValue,
		onChange,
		value,
		label,
		placeholder,
		availableSlots,
		startTime,
		disable=false
	}) => {
		

	const isEarlierThanEndLimit = (timeValue, endLimit, lastValue) => {
		let timeValueIsEarlier = moment(timeValue, TIME_FORMAT).diff(moment(endLimit, TIME_FORMAT)) < 0
		let timeValueIsLaterThanLastValue = lastValue === undefined ? true : moment(lastValue, TIME_FORMAT).diff(moment(timeValue, TIME_FORMAT)) < 0
		return timeValueIsEarlier && timeValueIsLaterThanLastValue;
	}

	let options = [];
	let timeValue = beginLimit;
	let lastValue;

	options.push({title:timeValue, value:timeValue});
	

    while (isEarlierThanEndLimit(timeValue, endLimit, lastValue) ) {
		lastValue = timeValue;
      	timeValue = moment(timeValue, TIME_FORMAT).add(step, 'minutes').format(TIME_FORMAT);
	  	options.push({title:timeValue, value:timeValue});
		
	}

	if(availableSlots && availableSlots.length > 0){
		const filteredSlots = [];
		let validTime = false;
		if(label === 'Start Time'){
			availableSlots.forEach(slot => {
				options.forEach(opt => {
					if(step === 60) {
						if (moment(opt.value, TIME_FORMAT).isSameOrAfter(moment(slot.startTime, TIME_FORMAT)) && moment(opt.value, TIME_FORMAT).isSameOrBefore(moment(slot.endTime, TIME_FORMAT).add(-1, 'hours'))) {
							filteredSlots.push(opt)
						}
					} else {
						if (moment(opt.value, TIME_FORMAT).isSameOrAfter(moment(slot.startTime, TIME_FORMAT)) && moment(opt.value, TIME_FORMAT).isSameOrBefore(moment(slot.endTime, TIME_FORMAT).add(-30, 'minutes'))) {
							filteredSlots.push(opt)
						}
					}
				})
			});
		} else{
			availableSlots.forEach(slot => {
				if(
				moment(startTime, TIME_FORMAT).isSameOrAfter(moment(slot.startTime, TIME_FORMAT)) &&
				moment(startTime, TIME_FORMAT).isSameOrBefore(moment(slot.endTime, TIME_FORMAT))
				)
				options.forEach(opt => {
					if(step === 60) {
						if (moment(opt.value, TIME_FORMAT).isSameOrAfter(moment(slot.startTime, TIME_FORMAT).add(1, 'hours')) && moment(opt.value, TIME_FORMAT).isSameOrBefore(moment(slot.endTime, TIME_FORMAT))) {
							filteredSlots.push(opt)
						}
					} else{
						if (moment(opt.value, TIME_FORMAT).isSameOrAfter(moment(slot.startTime, TIME_FORMAT).add(30, 'minutes')) && moment(opt.value, TIME_FORMAT).isSameOrBefore(moment(slot.endTime, TIME_FORMAT))) {
							filteredSlots.push(opt)
						}
					}
				})
			});
		}
		options = filteredSlots;
		
	}
	

    return (
		<SelectField
			placeholder={placeholder}
			label={label}
			simplifiedMenu={true}
			value={value}
			onChange={value=>onChange(value)}
			menuItems={options}
			defaultSelected={value}
			disabled={disable}
		/>
	);

};
TimePicker.propTypes = {
	beginLimit : PropTypes.string,
	endLimit: PropTypes.string,
	step : PropTypes.number,
	defaultSelected : PropTypes.string,
	onChange : PropTypes.func,
	value: PropTypes.string,
	label: PropTypes.string,
	placeholder : PropTypes.string
  };
export default TimePicker;
