import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { AVAILABILITY, PACKAGES, WEEKLY_OPTION } from '../../../SpaceListingConstants';
import PricingAndAvailability from '../../../Common/PricingAndAvailablity';
import PackageComponent from '../../../Common/Packages';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const WeeklyPricing = ({chargingType}) => {
  const country = useSelector((state) => state?.locationMeta?.data?.country_longCode?.replace(" ", "-") || null);
  const baseRouteURL = `/${country}/dashboard/list-a-space/pricing_options/` + WEEKLY_OPTION + '/';
  return (
    <>
      <Switch>
        <Route
          path={baseRouteURL + AVAILABILITY}
          component={() => {
            return <PricingAndAvailability pricingOption={WEEKLY_OPTION} chargingType={chargingType}/>;
          }}
        />
        <Route
          path={baseRouteURL + PACKAGES}
          component={() => {
            return <PackageComponent pricingOption={WEEKLY_OPTION}/>;
          }}
        />
        <Route
          path={'*'}
          component={() => <Redirect to={baseRouteURL + AVAILABILITY}/>}
        />
      </Switch>
    </>
  );
}

export default WeeklyPricing;
