import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import React, { useCallback } from 'react';
import train5 from '../../../assets/images/train/train5.svg';
import { FETCH_LOCATION_STREET_NAME, MAP_PIN_UPDATED, MAP_ZOOM_UPDATED } from '../ListingActionTypes';
import { useDispatch } from 'react-redux';

const ListingMap = ({ locationDetails, nearLatitude,nearLongitude }) => {
  const dispatch = useDispatch();
  function handleZoomChanged() {
    dispatch({
      type: MAP_ZOOM_UPDATED,
      payload: {
        mapZoom: this.getZoom(),
      },
    });
  }

  const onMarkerDragEnds = (event) => {
    dispatch({
      type: MAP_PIN_UPDATED,
      payload: {
        latitude: event.latLng.lat(),
        longitude: event.latLng.lng(),
      },
    });
  };

  return (
    <GoogleMap
      defaultZoom={Number(locationDetails?.mapZoom)}
      zoom={Number(locationDetails?.mapZoom)}
      onZoomChanged={handleZoomChanged}
      defaultCenter={{ lat: Number(locationDetails?.latitude), lng: Number(locationDetails?.longitude) }}
      center={{ lat: Number(locationDetails?.latitude), lng: Number(locationDetails?.longitude) }}
    >
      <Marker
        position={{ lat: Number(locationDetails?.latitude), lng: Number(locationDetails?.longitude) }}
        draggable={true}
        onDragEnd={onMarkerDragEnds}
      />
      <Marker
        position={{ lat: Number(nearLatitude), lng: Number(nearLongitude) }}
        icon={{ url: train5, scaledSize: { height: 30, width: 30 } }}
      />
      
    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap(ListingMap));
